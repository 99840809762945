/* This is a generated file, do not edit it directly */
export type { Country } from '@aerapass/country-data';
export type CountryCode =
  | 'XX'
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BQ'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'EU'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW';
export type BooleanMap = Record<string, boolean>;
export type FeatureRestrictedRegionMap = Partial<Record<Feature, BooleanMap>>;
export type FeatureRestrictedCountryRegionMap = Partial<
  Record<CountryCode, FeatureRestrictedRegionMap>
>;
export type CoreCurrencyConfigurations = Record<
  Scalars['NetworkCurrency']['output'],
  CoreCurrencyNetworkConfiguration
>;
export type OpsCurrencyConfigurations = Record<
  Scalars['NetworkCurrency']['output'],
  OpsCurrencyNetworkConfiguration
>;
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AWSDate: { input: string; output: string };
  AWSDateTime: { input: string; output: string };
  AWSEmail: { input: string; output: string };
  AWSIPAddress: { input: string; output: string };
  AWSJSON: { input: Record<string, unknown>; output: Record<string, unknown> };
  AWSPhone: { input: string; output: string };
  AWSTime: { input: string; output: string };
  AWSTimestamp: { input: string; output: string };
  AWSURL: { input: string; output: string };
  /** AccountID: an account's unique ID, of the form <UserID>/<CurrencyCode>/<Type> */
  AccountID: { input: string; output: string };
  /** Address: an account or wallet address related to a transaction. Fireblocks = data.destinationAddress */
  Address: { input: string; output: string };
  /** AddressRef: Destination tag for XRP, used as memo for EOS/XLM */
  AddressRef: { input: string; output: string };
  /** AssetID:  A scalar representing a financial asset code. Suitable for use in cases where exactly one of the currency codes must be provided. */
  AssetID: { input: string; output: string };
  /** AuthID: user's id in the auth service (Ory) */
  AuthID: { input: string; output: string };
  /** BadgeTypeID: id of a badge type */
  BadgeTypeID: { input: string; output: string };
  /** BooleanMap: a map of strings to booleans, e.g. { Foo: true } */
  BooleanMap: { input: BooleanMap; output: BooleanMap };
  /**
   * CoreCurrencyConfigurations: Core settings for currencies and their networks.
   * It is used to manage parameters for currencies, such as their unique identifiers, network-specific information, and precision attributes.
   */
  CoreCurrencyConfigurations: {
    input: CoreCurrencyConfigurations;
    output: CoreCurrencyConfigurations;
  };
  /** CountryCode: An ISO 3166-1 alpha-2 country code, type from https://github.com/annexare/Countries/blob/master/data/countries.3to2.json */
  CountryCode: { input: CountryCode; output: CountryCode };
  /** CustomerID: ID of a customer */
  CustomerID: { input: string; output: string };
  /** DDBTableName: DynamoDB Table name */
  DDBTableName: { input: string; output: string };
  /** Decimal: arbitrary precision decimal number */
  Decimal: { input: string; output: string };
  /** DynamoID: our internal DynamoDB identifier */
  DynamoID: { input: string; output: string };
  /** ErrorExtensions: object with ErrorExtensionType fields: map[ErrorExtensionType]map[string]any. */
  ErrorExtensions: {
    input: Record<ErrorExtensionType, Record<string, unknown>>;
    output: Record<ErrorExtensionType, Record<string, unknown>>;
  };
  /** ErrorObject: error object native to language */
  ErrorObject: { input: string; output: string };
  /** EventID: the assigned gateway api identifier for each request */
  EventID: { input: string; output: string };
  /** FeatureRestrictedCountryRegionMap: a map of CountryCodes (strings) to FeatureMaps e.g. { CA: { Withdraw: { '*': true } }, US: { Withdraw: { NY: true, TX: true } }} */
  FeatureRestrictedCountryRegionMap: {
    input: FeatureRestrictedCountryRegionMap;
    output: FeatureRestrictedCountryRegionMap;
  };
  /** FiatPaymentMethodID: ID of a fiat payment method */
  FiatPaymentMethodID: { input: string; output: string };
  /** FinServiceConfigurations: FinService settings for currency accounts and funding. */
  FinServiceConfigurations: {
    input: Partial<Record<FinService, AppConfigFundingBalanceSettings[]>>;
    output: Partial<Record<FinService, AppConfigFundingBalanceSettings[]>>;
  };
  /** FireblocksID: the transaction id used by Fireblocks = data.id */
  FireblocksID: { input: string; output: string };
  /** JWT: JSON Web Token */
  JWT: { input: string; output: string };
  /** KSUID: K-Sortable Unique Identifier */
  KSUID: { input: string; output: string };
  /** MarketingCodeId: id of a a marketing code */
  MarketingCodeID: { input: string; output: string };
  /**
   * MoonpayID: the transaction id used by Moonpay = data.id
   *
   * @deprecated Moonpay is deprecated and should not be used.
   */
  MoonpayID: { input: string; output: string };
  /** NetworkCurrency: A concatenation of both the currency code and network, of the form <Network>/<CurrencyCode>. */
  NetworkCurrency: { input: string; output: string };
  /** NoahID: our internal transaction id */
  NoahID: { input: string; output: string };
  /** Nonce: a string which must be unique each time a new transaction is created, like a UUID or operation sequence number. Request can be idempotently retried by using the same Nonce. */
  Nonce: { input: string; output: string };
  /**
   * OpsCurrencyConfigurations: Ops settings for currencies and their networks.
   * It is used to manage operational settings, such as minimum sweep amounts for account-based currencies and funding settings for re-balancing.
   */
  OpsCurrencyConfigurations: {
    input: OpsCurrencyConfigurations;
    output: OpsCurrencyConfigurations;
  };
  /** PaymentRequest: lightning payment request */
  PaymentRequest: { input: string; output: string };
  /** PromoID: unique promotion identifier */
  PromoID: { input: string; output: string };
  /** PublicID: the public blockchain transaction id or hash (if available). Fireblocks = data.txHash */
  PublicID: { input: string; output: string };
  /** QuestID: id of a quest */
  QuestID: { input: string; output: string };
  /** QuestTaskID: id of a quest task */
  QuestTaskID: { input: string; output: string };
  /** RegionCode: An ISO 3166-1 style postal abbreviation code of a region */
  RegionCode: { input: string; output: string };
  /** SigningKeyID: ID of a user signing key, of the form <UserID>/<SigningDocumentType> */
  SigningKeyID: { input: string; output: string };
  /** UUID: Unique identifier in format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx */
  UUID: { input: string; output: string };
  /** UserID: user's id - equal to the auth Identity Sub */
  UserID: { input: string; output: string };
  /** WhitelistID: identifies a whitelist address */
  WhitelistID: { input: string; output: string };
  /** WithdrawOrderID: id for withdraw order */
  WithdrawOrderID: { input: string; output: string };
};

export type AwsEventBridgeEntry = {
  ErrorCode?: Maybe<Scalars['String']['output']>;
  ErrorMessage?: Maybe<Scalars['String']['output']>;
  EventId: Scalars['String']['output'];
};

export type AwsEventBridgeResponse = {
  Entries: Array<AwsEventBridgeEntry>;
  FailedEntryCount: Scalars['Int']['output'];
};

/** Account: an account belonging to a user. */
export type Account = {
  /** AccountType: type of account e.g. current / savings / loan */
  AccountType: AccountType;
  /** Created: date when the account was created */
  Created: Scalars['AWSDateTime']['output'];
  /** CurrencyCode: the currency code of this account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** ID: this account's unique ID, of the form <UserID>/<CurrencyCode>/<Type> */
  ID: Scalars['AccountID']['output'];
  /** UserID: identifies the owner of this account */
  UserID: Scalars['UserID']['output'];
  /** WhitelistAddresses: Whitelisted addresses */
  WhitelistAddresses?: Maybe<Array<WhitelistAddress>>;
};

export type AccountBalance = AccountBalanceCurrent;

/** AccountBalanceCurrent: balance of a current account. */
export type AccountBalanceCurrent = {
  /** AccountType: type of account to create e.g. current / savings / loan */
  AccountType: AccountType;
  /** Available: The balance available for new transactions */
  Available: Scalars['Decimal']['output'];
  /** ID: the ID of the account this balance belongs to, of the form <UserID>/<CurrencyCode>/<Type> */
  ID: Scalars['AccountID']['output'];
  /** Lifetime: Total accrued */
  Lifetime: Scalars['Decimal']['output'];
  /** Total: The current reconciled total */
  Total: Scalars['Decimal']['output'];
};

/** AccountCreateInput: Graphql input to request to create an account (UserID will come from JWT) */
export type AccountCreateInput = {
  /** AccountType: type of account to create e.g. current / savings / loan */
  AccountType: AccountType;
  /** CurrencyCode: the currency code of the account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** DepositAddressRequest: use to query or create a deposit address for a specific network. */
  DepositAddressRequest?: InputMaybe<DepositAddressRequest>;
};

export type AccountCreateResponse = {
  /** Account: an account belonging to a user */
  Account: Account;
  /** DepositAddresses: addresses where user can directly deposit funds */
  DepositAddresses?: Maybe<Array<DepositAddress>>;
};

export type AccountHolderDetails = {
  /**
   * Address: the address of the account owner. This should match the
   * payment method records.
   */
  Address?: Maybe<StreetAddress>;
  /** BusinessName: the name of the business as the owner of the account. */
  BusinessName?: Maybe<Scalars['String']['output']>;
  /** Email: the email associated with the payment method */
  Email?: Maybe<Scalars['String']['output']>;
  /** Identity: account holder identity */
  Identity?: Maybe<CustomerIdentity>;
  /**
   * Name: the name of the owner of the account. This should match
   * the payment method records.
   */
  Name?: Maybe<FullName>;
  /** PhoneNumber: the phone number associated with the payment method */
  PhoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
};

export type AccountHolderDetailsInput = {
  /**
   * Address: the address of the account owner. This should match the
   * bank records.
   */
  Address?: InputMaybe<StreetAddressInput>;
  /** Identity: account holder identity */
  Identity?: InputMaybe<CustomerIdentityInput>;
  /**
   * Name: the name of the owner of the account. This should match
   * the bank records.
   */
  Name?: InputMaybe<FullNameInput>;
  /** PhoneNumber: the phone number associated with the payment method */
  PhoneNumber?: InputMaybe<Scalars['AWSPhone']['input']>;
};

/** AccountResponse: used to publish account updates to the app. */
export type AccountResponse = {
  /** AccountType: type of account e.g. current / savings / loan */
  AccountType: AccountType;
  /** Balance: balance in the account */
  Balance?: Maybe<AccountBalance>;
  /** CurrencyCode: the currency code of this account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** ID: this account's unique ID, of the form <UserID>/<CurrencyCode>/<Type> */
  ID: Scalars['AccountID']['output'];
  /** Version: version of the account */
  Version: Scalars['Int']['output'];
  /** VersionBalance: version of the account balance */
  VersionBalance: Scalars['Int']['output'];
};

/** AccountType: type of account e.g. current / savings / loan */
export enum AccountType {
  /** Current: transactional account for on-demand funds */
  Current = 'Current',
  /** Savings: account for gaining income for capital */
  Savings = 'Savings',
}

export type AccountsInput = {
  paging?: InputMaybe<PagingInput>;
};

export type AccountsPage = {
  items: Array<AccountResponse>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type Action = {
  /** ID: Identifier: RuleID/Position of the Action */
  ID: Scalars['ID']['output'];
  Type: ActionType;
};

export enum ActionType {
  Buy = 'Buy',
  Choice = 'Choice',
  Sell = 'Sell',
  Withdraw = 'Withdraw',
}

/** AddressFormat: address formats */
export enum AddressFormat {
  /** EvmCompatible: Ethereum virtual machine compatible hex address format */
  EvmCompatible = 'EvmCompatible',
  /** Segwit: Bitcoin P2WPKH address format */
  Segwit = 'Segwit',
  /** SolanaBase58: Solana Base58 encoded public key format (32-byte array) */
  SolanaBase58 = 'SolanaBase58',
  /** TronBase58: Tron Base58 address format starting with "T" (version byte 65) */
  TronBase58 = 'TronBase58',
}

/** AdminGroup: group that admin user can belong to */
export enum AdminGroup {
  /** Compliance: user can access compliance operations */
  Compliance = 'Compliance',
  /** FinOps: user can access financial operations */
  FinOps = 'FinOps',
}

/** AdminUser: administrative user */
export type AdminUser = {
  /** Email: Noah email address */
  Email: Scalars['String']['output'];
  /** Groups: groups that the user belongs to */
  Groups?: Maybe<Array<AdminGroup>>;
  /** ID: identifies a Noah admin */
  ID: Scalars['UUID']['output'];
  /** RetoolID: identifier in Retool */
  RetoolID?: Maybe<Scalars['String']['output']>;
};

export enum AlertEvent {
  FinopsFinServiceTimeout = 'FinopsFinServiceTimeout',
  FinopsForceOperation = 'FinopsForceOperation',
  FinopsManualOperationRequired = 'FinopsManualOperationRequired',
  FinopsTransactionFailure = 'FinopsTransactionFailure',
  FinopsWithdrawOrderFailure = 'FinopsWithdrawOrderFailure',
  FinsopsInsufficientLiquidity = 'FinsopsInsufficientLiquidity',
}

export type ApiAllowHeader = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ApiKey = {
  ApiKey?: Maybe<Scalars['String']['output']>;
  Created: Scalars['AWSDateTime']['output'];
  Expires?: Maybe<Scalars['AWSDateTime']['output']>;
  KeyHash: Scalars['String']['output'];
  Label: Scalars['String']['output'];
  PublicKey?: Maybe<Scalars['String']['output']>;
  Signature?: Maybe<Scalars['String']['output']>;
  UserID: Scalars['UserID']['output'];
};

/** ApiKeysIndexedFields: index fields on ledger apikeys table */
export enum ApiKeysIndexedFields {
  KeyHash = 'KeyHash',
}

export type ApiKeysInput = {
  paging?: InputMaybe<PagingInput>;
};

export type ApiKeysPage = {
  items: Array<ApiKey>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type AppConfigAnalytics = {
  enabled: Scalars['Boolean']['input'];
  identify: Scalars['Boolean']['input'];
  track: Scalars['Boolean']['input'];
};

export type AppConfigApple = {
  initiativeContext: AppConfigAppleInitiaveContext;
  merchantIdentifier: Scalars['String']['input'];
};

export type AppConfigAppleInitiaveContext = {
  checkout: Scalars['String']['input'];
  web: Scalars['String']['input'];
};

/** AppConfigApplicationName: Enum containing values for AppConfig application names */
export enum AppConfigApplicationName {
  /** NoahConfigCoreV2: appconfig core application name */
  NoahConfigCoreV2 = 'NoahConfigCoreV2',
  /** NoahConfigOpsV2: appconfig ops application name */
  NoahConfigOpsV2 = 'NoahConfigOpsV2',
}

export type AppConfigAuth = {
  developer: Scalars['Boolean']['input'];
  oryCookieName: Scalars['String']['input'];
  oryCustomUrl: Scalars['String']['input'];
  oryProjectSlug: Scalars['String']['input'];
  oryUiURL: Scalars['String']['input'];
  subscriptionJwtIssuer: Scalars['String']['input'];
};

export type AppConfigAws = {
  captchaApi?: InputMaybe<AwsCaptchaApi>;
  webAclFeArn?: InputMaybe<Scalars['String']['input']>;
};

export type AppConfigBusiness = {
  apiUrl: Scalars['String']['input'];
  requirePublicKey: Scalars['Boolean']['input'];
};

export type AppConfigCko = {
  cardHolderNameMatchThreshold: Scalars['Decimal']['input'];
  entityID: Scalars['String']['input'];
  eurAccountID: Scalars['String']['input'];
  feeMinimumFiatAmount: Scalars['Decimal']['input'];
  feePercentage: Scalars['Decimal']['input'];
  /**
   * Remove these. Should have come from the /balances request but the SDK doesn't
   * support the withCurrencyAccountId param and there's no other way to get it
   * the account id. Checkout has provided us with these values via slack.
   */
  gbpAccountID: Scalars['String']['input'];
  /**
   * minReserveAmount: How much balance to reserve. This will help deal with
   * potential race conditions when our balances are runing low. The actual
   * balance might still go below this amount in those scenarios.
   */
  minReserveFiatAmount: Scalars['Decimal']['input'];
  minimumFiatAmount: Scalars['Decimal']['input'];
  processingChannelId: Scalars['String']['input'];
  slippagePercentage: Scalars['Decimal']['input'];
  supportedFiatPaymentCards: Array<FiatPaymentCardScheme>;
  usdAccountID: Scalars['String']['input'];
  webhookUrl: Scalars['String']['input'];
};

/** AppConfigConfigurationName: Enum containing values for AppConfig configuration names */
export enum AppConfigConfigurationName {
  /** ConfigProfileCore: appconfig core configuration name */
  ConfigProfileCore = 'ConfigProfileCore',
  /** ConfigProfileOps: appconfig ops configuration name */
  ConfigProfileOps = 'ConfigProfileOps',
}

export type AppConfigCoreCurrencies = {
  configurations: Scalars['CoreCurrencyConfigurations']['input'];
};

export type AppConfigCoreSigning = {
  coreSigningKeyArn: Scalars['String']['input'];
};

export type AppConfigEthereum = {
  rpcUrl: Scalars['String']['input'];
};

export type AppConfigFe = {
  checkout: AppConfigFeCheckout;
  web: AppConfigFeWeb;
};

export type AppConfigFeCheckout = {
  baseUrl: Scalars['String']['input'];
  paymentMethodsUrl: Scalars['String']['input'];
};

export type AppConfigFeWeb = {
  baseUrl: Scalars['String']['input'];
  cko3DSReturnPath: Scalars['String']['input'];
  enableDatadog: Scalars['Boolean']['input'];
};

export type AppConfigFeature = {
  globalOverrides?: InputMaybe<Array<FeatureFlagInput>>;
  /**
   * policies: policies configures which users can access which features.
   * Features are disabled by default and can be added to policies to permit them.
   *
   * Policies set the baseline which can be augmented through:
   * * User overrides
   * * Global overrides
   * * Regions
   */
  policies: Array<AppConfigFeaturePolicy>;
  profanityFilter: Scalars['Boolean']['input'];
  regions: AppConfigFeatureRegions;
  systemStatus: SystemStatus;
};

export type AppConfigFeaturePolicy = {
  /** currencies: currencies permitted by this policy. */
  currencies: Array<CurrencyCode>;
  /**
   * features: features permitted by this policy.
   * Must be defined or no features are permitted by this policy.
   */
  features: Array<Feature>;
  /**
   * groups: groups this policy permits features for.
   * Default is everyone.
   */
  groups?: InputMaybe<Array<UserGroup>>;
  /** networks: networks permitted by this policy. */
  networks: Array<Network>;
  /**
   * precedence: take precedence over global override / system status.
   * 0: no precedence - for 'defaults'
   * 1: take effect over global override
   * 2: take effect also over system status
   */
  precedence: Scalars['Int']['input'];
};

export type AppConfigFeatureRegions = {
  /** HighRiskCountries: countries we need to ask for extra documentation */
  HighRiskCountries: Scalars['BooleanMap']['input'];
  /** ProhibitedCountries: countries that are entirely blocked from accessing the app */
  ProhibitedCountries: Scalars['BooleanMap']['input'];
  /** RestrictedRegions: a complex type describing countries which have region-restricted features */
  RestrictedRegions: Scalars['FeatureRestrictedCountryRegionMap']['input'];
};

export type AppConfigFireblocks = {
  finopsVault: Scalars['Int']['input'];
  fuelPumpVault: Scalars['Int']['input'];
  /** fundingForwardVaults: vault IDs from which funds will be forwarded to fundingWalletID */
  fundingForwardVaults: Array<Scalars['Int']['input']>;
  /** fundingWalletID: internal wallet ID of the sytem|funding account */
  fundingWalletID: Scalars['String']['input'];
  omnibusVault: Scalars['Int']['input'];
  /** sync: indicating whether settings related to gas gas station and confirmation threshold should be syncronized with Fireblocks */
  sync: Scalars['Boolean']['input'];
  testWalletVault: Scalars['Int']['input'];
  withdrawVault: Scalars['Int']['input'];
};

export type AppConfigFuelPump = {
  /** capacity: The amount in an intermediate vault up to which the gas station will fuel it */
  capacity: Scalars['Decimal']['input'];
  /** threshold: The amount in an intermediate vault below which the gas station will fuel it */
  threshold: Scalars['Decimal']['input'];
};

export type AppConfigFunding = {
  settings: Scalars['FinServiceConfigurations']['input'];
};

export type AppConfigFundingBalanceSettings = {
  /** currency: the currency of the account */
  currency: Scalars['AssetID']['input'];
  /** reference: identification of the account with the provider */
  reference: Scalars['String']['input'];
  /**
   * scarcityThreshold: how much is the balance allowed to deviate into
   * insufficiency before triggering an order to top-up funds in the 3rd party.
   * 0 means 0% (not recomended because it would trigger a top-up order on any
   * deviation) and 100 means 100% (also not recomended because the top up would
   * only occur after the account is completely depleted).
   */
  scarcityThreshold: Scalars['Decimal']['input'];
  /**
   * surplusThreshold: how much is the balance allowed to deviate in excess before
   * triggering an order to withdraw funds from the 3rd party. 0 means 0% (not
   * recomended because it would trigger a withdraw order on any deviation) and
   * 100 means 100% (trigger a withdraw order when the balance is double the
   * target amount).
   */
  surplusThreshold: Scalars['Decimal']['input'];
  /** targetAmount: how much should the funding manager aim at keeping in this account */
  targetAmount: Scalars['Decimal']['input'];
};

export type AppConfigFundingSettings = {
  /**
   * minimumFundingAmountUsd: The rebalancing process between the omnibus and withdrawal vaults is triggered when the calculated imbalance in USD surpasses this specified value.
   * Re-balancing won't occur if the difference is below this value.
   */
  minimumFundingAmountUsd: Scalars['Decimal']['input'];
  /** targetShare: The target share of the omnibus vault represents the proportion of the total balance allocated to the withdrawal vault for the amount exceeding the threshold. */
  targetShare: Scalars['Decimal']['input'];
  /** thresholdUsd: The omnibus vault balance is distributed equally between the omnibus vault and the withdrawal vault up to this specified threshold amount. */
  thresholdUsd: Scalars['Decimal']['input'];
};

export type AppConfigGasStation = {
  /** address: The onchain address for the gas station used to identify auto-fuel events. */
  address: Scalars['Address']['input'];
  /** capacity: The amount in an intermediate vault up to which the gas station will fuel it */
  capacity: Scalars['Decimal']['input'];
  /** gasMaxPrice: The maximum gas price that is paid for an auto-fuel transaction. If not set there is no limit to the maximum transaction fee */
  gasMaxPrice?: InputMaybe<Scalars['Decimal']['input']>;
  /** threshold: The amount in an intermediate vault below which the gas station will fuel it */
  threshold: Scalars['Decimal']['input'];
};

export type AppConfigLeadBank = {
  /** CustomerFBOAccountID: The Customer FBO account id with Lead Bank that virtual account numbers are generated for. */
  CustomerFBOAccountID: Scalars['String']['input'];
  /** OperatorAccountNumberID: The Operator account number id with Lead Bank that can be used for payouts. */
  OperatorAccountNumberID: Scalars['String']['input'];
};

export type AppConfigLightning = {
  addressDomain: Scalars['String']['input'];
  callbackDomain: Scalars['String']['input'];
};

export type AppConfigLimitPolicy = {
  businessPolicy: LimitPolicy;
  userPolicy: LimitPolicy;
};

export type AppConfigLimits = {
  /** btcSingleMinimumEur: The minimum amount of EUR required for a single BTC transaction */
  btcSingleMinimumEur: Scalars['Int']['input'];
  /** dayLimitEur: The maximum amount of EUR that can be withdrawn in a day */
  dayLimitEur: Scalars['Int']['input'];
  /** ethereumSingleMinimumEur: The minimum amount of EUR required for a single Ethereum transaction */
  ethereumSingleMinimumEur: Scalars['Int']['input'];
  /** lnDayLimitEur: The maximum amount of EUR that can be transacted in a day via Lightning Network */
  lnDayLimitEur: Scalars['Int']['input'];
  /** lnSingleLimitEur: The maximum amount of EUR for a single payment via Lightning Network */
  lnSingleLimitEur: Scalars['Int']['input'];
  /** signingDayLimitEur: The maximum amount of EUR that can be withdrawn without a signature in a day */
  signingDayLimitEur: Scalars['Int']['input'];
  /** signingSingleLimitEur: The maximum amount of EUR that can be withdrawn without a signature in a single transaction */
  signingSingleLimitEur: Scalars['Int']['input'];
};

export type AppConfigLogger = {
  logAppConfig: Scalars['Boolean']['input'];
  logLevel: LogLevel;
  mode: LoggerMode;
  stackTrace: Scalars['Boolean']['input'];
};

export type AppConfigManualRamp = {
  settings: Array<InputMaybe<ManualRampCurrencySettings>>;
};

export type AppConfigMockServer = {
  grpcUrl: Scalars['String']['input'];
  httpUrl: Scalars['String']['input'];
};

export type AppConfigNetworkFee = {
  quoteExpirySec: Scalars['Int']['input'];
};

export type AppConfigNetworkSettings = {
  /** confirmationThreshold: The required number of confirmations to treat a transaction as final */
  confirmationThreshold: Scalars['Int']['input'];
  /** fuelPump: Settings for the fuel pump. If not set the fuel pump is disabled for this network. */
  fuelPump?: InputMaybe<AppConfigFuelPump>;
  /** gasStation: Settings for the gas station. If not set the gas station is disabled. */
  gasStation?: InputMaybe<AppConfigGasStation>;
  /** network: The specific network the settings apply to */
  network: Network;
  /** rpc: settings for rpc nodes of the network */
  rpc?: InputMaybe<RpcConnection>;
};

export type AppConfigNetworks = {
  settings: Array<AppConfigNetworkSettings>;
};

export type AppConfigOpsCurrencies = {
  configurations: Scalars['OpsCurrencyConfigurations']['input'];
};

export type AppConfigProfileCore = {
  analytics: AppConfigAnalytics;
  apiAllowCloudfrontHeader: ApiAllowHeader;
  apiAllowHeader: ApiAllowHeader;
  appEnv: AppEnvs;
  appStage: AppStages;
  apple: AppConfigApple;
  auth: AppConfigAuth;
  aws: AppConfigAws;
  business: AppConfigBusiness;
  cko: AppConfigCko;
  coreSigning: AppConfigCoreSigning;
  currencies: AppConfigCoreCurrencies;
  defaultRegion: Scalars['String']['input'];
  dropUnmatchedWebhook: Scalars['Boolean']['input'];
  ethereum: AppConfigEthereum;
  fe: AppConfigFe;
  fireblocks: AppConfigFireblocks;
  graphqlUrl: Scalars['String']['input'];
  leadbank: AppConfigLeadBank;
  lightning: AppConfigLightning;
  logger: AppConfigLogger;
  manualRamp: AppConfigManualRamp;
  mock: Scalars['Boolean']['input'];
  mockServer: AppConfigMockServer;
  networkFee: AppConfigNetworkFee;
  remitee: AppConfigRemitee;
  repos: AppConfigRepos;
  revolut: AppConfigRevolut;
  ses: AppConfigSes;
  signing: AppConfigSigning;
  solana: AppConfigSolana;
  ssm: AppConfigSsm;
  sumsub: AppConfigSumSub;
};

export type AppConfigProfileOps = {
  currencies: AppConfigOpsCurrencies;
  feature: AppConfigFeature;
  funding: AppConfigFunding;
  limitPolicy: AppConfigLimitPolicy;
  limits: AppConfigLimits;
  networks: AppConfigNetworks;
  risks: AppConfigRisks;
};

export type AppConfigRemitee = {
  debtor: RemiteeDebtor;
};

export type AppConfigRepos = {
  ddb: DdbTableNames;
  ledger: Ledger;
  s3: S3;
};

export type AppConfigRevolut = {
  /** webhookUrl: which url is used to receive webhooks from Revolut */
  webhookUrl: Scalars['String']['input'];
};

export type AppConfigRisk = {
  /**
   * approveLevel:
   *
   * SARDINE auto approveLevel
   * Set this to control at which risk level our system auto-approves Sardine checks
   *
   * 0 = nothing (declines all in our system),
   * 1 = low,
   * 2 = medium_low,
   * 3 = medium,
   * 4 = high,
   * 5 = very_high (effectively auto-approves everything and acts like shadow mode)
   *
   * SCORECHAIN approveLevel
   * Set this to control at which risk level our system auto-approves Scorechain checks
   *
   * Scorechain risk scores at or above this number are auto-approved
   * 1: Critical
   * 2-29: High risk
   * 30-69: Medium risk
   * 70-99: Low risk
   * 100: No risk
   */
  approveLevel: Scalars['Int']['input'];
  check: Scalars['Boolean']['input'];
};

export type AppConfigRisks = {
  fiatBuyCustomer: AppConfigRisk;
  fiatSellCustomer: AppConfigRisk;
  kyc: AppConfigRisk;
  registrationDevice: AppConfigRisk;
  withdrawCustomer: AppConfigRisk;
};

export type AppConfigSsm = {
  /** overridePrefix: overrides environment's parameter if found in this prefix */
  overridePrefix?: InputMaybe<Scalars['String']['input']>;
  roleArn: Scalars['String']['input'];
};

export type AppConfigSes = {
  accessRole: Scalars['String']['input'];
};

export type AppConfigSigning = {
  enforceSignature: Scalars['Boolean']['input'];
};

export type AppConfigSolana = {
  rpcUrl: Scalars['String']['input'];
};

export type AppConfigSumSub = {
  /** basicLevelName: the name of the basic level kyc workflow in SumSub */
  basicLevelName: Scalars['String']['input'];
  /** customerLevelName: the name of the customer level workflow in SumSub where applicants can be created with business kyc data */
  customerLevelName: Scalars['String']['input'];
  /** eddLevelName: the name of the extra due diligence level workflow in SumSub */
  eddLevelName: Scalars['String']['input'];
  /** enableBicLookup: whether to enable the BIC lookup via IbanApi */
  enableBicLookup: Scalars['Boolean']['input'];
  /** enableKyt: whether to enable the KYT feature in SumSub */
  enableKyt: Scalars['Boolean']['input'];
  /** enableTravelRule: whether to enable the TravelRule feature in SumSub */
  enableTravelRule: Scalars['Boolean']['input'];
  /** fullLevelName: the name of the full level kyc workflow in SumSub */
  fullLevelName: Scalars['String']['input'];
  /**
   * requestAmlChecks: whether to request AML checks on customer applicant creation.
   * This process should trigger automatically, therefore this can be disabled when AML checks are being done as intended at SumSub.
   */
  requestAmlChecks: Scalars['Boolean']['input'];
  /** travelRuleAmountThreshold: USD amount under which travel rule checks are skipped */
  travelRuleAmountThreshold: Scalars['Decimal']['input'];
};

/** AppEnvs are generic names of envs which contain one or more AppStages */
export enum AppEnvs {
  Dev = 'Dev',
  Prod = 'Prod',
}

/**
 * AppStages are specific stages of the App and it's deployment
 * WARNING! Changing these enum values can / will change the logical ID of deployed resouces, which can break deployments
 */
export enum AppStages {
  Dev = 'Dev',
  PreProd = 'PreProd',
  /** AppEnvs.Prod */
  Prod = 'Prod',
  Review = 'Review',
  /** AppEnvs.Dev */
  Sandbox = 'Sandbox',
  Test = 'Test',
}

export enum AssetBasis {
  AccountBased = 'AccountBased',
  UtxoBased = 'UtxoBased',
}

export type AuthEvent = {
  AuthId: Scalars['AuthID']['output'];
  Email: Scalars['AWSEmail']['output'];
  UserId: Scalars['UserID']['output'];
};

export type AwsCaptchaApi = {
  key: Scalars['String']['input'];
  src: Scalars['String']['input'];
};

/** Badge: badge that can be earned */
export type Badge = {
  /** BadgeTypeID: identifies the type of this badge */
  BadgeTypeID: Scalars['BadgeTypeID']['output'];
  /** Difficulty: difficulty of the badge */
  Difficulty: Scalars['Int']['output'];
  /** QuestID: identifies a quest granting this badge */
  QuestID?: Maybe<Scalars['QuestID']['output']>;
};

export enum BankAccountType {
  /**
   * Current: is an account that is used for everyday transactions.
   * It is also known as a checking account.
   */
  Current = 'Current',
  /** Savings: account used to earn interest on easily accessible cash */
  Savings = 'Savings',
}

export enum BankingSystem {
  /**
   * Ach: the BankCode identifies the bank through the routing number (within
   * the USA). The AccountNumber is the bank account identifier and is of
   * arbitrary length and format depending on the bank where the account is at.
   */
  Ach = 'Ach',
  /**
   * Fedwire: the BankCode identifies the bank through the routing number (within
   * the USA). The AccountNumber is the bank account identifier and is of
   * arbitrary length and format depending on the bank where the account is at.
   */
  Fedwire = 'Fedwire',
  /** Local: system local to a country. Banking channels of the country define the scheme. If a country has multiple systems, avoid using this. */
  Local = 'Local',
  /**
   * Sepa: the AccountNumber alone identifies the country, bank and account
   * according to the IBAN standard.
   */
  Sepa = 'Sepa',
  /**
   * SortCode: the BankCode identifies the bank/branch through the sort code
   * (6 digits) and the AccountNumber identifies the bank account number
   * (8 digits). The account number can start with 0.
   */
  SortCode = 'SortCode',
  /**
   * Swift: the BankCode identifies the bank (and branch where appropriate)
   * according to the SWIFT network stadards. The AccountNumber identifies the
   * account according to the local standards. For example, in the USA this
   * would an Account Number and in some EU countries this would be the IBAN.
   */
  Swift = 'Swift',
}

/** BotData: network traffic data for suspected bots */
export type BotData = {
  /** CountryValue: honeypot field value */
  CountryValue?: Maybe<Scalars['String']['output']>;
  /**
   * IPCountry: Cloundfront country
   * See: https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/using-cloudfront-headers.html
   */
  IPCountry?: Maybe<Scalars['CountryCode']['output']>;
  /**
   * IPRoute: X-Forwarded-For request header
   * See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For
   */
  IPRoute?: Maybe<Scalars['String']['output']>;
};

export type BusinessCustomer = Customer & {
  /** ComplianceState: details a user's compliance status */
  ComplianceState?: Maybe<UserComplianceState>;
  Created: Scalars['AWSDateTime']['output'];
  CreationSource: CustomerCreationSource;
  CustomerID: Scalars['CustomerID']['output'];
  IncorporationDate: Scalars['AWSDate']['output'];
  RegisteredAddress: StreetAddress;
  RegisteredName: Scalars['String']['output'];
  RegistrationCountry: Scalars['CountryCode']['output'];
  RegistrationNumber: Scalars['String']['output'];
  Type: CustomerType;
  UserID: Scalars['UserID']['output'];
};

export type BusinessProfile = {
  /**
   * CustomerFee: a fee that is applied to the business customers transactions on behalf of the
   * business.
   */
  CustomerFee?: Maybe<FeeConfig>;
  /** UserID: the ID of the business owning of this account */
  UserID: Scalars['UserID']['output'];
};

export type BuyAction = Action & {
  /** CryptoCurrency: which crypto to buy. */
  CryptoCurrency: CurrencyCode;
  /** CustomerID: which customer to tie the buy to. */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** FiatAmount: specifies how much to pay for your crypto. Should always reference the previous step. */
  FiatAmount: StepDecimal;
  /** FiatCurrency: specifies which currency to pay in. Should always reference the previous step. */
  FiatCurrency: Scalars['String']['output'];
  /** FormSessionID: the ID of the form we can use to lookup the fiat payment method. */
  FormSessionID: Scalars['UUID']['output'];
  ID: Scalars['ID']['output'];
  Type: ActionType;
};

export type CardIssuerNotification = {
  /** Active: is this notification currently active */
  Active: Scalars['Boolean']['output'];
  /** IssuerName: name of the card issuer */
  IssuerName: Scalars['String']['output'];
  /** Reason: notification reason */
  Reason: CardIssuerNotificationReason;
};

export type CardIssuerNotificationInput = {
  /** IssuerName: name of the card issuer */
  IssuerName: Scalars['String']['input'];
  paging?: InputMaybe<PagingInput>;
};

export type CardIssuerNotificationPage = {
  items: Array<CardIssuerNotification>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export enum CardIssuerNotificationReason {
  CreditCardPaymentsFailing = 'CreditCardPaymentsFailing',
  HsbcPaymentsFailing = 'HsbcPaymentsFailing',
}

export type Channel = {
  /** ChannelConfigID: ChannelConfig used to create the channel */
  ChannelConfigID?: Maybe<Scalars['UUID']['output']>;
  /** Country: The supported country of the channel */
  Country: Scalars['CountryCode']['output'];
  /** CreatedAt: The date the channel was created */
  CreatedAt: Scalars['AWSDateTime']['output'];
  /** Direction: The direction of the channel */
  Direction: TransactionDirection;
  /** Enabled: Whether channel is enabled */
  Enabled: Scalars['Boolean']['output'];
  /** Expiry: The date the channel expired. A channel is expired when a significant change is introduced or when the channel is no longer supported. */
  Expiry?: Maybe<Scalars['AWSDateTime']['output']>;
  /** FeeConfig: The fee configurations for using the channel */
  FeeConfig: Array<ChannelFeeConfig>;
  /** FiatCurrency: The fiat currency of the channel */
  FiatCurrency: FiatCurrencyCode;
  /** FinService: The financial service provider of the channel. */
  FinService: FinService;
  /** ID: UUID for the channel. */
  ID: Scalars['UUID']['output'];
  /** LimitConfig: The limits for this channel */
  LimitConfig: ChannelLimitConfig;
  /** MaxProcessingSeconds: The maximum time it takes to process a transaction through the channel */
  MaxProcessingSeconds: Scalars['Int']['output'];
  /** PaymentMethodCategory: The payment method category. */
  PaymentMethodCategory: PaymentMethodCategory;
  /** PaymentMethod: The payment method type. */
  PaymentMethodType: PaymentMethodType;
  /** ProcessingSeconds: The time it takes to process a transaction through the channel */
  ProcessingSeconds: Scalars['Int']['output'];
};

export type ChannelConfig = {
  /** AdminUserIDs: identifies admin users authorising this config. Used with FinopsReason. */
  AdminUserIDs: Array<Scalars['UUID']['output']>;
  /** CountryConfig: list of currency and country configuration options */
  CountryConfig: Array<CountryConfig>;
  /** CreatedAt: The date the channel was created */
  CreatedAt: Scalars['AWSDateTime']['output'];
  /** Direction: The direction of the channel */
  Direction: TransactionDirection;
  /** Enabled: Whether channel is enabled */
  Enabled: Scalars['Boolean']['output'];
  /** FeeConfig: The fee configurations for using the channel */
  FeeConfig: Array<ChannelFeeConfig>;
  /** FiatCurrency: The fiat currency of the channel */
  FiatCurrency: FiatCurrencyCode;
  /** FinService: The financial service provider of the channel. */
  FinService: FinService;
  /** ID: UUID for the channel config */
  ID: Scalars['UUID']['output'];
  /** Latest: The item at version 0 has this field pointing to the most recent version */
  Latest?: Maybe<Scalars['Int']['output']>;
  /** LimitConfig: The limits for this channel */
  LimitConfig: ChannelLimitConfig;
  /** MaxProcessingSeconds: The maximum time it takes to process a transaction through the channel */
  MaxProcessingSeconds: Scalars['Int']['output'];
  /** PaymentMethod: The payment method type. */
  PaymentMethodType: PaymentMethodType;
  /** ProcessingSeconds: The time it takes to process a transaction through the channel */
  ProcessingSeconds: Scalars['Int']['output'];
  /** Version: The version of the config. The item at version 0 will have the latest data */
  Version: Scalars['Int']['output'];
};

export type ChannelFeeConfig = {
  /** FeeBase: Optional base fee that is added to the percentage fee */
  FeeBase?: Maybe<Scalars['Decimal']['output']>;
  /** FeeMin: The minimum fee charged for using the channel */
  FeeMin: Scalars['Decimal']['output'];
  /** FeePct: The percentage fee charged for using the channel */
  FeePct?: Maybe<Scalars['Decimal']['output']>;
  /** FeeType: The type of fee charged for using the channel */
  FeeType: FeeType;
  /** FiatCurrencyCode: The fiat currency code of the fee */
  FiatCurrencyCode: FiatCurrencyCode;
};

export type ChannelLimitConfig = {
  /** FiatCurrencyCode: The fiat currency code of the limits */
  FiatCurrencyCode: FiatCurrencyCode;
  /** MaxLimit: The maximum amount that can be sent through the channel */
  MaxLimit?: Maybe<Scalars['Decimal']['output']>;
  /** MinLimit: The minimum amount that can be sent through the channel */
  MinLimit: Scalars['Decimal']['output'];
};

export type ChannelPriceItem = {
  CryptoAmount: Scalars['Decimal']['output'];
  CryptoFeeBreakdown: Array<FeeBreakdownItem>;
  CryptoFeeTotal: Scalars['Decimal']['output'];
  FiatAmount: Scalars['Decimal']['output'];
  MarketPrice: MarketPrice;
  PaymentMethodCategory: PaymentMethodCategory;
};

export type ChannelPrices = {
  Prices: Array<ChannelPriceItem>;
};

export type ChannelPricesInput = {
  Category?: InputMaybe<PaymentMethodCategory>;
  Country?: InputMaybe<Scalars['CountryCode']['input']>;
  DestinationAmount?: InputMaybe<Scalars['Decimal']['input']>;
  DestinationCurrency: Scalars['AssetID']['input'];
  SourceAmount?: InputMaybe<Scalars['Decimal']['input']>;
  SourceCurrency: Scalars['AssetID']['input'];
};

export type ChannelsInput = {
  Country?: InputMaybe<Scalars['CountryCode']['input']>;
  CryptoCurrency: CurrencyCode;
  CustomerID?: InputMaybe<Scalars['CustomerID']['input']>;
  Direction?: InputMaybe<TransactionDirection>;
  FiatAmount?: InputMaybe<Scalars['Decimal']['input']>;
  FiatCurrency?: InputMaybe<FiatCurrencyCode>;
  FiatPaymentMethodID?: InputMaybe<Scalars['FiatPaymentMethodID']['input']>;
  paging?: InputMaybe<PagingInput>;
};

export type ChannelsPage = {
  items: Array<Scalars['AWSJSON']['output']>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export enum CheckoutStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Settled = 'Settled',
}

export type Choice = {
  Actions: Array<Action>;
  /** Conditions: a list of conditions to be met for the choice to be executed. A null conditions means an else choice */
  Conditions?: Maybe<Array<ChoiceCondition>>;
};

export type ChoiceAction = Action & {
  Choices: Array<Choice>;
  ID: Scalars['ID']['output'];
  Type: ActionType;
};

export type ChoiceCondition = {
  ComparisonOperator: ComparisonOperator;
  Value: Scalars['String']['output'];
};

export enum CkoMetadataType {
  admin_user_ids = 'admin_user_ids',
  compliance_reason = 'compliance_reason',
  reverses = 'reverses',
}

export enum CkoWebhookType {
  dispute_canceled = 'dispute_canceled',
  dispute_evidence_required = 'dispute_evidence_required',
  dispute_expired = 'dispute_expired',
  dispute_lost = 'dispute_lost',
  dispute_resolved = 'dispute_resolved',
  dispute_won = 'dispute_won',
  payment_approved = 'payment_approved',
  payment_authentication_failed = 'payment_authentication_failed',
  payment_canceled = 'payment_canceled',
  payment_capture_declined = 'payment_capture_declined',
  payment_captured = 'payment_captured',
  payment_declined = 'payment_declined',
  payment_expired = 'payment_expired',
  payment_pending = 'payment_pending',
  payment_refund_declined = 'payment_refund_declined',
  payment_refunded = 'payment_refunded',
  payment_void_declined = 'payment_void_declined',
  payment_voided = 'payment_voided',
  payout_declined = 'payout_declined',
  payout_paid = 'payout_paid',
}

/** Claim: user claim input */
export type ClaimInput = {
  /** Code: claim code */
  Code: Scalars['String']['input'];
};

export type ClaimOtpInput = {
  OtpCode: Scalars['String']['input'];
  OtpPurpose: OtpPurpose;
};

export type ClaimedPromotion = {
  /** Claims: IDs and amounts for the transactions / orders for which a promotion was applied against. */
  Claims: Array<PromotionClaim>;
  /** PromoID: ID of the promotion that was used */
  PromoID: Scalars['PromoID']['output'];
  /** UsedAmount: Amount of the promotion that was used */
  UsedAmount: FiatAmount;
  /** UserID: ID of the user that claimed the promotion */
  UserID: Scalars['UserID']['output'];
  /** Version: version to increment when updating ClaimedPromotion */
  Version: Scalars['Int']['output'];
};

export enum ComparisonOperator {
  EQ = 'EQ',
  GTEQ = 'GTEQ',
  LTEQ = 'LTEQ',
}

export type ComplianceRefundInput = {
  PaymentID: Scalars['String']['input'];
  RetoolID: Scalars['String']['input'];
};

export type ComplianceRefundResponse = {
  RefundID: Scalars['UUID']['input'];
};

export type CookieConsent = {
  advertising?: Maybe<Scalars['Boolean']['output']>;
  functional: Scalars['Boolean']['output'];
  marketingAndAnalytics?: Maybe<Scalars['Boolean']['output']>;
};

export type CookieConsentInput = {
  advertising?: InputMaybe<Scalars['Boolean']['input']>;
  functional: Scalars['Boolean']['input'];
  marketingAndAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoreCurrencyNetworkConfiguration = {
  /** assetBasis: basis of this currency, which can be UTXO based or Account based. Only set if considered relevant */
  assetBasis?: InputMaybe<AssetBasis>;
  /**
   * assetID: how to identify this asset on the network it runs on. For ethereum
   * based tokens it will be the address contract.
   */
  assetID?: InputMaybe<Scalars['String']['input']>;
  /** fireblocksID: is the ID of this currency in the fireblocks API */
  fireblocksID: Scalars['String']['input'];
  /**
   * precision: how many decimal places this currency supports. For Ethereum based
   * tokens this is defined by the smart contract.
   */
  precision: Scalars['Int']['input'];
};

/** CountLimit: A limit for the number of transactions over a time period */
export type CountLimit = {
  /** Limit: total number of transactions */
  Limit: Scalars['Int']['output'];
  /** Usage: used number of transactions */
  Usage?: Maybe<Scalars['Int']['output']>;
};

export type CounterpartyAddressInput = {
  City: Scalars['String']['input'];
  Country: Scalars['CountryCode']['input'];
  PostCode: Scalars['String']['input'];
  Street: Scalars['String']['input'];
};

export type CounterpartyInput = {
  AccountNo?: InputMaybe<Scalars['String']['input']>;
  Address?: InputMaybe<CounterpartyAddressInput>;
  BankCountry: Scalars['CountryCode']['input'];
  Bic?: InputMaybe<Scalars['String']['input']>;
  BsbCode?: InputMaybe<Scalars['String']['input']>;
  Clabe?: InputMaybe<Scalars['String']['input']>;
  CompanyName: Scalars['String']['input'];
  Currency: FiatCurrencyCode;
  Iban?: InputMaybe<Scalars['String']['input']>;
  Ifsc?: InputMaybe<Scalars['String']['input']>;
  RoutingNumber?: InputMaybe<Scalars['String']['input']>;
  SortCode?: InputMaybe<Scalars['String']['input']>;
};

export type CountryConfig = {
  /** Country: The supported country of the channel */
  Country: Scalars['CountryCode']['output'];
  /** Enabled: Whether country is enabled */
  Enabled: Scalars['Boolean']['output'];
};

export type CreateApiKeyInput = {
  /** Expires: Optional expiration date for the API key. If not provided, the key does not expire. */
  Expires?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** Label: A unique label for the API key. */
  Label: Scalars['String']['input'];
  /** PublicKey: base64 encoded X.509 SubjectPublicKeyInfo */
  PublicKey?: InputMaybe<Scalars['String']['input']>;
  /** Signature: signature with user's NonCustodyKey, of the form v1/DateTime/base64(sign(ApiSigningKey/DateTime/PublicKey)) */
  Signature?: InputMaybe<Scalars['String']['input']>;
};

/** CryptoAmount: amount in cryptocurrency */
export type CryptoAmount = {
  /** Amount: amount of cryptocurrency */
  Amount: Scalars['Decimal']['output'];
  /** Currency: cryptocurrency the amount is for */
  Currency: CurrencyCode;
  /** FetchedAt: the time when the rate was fetched */
  FetchedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Price: price of the cryptocurrency in fiat */
  Price?: Maybe<Scalars['Decimal']['output']>;
};

/** CurrencyCode: the currency codes e.g. BTC / ETH */
export enum CurrencyCode {
  /** BTC: Bitcoin currency */
  BTC = 'BTC',
  /** BTC_TEST: Testnet Bitcoin */
  BTC_TEST = 'BTC_TEST',
  /** ETH: Etherum currency */
  ETH = 'ETH',
  /** ETH_TEST_SEPOLIA: Testnet Ethereum (SEPOLIA) */
  ETH_TEST_SEPOLIA = 'ETH_TEST_SEPOLIA',
  /** MATIC: Matic currency */
  MATIC = 'MATIC',
  /** MATIC_TEST: Testnet Matic */
  MATIC_TEST = 'MATIC_TEST',
  /** NOAH: Noah's currency */
  NOAH = 'NOAH',
  /** SOL: Solana currency */
  SOL = 'SOL',
  /** SOL_TEST: Devnet Solana */
  SOL_TEST = 'SOL_TEST',
  /** TRX: Tron currency */
  TRX = 'TRX',
  /** TRX_TEST: Testnet Tron */
  TRX_TEST = 'TRX_TEST',
  /** USDC: USD Coin currency */
  USDC = 'USDC',
  /** USDC_TEST: Testnet USD Coin */
  USDC_TEST = 'USDC_TEST',
  /** USDT: Tether USD currency */
  USDT = 'USDT',
  /** USDT_TEST: Testnet Tether USD */
  USDT_TEST = 'USDT_TEST',
}

/** CurrencyDisplayType: the type of currency used to display in the app */
export enum CurrencyDisplayType {
  Crypto = 'Crypto',
  Fiat = 'Fiat',
}

/** CurrencyUnit: the units a currency can be displayed in e.g. SATS / GWEI */
export enum CurrencyUnit {
  /** BITS: A BTC unit - 1 BTC = 1,000,000 BITS */
  BITS = 'BITS',
  /** Default: Uses the default unit of the Crypto currency */
  Default = 'Default',
  /** GWEI: An ETH unit - 1 ETH = 10^9 GWEI */
  GWEI = 'GWEI',
  /** SATS: A BTC unit - 1 BTC = 100,000,000 SATS */
  SATS = 'SATS',
  /** WEI: An ETH unit - 1 ETH = 10^18 WEI */
  WEI = 'WEI',
}

export type Customer = {
  /** ComplianceState: details a user's compliance status */
  ComplianceState?: Maybe<UserComplianceState>;
  Created: Scalars['AWSDateTime']['output'];
  CreationSource: CustomerCreationSource;
  CustomerID: Scalars['CustomerID']['output'];
  Type: CustomerType;
  UserID: Scalars['UserID']['output'];
};

export enum CustomerCreationSource {
  Api = 'Api',
}

export type CustomerIdentity = {
  ExpiryDate?: Maybe<Scalars['AWSDate']['output']>;
  IDNumber: Scalars['String']['output'];
  IDType: IdentityType;
  IssuedDate?: Maybe<Scalars['AWSDate']['output']>;
  IssuingCountryCode: Scalars['CountryCode']['output'];
};

export type CustomerIdentityInput = {
  ExpiryDate?: InputMaybe<Scalars['AWSDate']['input']>;
  IDNumber: Scalars['String']['input'];
  IDType: IdentityType;
  IssuedDate?: InputMaybe<Scalars['AWSDate']['input']>;
  IssuingCountryCode: Scalars['CountryCode']['input'];
};

export type CustomerInput = {
  /** CustomerID: the unique identifier of this customer */
  CustomerID: Scalars['String']['input'];
};

export enum CustomerType {
  Business = 'Business',
  Individual = 'Individual',
}

export type CustomersInput = {
  paging?: InputMaybe<PagingInput>;
};

export type CustomersPage = {
  items: Array<IndividualCustomer>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** DDBTableNames: an enum describing the tables that are created in DynamoDB */
export type DdbTableNames = {
  accounts: Scalars['DDBTableName']['input'];
  adminusers: Scalars['DDBTableName']['input'];
  apikeys2: Scalars['DDBTableName']['input'];
  badges: Scalars['DDBTableName']['input'];
  businessprofiles: Scalars['DDBTableName']['input'];
  cardissuernotifications: Scalars['DDBTableName']['input'];
  channels: Scalars['DDBTableName']['input'];
  channelsconfigs: Scalars['DDBTableName']['input'];
  /** checkoutdocumets: (CORE-619) Stores webhooks from Checkout(merchant) */
  checkoutdocuments: Scalars['DDBTableName']['input'];
  checkoutsessions2: Scalars['DDBTableName']['input'];
  ckopayoutstatuses: Scalars['DDBTableName']['input'];
  compliancerequests: Scalars['DDBTableName']['input'];
  customers: Scalars['DDBTableName']['input'];
  depositaddresses: Scalars['DDBTableName']['input'];
  depositorderids: Scalars['DDBTableName']['input'];
  earnedbadges: Scalars['DDBTableName']['input'];
  fiatdeposits: Scalars['DDBTableName']['input'];
  fiatpaymentmethods: Scalars['DDBTableName']['input'];
  fiatpaymentreferencecounter: Scalars['DDBTableName']['input'];
  fiatpaymentreferences: Scalars['DDBTableName']['input'];
  fiatpayments: Scalars['DDBTableName']['input'];
  finserviceaddresses: Scalars['DDBTableName']['input'];
  finserviceaddressindexes: Scalars['DDBTableName']['input'];
  fireblockswebhooks: Scalars['DDBTableName']['input'];
  flutterwavepayoutstatuses: Scalars['DDBTableName']['input'];
  formsessions: Scalars['DDBTableName']['input'];
  fundingbalances: Scalars['DDBTableName']['input'];
  leadbankpayoutstatuses: Scalars['DDBTableName']['input'];
  liquidityorders: Scalars['DDBTableName']['input'];
  manualramppayments: Scalars['DDBTableName']['input'];
  marketingcodes: Scalars['DDBTableName']['input'];
  marketprices: Scalars['DDBTableName']['input'];
  noahpoints: Scalars['DDBTableName']['input'];
  otpcodes: Scalars['DDBTableName']['input'];
  paymentrequests: Scalars['DDBTableName']['input'];
  policyordersv2: Scalars['DDBTableName']['input'];
  policyusage: Scalars['DDBTableName']['input'];
  promotionclaims: Scalars['DDBTableName']['input'];
  promotions: Scalars['DDBTableName']['input'];
  pubsubhandledevents: Scalars['DDBTableName']['input'];
  questcompletions: Scalars['DDBTableName']['input'];
  quests: Scalars['DDBTableName']['input'];
  questtaskstatuses: Scalars['DDBTableName']['input'];
  remiteepayoutstatuses: Scalars['DDBTableName']['input'];
  revolutaccounts: Scalars['DDBTableName']['input'];
  ruleexecutions: Scalars['DDBTableName']['input'];
  rulerefs: Scalars['DDBTableName']['input'];
  rules: Scalars['DDBTableName']['input'];
  ruletriggerindexes: Scalars['DDBTableName']['input'];
  statemachinesuspendstates: Scalars['DDBTableName']['input'];
  tazapaypayoutstatuses: Scalars['DDBTableName']['input'];
  transactions: Scalars['DDBTableName']['input'];
  transactionsmetadata: Scalars['DDBTableName']['input'];
  travelruledeposits: Scalars['DDBTableName']['input'];
  userconsent: Scalars['DDBTableName']['input'];
  userfinserviceprofile: Scalars['DDBTableName']['input'];
  usernames: Scalars['DDBTableName']['input'];
  users: Scalars['DDBTableName']['input'];
  uservault: Scalars['DDBTableName']['input'];
  vaspaddresses: Scalars['DDBTableName']['input'];
  walletverifications: Scalars['DDBTableName']['input'];
  webhookdeliveries: Scalars['DDBTableName']['input'];
  webhooksubscriptions: Scalars['DDBTableName']['input'];
  whitelist: Scalars['DDBTableName']['input'];
  withdrawrequests: Scalars['DDBTableName']['input'];
};

export type DeleteApiKeyInput = {
  /** KeyHash: The API key hash to delete. */
  KeyHash: Scalars['String']['input'];
  /** Label: A label of the key to delete. */
  Label: Scalars['String']['input'];
};

export type DeliveryLog = {
  DynamoID: Scalars['DynamoID']['output'];
  EventType: WebhookEventType;
  FirstSent: Scalars['AWSDateTime']['output'];
  HTTPCode: Scalars['Int']['output'];
  LastSent: Scalars['AWSDateTime']['output'];
  Occurred: Scalars['AWSDateTime']['output'];
  URL: Scalars['String']['output'];
  UserID: Scalars['UserID']['output'];
  Version: Scalars['String']['output'];
  WebhookData: Scalars['AWSJSON']['output'];
};

/** DepositAddress: address where user can directly deposit funds */
export type DepositAddress = {
  /**
   * AccountID: the ID of the account this balance belongs to, of the form <UserID>/<CurrencyCode>/<Type>
   *
   * @deprecated New deposit addresses use UserID instead
   */
  AccountID?: Maybe<Scalars['AccountID']['output']>;
  /** AddressFormat: format of the address */
  AddressFormat: AddressFormat;
  /**
   * CurrencyCode: the currency code of this account's base currency e.g. BTC / ETH
   *
   * @deprecated New deposit addresses are specific to AddressFormat not a currency
   */
  CurrencyCode?: Maybe<CurrencyCode>;
  /** DestinationAddress: address user can deposit funds to */
  DestinationAddress: DestinationAddress;
  /** UserID: identifies the owner of this deposit address */
  UserID: Scalars['UserID']['output'];
  /** VaultAccountID: ID of the Fireblocks vault account */
  VaultAccountID: Scalars['ID']['output'];
};

/**
 * DepositAddressRequest: querying a deposit address for a specific network in the account create input.
 * If Create bool is set the deposit address will be created if one does not exists.
 */
export type DepositAddressRequest = {
  Create: Scalars['Boolean']['input'];
  Network: Network;
};

/** DepositAddressesIndexedFields: index fields on ledger deposit addresses tables */
export enum DepositAddressesIndexedFields {
  AccountID = 'AccountID',
  DestinationAddress = 'DestinationAddress',
}

/** DepositAddressesInput: GraphQL input to obtain deposit addresses for a user given currency and network (UserID will come from JWT) */
export type DepositAddressesInput = {
  /** CurrencyCode: the currency code of the account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** Network: the network for the currency to obtain deposit address. */
  Network: Network;
};

export type DepositBuysInput = {
  /** ChannelID: channel to use for the buy. */
  ChannelID: Scalars['UUID']['input'];
  /** CryptoCurrency: the crypto currency to buy */
  CryptoCurrency: CurrencyCode;
};

export type DepositBuysResponse = {
  /** AccountHolderName: The name of the account owner. */
  AccountHolderName: Scalars['String']['output'];
  /** AccountNumber: The account number. */
  AccountNumber: Scalars['String']['output'];
  /** BankAddress: The address of the bank. */
  BankAddress: StreetAddress;
  /** BankCode. The bank code. Can be routing number of US accounts or BIC for Sepa. */
  BankCode: Scalars['String']['output'];
  /** BankName: The name of the bank. */
  BankName: Scalars['String']['output'];
  /** PaymentMethodID: The FiatPaymentMethodID used for deposit. */
  PaymentMethodID: Scalars['FiatPaymentMethodID']['output'];
  /** PaymentMethodType: The method used for the account. */
  PaymentMethodType: PaymentMethodType;
};

export type DepositDestinationTrigger = Trigger & {
  Amount: Scalars['Decimal']['output'];
  CryptoCurrency: CurrencyCode;
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  DepositAddress: DepositAddress;
  Network?: Maybe<Network>;
  RefundAddress: DestinationAddress;
  Type: TriggerType;
};

export type DepositSourceTrigger = Trigger & {
  Conditions: Array<DepositSourceTriggerCondition>;
  CustomerID: Scalars['CustomerID']['output'];
  SourceAddress: Scalars['Address']['output'];
  Type: TriggerType;
};

export type DepositSourceTriggerCondition = {
  CryptoCurrency: CurrencyCode;
  DecimalConditions: Array<TriggerDecimalCondition>;
  DestinationAddress: DestinationAddress;
  Network: Network;
};

/** DestinationAddress: the end location of a transaction */
export type DestinationAddress = {
  /** Address: the wallet address of the transaction */
  Address: Scalars['Address']['output'];
  /** Ref: Destination tag for XRP, used as memo for EOS/XLM */
  Ref?: Maybe<Scalars['AddressRef']['output']>;
};

/** DestinationAddressInput: single use address */
export type DestinationAddressInput = {
  /** Address: address for transfer */
  Address: Scalars['Address']['input'];
  /** Ref: Destination tag for XRP, used as memo for EOS/XLM */
  Ref?: InputMaybe<Scalars['AddressRef']['input']>;
};

export enum DetailType {
  AppSyncEvent = 'AppSyncEvent',
  InboundEvent = 'InboundEvent',
  PubSubEvent = 'PubSubEvent',
  WebhookEvent = 'WebhookEvent',
}

/** EarnedBadge: badge that user has earned */
export type EarnedBadge = {
  /** BadgeTypeID: identifies the type of this badge */
  BadgeTypeID: Scalars['BadgeTypeID']['output'];
  /** Difficulty: difficulty of the quest granting this badge */
  Difficulty: Scalars['Int']['output'];
  /** Earned: date when this badge was earned */
  Earned: Scalars['AWSDateTime']['output'];
  /** Opens: date from which the badge is displayed to users */
  Opens: Scalars['AWSDateTime']['output'];
  /** QuestID: identifies the quest ID that granted this badge */
  QuestID?: Maybe<Scalars['QuestID']['output']>;
  /** UserID: identifies the owner of this badge */
  UserID: Scalars['UserID']['output'];
};

/** EarnedBadgesInput: input for querying earned badges */
export type EarnedBadgesInput = {
  paging?: InputMaybe<PagingInput>;
};

export type EarnedBadgesPage = {
  items: Array<EarnedBadge>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** EntityAccountID: ID of a reserved Account. */
export enum EntityAccountId {
  /**
   * System: Singular black hole account that applies to all currencies.
   *
   * NOAH tokens can be minted from this account and burned by sending to this account.
   */
  System = 'System',
}

export enum EnvVarNames {
  /** ACCOUNTS_BUCKET_NAME: bucket name of json qldb accounts table backup */
  ACCOUNTS_BUCKET_NAME = 'ACCOUNTS_BUCKET_NAME',
  /** AWS_APPCONFIG_EXTENSION_LOG_LEVEL: see https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-integration-lambda-extensions.html */
  AWS_APPCONFIG_EXTENSION_LOG_LEVEL = 'AWS_APPCONFIG_EXTENSION_LOG_LEVEL',
  /** AWS_APPCONFIG_EXTENSION_POLL_INTERVAL_SECONDS: see https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-integration-lambda-extensions.html */
  AWS_APPCONFIG_EXTENSION_POLL_INTERVAL_SECONDS = 'AWS_APPCONFIG_EXTENSION_POLL_INTERVAL_SECONDS',
  /** AWS_APPCONFIG_EXTENSION_PREFETCH_LIST: see https://docs.aws.amazon.com/appconfig/latest/userguide/appconfig-integration-lambda-extensions.html */
  AWS_APPCONFIG_EXTENSION_PREFETCH_LIST = 'AWS_APPCONFIG_EXTENSION_PREFETCH_LIST',
  /** BALANCES_BUCKET_NAME: bucket name of json qldb balances table backup */
  BALANCES_BUCKET_NAME = 'BALANCES_BUCKET_NAME',
  /** EVENT_BUS_ARN: arn of an event bus to send events to */
  EVENT_BUS_ARN = 'EVENT_BUS_ARN',
  /** REWARDS_BUCKET_NAME: bucket name of json qldb rewards table backup */
  REWARDS_BUCKET_NAME = 'REWARDS_BUCKET_NAME',
  /** STATE_MACHINE_ARN: arn of a state machine that can be started */
  STATE_MACHINE_ARN = 'STATE_MACHINE_ARN',
  /** TRANSACTION_BUCKET_NAME: bucket name of json qldb transactions table backup */
  TRANSACTIONS_BUCKET_NAME = 'TRANSACTIONS_BUCKET_NAME',
  /** WITHDRAW_ORDERS_BUCKET_NAME: bucket name of qldb withdraworders table backup */
  WITHDRAW_ORDERS_BUCKET_NAME = 'WITHDRAW_ORDERS_BUCKET_NAME',
}

export type ErrorCatch = {
  /** Error: error message */
  Error: Scalars['String']['output'];
};

/** ErrorExtensionType: documented and whitelisted error extension keys. */
export enum ErrorExtensionType {
  /** Debug: used for debugging, not exposed to clients */
  Debug = 'Debug',
  /** Features: features with array of reasons */
  Features = 'Features',
  /** Request: describes the request properties that contain errors */
  Request = 'Request',
  /** Stacktrace: function call stack at the time of error */
  Stacktrace = 'Stacktrace',
}

export type EstimateBalanceInput = {
  TargetAsset: Scalars['AssetID']['input'];
};

export type EstimateBalanceResponse = {
  Available: Scalars['Decimal']['output'];
  Total: Scalars['Decimal']['output'];
};

/** EventBridgeInput: Describes the schema of an event expected by EventBridge. */
export type EventBridgeInput = {
  /** Detail: the raw event as a json string. */
  Detail: Scalars['AWSJSON']['output'];
  /** DetailType: a string enum describing the event. */
  DetailType: DetailType;
  /** EventBusName: the name of the target event bus (generated by cdk). */
  EventBusName: Scalars['String']['output'];
  /** Source: the source of the event - used for routing events in EventBridge. */
  Source: EventSource;
};

/**
 * EventBridgeOutput: Do not modify - set by AWS. Describes the full type that is sent from EventBridge to its consumers.
 * EventBridge automatically appends these properties and you can see them in the relevant CloudWatch logs.
 * Note: it is possible to filter some of the fields of the payload in the `Detail` field but so far, we don't.
 */
export type EventBridgeOutput = {
  /** ID: Unique ID of the event (generated by AWS). */
  ID: Scalars['EventID']['output'];
  /** Region: The AWS Region of the originating event. */
  Region: Scalars['String']['output'];
  /** Resources: currently unused. */
  Resources: Array<Scalars['String']['output']>;
  /** Time: RFC3339 timestamp of the received event. */
  Time: Scalars['AWSDateTime']['output'];
  /** Version: of the event, defaults to '0'. */
  Version: Scalars['String']['output'];
};

/** EventIndexedFields: index fields on ledger events tables (e.g. fireblocks & moonpay) */
export enum EventIndexedFields {
  Hash = 'Hash',
  ID = 'ID',
  PublicID = 'PublicID',
}

/** EventOrigin: Where did the event originate from */
export enum EventOrigin {
  DynamoDbFiatPayment = 'DynamoDbFiatPayment',
  LedgerTransaction = 'LedgerTransaction',
  LedgerWithdrawOrder = 'LedgerWithdrawOrder',
}

export enum EventSource {
  CkoWebhook = 'CkoWebhook',
  FireblocksWebhook = 'FireblocksWebhook',
  LeadBankWebhook = 'LeadBankWebhook',
  LndInvoicesWebhook = 'LndInvoicesWebhook',
  LndPaymentsWebhook = 'LndPaymentsWebhook',
  NoahEventPub = 'NoahEventPub',
  NoahUser = 'NoahUser',
  OpenpaydWebhook = 'OpenpaydWebhook',
  OryWebhook = 'OryWebhook',
  RevolutWebhook = 'RevolutWebhook',
  SardineWebhook = 'SardineWebhook',
  SigningWebhook = 'SigningWebhook',
  SumSubWebhook = 'SumSubWebhook',
  TazapayWebhook = 'TazapayWebhook',
  VoltWebhook = 'VoltWebhook',
}

export enum ExecutionEnv {
  Bitcoin = 'Bitcoin',
  Evm = 'Evm',
  Svm = 'Svm',
}

/** ExternalIntegration: external system that Noah is integrated with */
export enum ExternalIntegration {
  ApplePay = 'ApplePay',
  Cko = 'Cko',
  Coingecko = 'Coingecko',
  EthRpc = 'EthRpc',
  Fireblocks = 'Fireblocks',
  Flutterwave = 'Flutterwave',
  IbanApi = 'IbanApi',
  LeadBank = 'LeadBank',
  Novu = 'Novu',
  Onafriq = 'Onafriq',
  Openpayd = 'Openpayd',
  Ory = 'Ory',
  Remitee = 'Remitee',
  Revolut = 'Revolut',
  Sardine = 'Sardine',
  Scorechain = 'Scorechain',
  Segment = 'Segment',
  SolRpc = 'SolRpc',
  SumSub = 'SumSub',
  Tazapay = 'Tazapay',
  WebhookSubscriber = 'WebhookSubscriber',
  Woorton = 'Woorton',
}

export type FailPaymentInput = {
  ID: Scalars['DynamoID']['input'];
};

export type FailPaymentResponse = {
  Status: PaymentStatus;
};

export type FailureStep = {
  /** Source: source of the failure */
  Source: TransactionStepSource;
  /** Step: step of the failure */
  Step: ReconciliationResult;
};

/**
 * Feature: Feature available to user.
 * If adding a new feature, please verify whether it should be enabled for suspended users and add here: apps/be/core/pkg/feature/flag.go
 */
export enum Feature {
  /** Bitrefill: Buy from Bitrefill. */
  Bitrefill = 'Bitrefill',
  /** Business: features reserved for business users. */
  Business = 'Business',
  /** Buy: Buy cryptocurrency via channels. */
  Buy = 'Buy',
  /** Deposit: Deposit currency to account. */
  Deposit = 'Deposit',
  /** Kyc: Register Know Your Customer details. */
  Kyc = 'Kyc',
  /** LnInvoice: Create a lightning invoice. */
  LnInvoice = 'LnInvoice',
  /** LnPayment: Make a lightning payment. */
  LnPayment = 'LnPayment',
  /** Notifications: Receives notifications (account activity, transactions etc.). */
  Notifications = 'Notifications',
  /** Orchestration: Allowing businesses to use orchestration. */
  Orchestration = 'Orchestration',
  /** Pin: Register a secure PIN. */
  Pin = 'Pin',
  /** Sell: Sell cryptocurrency via channels. */
  Sell = 'Sell',
  /** Sumsub: Register Know Your Customer details with Sumsub. */
  Sumsub = 'Sumsub',
  /** UIPromotions: display in-app promotional & marketing material */
  UIPromotions = 'UIPromotions',
  /** UIRewards: display in-app access to rewards / quests etc. */
  UIRewards = 'UIRewards',
  /** Withdraw: Withdraw currency from account. */
  Withdraw = 'Withdraw',
}

/** FeatureFlag: Feature's availability for a user. */
export type FeatureFlag = {
  /** Currencies: A list of currencies for which this feature applies to. */
  Currencies?: Maybe<Array<CurrencyCode>>;
  /** Enabled: Can the user use this feature. */
  Enabled: Scalars['Boolean']['output'];
  /** Feature: Feature which availability the flag controls. */
  Feature: Feature;
  /** Networks: A list of networks for which this feature applies to. */
  Networks?: Maybe<Array<Network>>;
  /** Reason: Reason for the feature's status. */
  Reason?: Maybe<Array<FeatureFlagReason>>;
};

export type FeatureFlagInput = {
  Currencies?: InputMaybe<Array<CurrencyCode>>;
  Enabled: Scalars['Boolean']['input'];
  Feature: Feature;
  Networks?: InputMaybe<Array<Network>>;
  Reason?: InputMaybe<Array<FeatureFlagReason>>;
};

export enum FeatureFlagReason {
  /** BetaAccess: Founding members can access this feature. */
  BetaAccess = 'BetaAccess',
  /** GloballyDisabled: Feature is disabled for all users. */
  GloballyDisabled = 'GloballyDisabled',
  /** Kyc: KYC is required for this feature */
  Kyc = 'Kyc',
  /** Maintenance: Feature is in maintenance mode and disabled for all users. */
  Maintenance = 'Maintenance',
  /** RestrictedRegion: User's region is restricted */
  RestrictedRegion = 'RestrictedRegion',
  /** Suspended: account is suspended */
  Suspended = 'Suspended',
  /** TravelRule: Feature is disabled due to Travel Rule restrictions */
  TravelRule = 'TravelRule',
}

export type FeeBreakdownItem = {
  Amount: Scalars['Decimal']['output'];
  Type: FeeBreakdownItemType;
};

export enum FeeBreakdownItemType {
  Business = 'Business',
  Channel = 'Channel',
}

export type FeeConfig = {
  /** FeeBase: Optional base fee that is added to the percentage fee */
  FeeBase?: Maybe<Scalars['Decimal']['output']>;
  /** FeeMin: The minimum fee charged for using the channel */
  FeeMin: Scalars['Decimal']['output'];
  /** FeePct: The percentage fee charged for using the channel */
  FeePct?: Maybe<Scalars['Decimal']['output']>;
  /** FiatCurrencyCode: The fiat currency code of the fee */
  FiatCurrencyCode: FiatCurrencyCode;
};

export enum FeeType {
  FinServiceFee = 'FinServiceFee',
  ServiceFee = 'ServiceFee',
}

/** FiatAmount: amount in fiat currency */
export type FiatAmount = {
  /** Amount: amount of fiat currency */
  Amount: Scalars['Decimal']['output'];
  /** CryptoAmounts: amount converted to cryptocurrency in exchange rate */
  CryptoAmounts?: Maybe<Array<CryptoAmount>>;
  /** FetchedAt: the time when the rate was fetched */
  FetchedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  /** FiatCurrency: used fiat currency */
  FiatCurrency: FiatCurrencyCode;
  /** Price: price of the cryptocurrency in fiat */
  Price?: Maybe<Scalars['Decimal']['output']>;
};

/** FiatAmount: amount in fiat currency */
export type FiatAmountCryptoAmountsArgs = {
  CurrencyCodes?: InputMaybe<Array<CurrencyCode>>;
};

/** FiatAmountInput: amount in fiat currency */
export type FiatAmountInput = {
  /** Amount: amount of fiat currency */
  Amount: Scalars['Decimal']['input'];
  /** FetchedAt: the time when the rate was fetched */
  FetchedAt: Scalars['AWSDateTime']['input'];
  /** Currency: used fiat currency */
  FiatCurrency: FiatCurrencyCode;
  /** Price: price of the cryptocurrency in fiat */
  Price: Scalars['Decimal']['input'];
};

/** FiatCurrencyCode: fiat currency codes e.g. USD / GBP */
export enum FiatCurrencyCode {
  AED = 'AED',
  ARS = 'ARS',
  AUD = 'AUD',
  BDT = 'BDT',
  BHD = 'BHD',
  BMD = 'BMD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CZK = 'CZK',
  DKK = 'DKK',
  ETB = 'ETB',
  EUR = 'EUR',
  GBP = 'GBP',
  GHS = 'GHS',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  KWD = 'KWD',
  LKR = 'LKR',
  MMK = 'MMK',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  NGN = 'NGN',
  NOK = 'NOK',
  NZD = 'NZD',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  TWD = 'TWD',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  VEF = 'VEF',
  VND = 'VND',
  XAF = 'XAF',
  XOF = 'XOF',
  ZAR = 'ZAR',
}

/** FiatDeposit: a fiat currency deposit transaction received from a finservice */
export type FiatDeposit = {
  /** AddressID: references the address with the finservice */
  AddressID: Scalars['String']['output'];
  /** Created: timestamp of when the deposit was created */
  Created: Scalars['AWSDateTime']['output'];
  /** CustomerID: the customer who received the deposit to the FiatPaymentMethodID */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** FiatPaymentMethodID: identifier of the payment method that received the deposit */
  FiatPaymentMethodID: Scalars['FiatPaymentMethodID']['output'];
  /** FinService: the financial service used for processing the deposit */
  FinService: FinService;
  /** ID: unique identifier of the fiat deposit */
  ID: Scalars['String']['output'];
  /** PaymentAmount: the amount of the deposit */
  PaymentAmount: FiatAmount;
  /** PaymentMethodType: The method used for the fiat deposit */
  PaymentMethodType: PaymentMethodType;
  /** Reference: payment reference on the deposit */
  Reference?: Maybe<Scalars['String']['output']>;
  /** Refunds: details about the refund if this deposit was refunded */
  Refunds?: Maybe<Array<FiatPaymentRefund>>;
  /** RefundsVersion: version of the refunds field */
  RefundsVersion?: Maybe<Scalars['Int']['output']>;
  /** Sender: the sender fiat payment method */
  Sender: SenderFiatPaymentMethod;
  /** Status: The current status of the deposit (e.g. pending, completed, failed) */
  Status: FiatDepositStatus;
  /** TransactionReference: identification of the transaction with the finservice */
  TransactionReference: Scalars['String']['output'];
  /** UserID: the user who received the deposit to the FiatPaymentMethodID */
  UserID: Scalars['UserID']['output'];
};

export enum FiatDepositStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Settled = 'Settled',
}

export type FiatDepositTrigger = Trigger & {
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  FiatCurrency: FiatCurrencyCode;
  FormSessionID: Scalars['UUID']['output'];
  PaymentMethodID: Scalars['FiatPaymentMethodID']['output'];
  Reference?: Maybe<Scalars['String']['output']>;
  Type: TriggerType;
};

/** FiatPayment: reference of an incoming or outgoing fiat payment */
export type FiatPayment = {
  /** Channel: The channel used for the payment */
  Channel?: Maybe<Channel>;
  /** ComplianceData: Compliance metadata for the payment */
  ComplianceData?: Maybe<PaymentComplianceData>;
  /** FailureCause: The reason why the payment failed */
  FailureCause?: Maybe<NoahError>;
  /**
   * FeeFiatAmount: How much fiat fee applies to this payment. When debiting the
   * user this amount is included in the PaymentAmount. When crediting the user
   * this amount is excluded from the PaymentAmount.
   */
  FeeFiatAmount: Scalars['Decimal']['output'];
  /** DepositID: identifies the deposit when the payment originated from a deposit. */
  FiatDepositID?: Maybe<Scalars['String']['output']>;
  /**
   * FiatPaymentMethod: The state of the payment method at the time the payment
   * was created. This might differ from the current state.
   */
  FiatPaymentMethod?: Maybe<FiatPaymentMethod>;
  /** Frontend: Which frontend (not necessarily UI) initiated this fiat payment. */
  Frontend?: Maybe<FiatPaymentFrontend>;
  /** LiquidityOrderID: ID of liquidity order used to offset this payment, empty if not yet offset */
  LiquidityOrderID?: Maybe<Scalars['String']['output']>;
  /** OrderCurrency: Currency of the order */
  OrderCurrency: CurrencyCode;
  /** PaymentAmount: Fiat amount that has been received / sent */
  PaymentAmount: FiatAmount;
  /** PaymentID: ID of the payment reference */
  PaymentID: Scalars['String']['output'];
  /** PaymentStatus: Status of the payment */
  PaymentStatus: FiatPaymentStatus;
  /** PaymentType: Direction of the payment */
  PaymentType: TransactionDirection;
  /** Refunds: refunds of this payment */
  Refunds?: Maybe<Array<FiatPaymentRefund>>;
  /** RefundsVersion: version of the refunds field */
  RefundsVersion?: Maybe<Scalars['Int']['output']>;
  /** RequestTime: timestamp when the request for the funds was made */
  RequestTime: Scalars['AWSDateTime']['output'];
  /** TokenizationSource: source of the payment tokenization */
  TokenizationSource?: Maybe<FiatPaymentTokenizationSource>;
  /** UserID: ID of the user purchasing crypto */
  UserID: Scalars['UserID']['output'];
};

export type FiatPaymentAppleSessionInput = {
  ValidationURL: Scalars['AWSURL']['input'];
};

export type FiatPaymentAppleSessionResponse = {
  MerchantSession: Scalars['String']['output'];
};

export type FiatPaymentBank = FiatPaymentMethodDetails & {
  /** AccountCurrency: what is this currency this account holds. */
  AccountCurrency: FiatCurrencyCode;
  /**
   * AccountNumber: identifies the bank account under a certain system. May
   * contain letters and even special characters. This is the key way to identify
   * the account but in some systems it might not be enough on its own.
   */
  AccountNumber: Scalars['String']['output'];
  /** AccountType: the type of the bank account. */
  AccountType?: Maybe<BankAccountType>;
  /**
   * BankCode: identifies the bank under a certain system. This can be
   * significantly different for each system but ultimately represents the bank
   * holding the account. May be optional for some banking systems.
   */
  BankCode?: Maybe<Scalars['String']['output']>;
  /**
   * BankCountry: the country where the bank is officially registered in.
   * This cannot be reliably derived from:
   *   * the account currency as some banks allow it's users to hold accounts in
   *   multiple currencies and some currencies are used in multiple countries.
   *   * the account holder address because some banks cater for foreign customers
   */
  BankCountry: Scalars['CountryCode']['output'];
  /** BranchCode: identifies the branch of the bank where the account is held. */
  BranchCode?: Maybe<Scalars['String']['output']>;
  /** Payin: payin capabilities */
  Payin: Payin;
  /** Payout: payout capabilities */
  Payout: Payout;
  /**
   * System: the scheme to identify the recipient. Some systems require more data
   * than others and it often partially overlaps. Some systems may require very
   * unique data. The potential combinations are virtually unmaintainable if we
   * were to create separate types for each so we use a flat structure and this
   * field to indicate what should be the expected scheme. Validation should
   * happen at the application layer.
   */
  System: BankingSystem;
};

export type FiatPaymentBankSaveInput = {
  /** AccountCurrency: currency this account holds. */
  AccountCurrency: FiatCurrencyCode;
  /** AccountHolderDetails: account holder details */
  AccountHolderDetails?: InputMaybe<AccountHolderDetailsInput>;
  /**
   * AccountNumber: identifies the bank account under a certain system. May
   * contain letters and even special characters. This is the key way to identify
   * the account but in some systems it might not be enough on its own.
   */
  AccountNumber: Scalars['String']['input'];
  /** AccountType: the type of the bank account. */
  AccountType?: InputMaybe<BankAccountType>;
  /**
   * BankCode: identifies the bank under a certain system. This can be
   * significantly different for each system but ultimately represents the bank
   * holding the account. May be optional for some banking systems.
   */
  BankCode?: InputMaybe<Scalars['String']['input']>;
  /** BankCountry: the country where the bank is officially registered in. */
  BankCountry: Scalars['CountryCode']['input'];
  /** BranchCode: identifies the branch of the bank where the account is held. */
  BranchCode?: InputMaybe<Scalars['String']['input']>;
  /**
   * System: the scheme to identify the recipient. Some systems require more data
   * than others and it often partially overlaps. Some systems may require very
   * unique data. The potential combinations are virtually unmaintainable if we
   * were to create separate types for each so we use a flat structure and this
   * field to indicate what should be the expected scheme. Validation should
   * happen at the application layer.
   */
  System: BankingSystem;
};

/** FiatPaymentCard: a card payment method associated with a user */
export type FiatPaymentCard = FiatPaymentMethodDetails & {
  /** BillingAddress: the billing address for the payment method */
  BillingAddress: StreetAddress;
  /** Bin: the bank identification number of the card */
  Bin: Scalars['String']['output'];
  /** Currency: currency in which a credit card issuer processes and bills the cardholder’s transactions. */
  Currency?: Maybe<FiatCurrencyCode>;
  /** ExpiryMonth: the expiry month of the card. Contains 1-2 characters */
  ExpiryMonth: Scalars['Int']['output'];
  /** ExpiryYear: the expiry year of the card. Contains 4 characters */
  ExpiryYear: Scalars['Int']['output'];
  /** Fingerprint: the fingerprint of the card */
  Fingerprint: Scalars['String']['output'];
  /** Issuer: the issuer of the card */
  Issuer?: Maybe<Scalars['String']['output']>;
  /**
   * IssuerCountry: The card issuer's country (two-letter ISO code).
   *
   * This field is required for new FiatPaymentCards. For existing cards, if this field is not present when read from the database, it must be retrieved and added.
   */
  IssuerCountry?: Maybe<Scalars['CountryCode']['output']>;
  /** Last4: the last 4 digits of the card */
  Last4: Scalars['String']['output'];
  /** Payin: payin capabilities */
  Payin: Payin;
  /** Payout: payout capabilities */
  Payout: Payout;
  /** Scheme: the Scheme of the card */
  Scheme: FiatPaymentCardScheme;
};

export enum FiatPaymentCardScheme {
  Mastercard = 'Mastercard',
  Visa = 'Visa',
}

/** FiatPaymentDetailsInput: details of the payment being used in the request */
export type FiatPaymentDetailsInput = {
  /**
   * ID: ID of the payment corresponding to the type (token / instrumentID / paymentMethodID) etc if applicable.
   * Current types that require ID are CheckoutToken and CheckoutSourceID.
   */
  ID?: InputMaybe<Scalars['FiatPaymentMethodID']['input']>;
  /** Type: type of the payment to use */
  Type: FiatPaymentType;
};

export enum FiatPaymentFrontend {
  HostedCheckout = 'HostedCheckout',
  NoahApp = 'NoahApp',
}

export type FiatPaymentIdentifier = FiatPaymentMethodDetails & {
  /** Currency: accepted currency. */
  Currency: FiatCurrencyCode;
  /** Identifier: account identifier. */
  Identifier: Scalars['String']['output'];
  /** IdentifierType: type of account identifier in the payment system. */
  IdentifierType: PaymentIdentifierType;
  /** Issuer: Issuer of the PaymentMethod. In case of MobileMoney this is a mobile money operator. */
  Issuer?: Maybe<Scalars['String']['output']>;
  /** Payin: payin capabilities */
  Payin: Payin;
  /** Payout: payout capabilities */
  Payout: Payout;
  /** System: payment system */
  System: IdentifierPaymentSystem;
};

export type FiatPaymentIdentifierInput = {
  /** AccountHolderDetails: account holder details */
  AccountHolderDetails?: InputMaybe<AccountHolderDetailsInput>;
  /** Currency: accepted currency. */
  Currency: FiatCurrencyCode;
  /** Identifier: account identifier. */
  Identifier: Scalars['String']['input'];
  /** IdentifierType: type of account identifier in the payment system. */
  IdentifierType: PaymentIdentifierType;
  /** System: payment system */
  System: IdentifierPaymentSystem;
};

export type FiatPaymentInput = {
  /** CurrencyCode: the currency code of the purchase currency. */
  CurrencyCode: CurrencyCode;
  /** FinService: FinService to use for payment. */
  FinService: FinService;
  /**
   * Nonce: When provided we garantee that only one payment will ever be created
   * for the same Nonce. This can be used to safely retry payment requests.
   */
  Nonce?: InputMaybe<Scalars['Nonce']['input']>;
  /** PaymentDetails: payment details for the payment. */
  PaymentDetails: FiatPaymentDetailsInput;
  /** RequestedAmount: fiat amount that user entered to buy. */
  RequestedAmount: FiatRequestedAmountInput;
  /** TokenizationSource: source of the payment tokenization */
  TokenizationSource: FiatPaymentTokenizationSource;
};

/** FiatPaymentIntegration: the integration of a payment method */
export type FiatPaymentIntegration = {
  /** FinService: finservice for the integration */
  FinService?: Maybe<FinService>;
};

/**
 * FiatPaymentIntegrationAccount: integration where an account is created with the finservice
 *
 * @deprecated Use FiatPaymentIntegrationAddress instead.
 */
export type FiatPaymentIntegrationAccount = FiatPaymentIntegration & {
  /** AccountID: references the account with the finservice. E.g. for Openpayd this is the internal account ID. */
  AccountID: Scalars['String']['output'];
  /** FinService: FinService for the bank account */
  FinService?: Maybe<FinService>;
};

/** FiatPaymentIntegrationAddress: integration where an account is created with the finservice */
export type FiatPaymentIntegrationAddress = FiatPaymentIntegration & {
  /** AddressID: references the address with the finservice. */
  AddressID: Scalars['String']['output'];
  /** FinService: FinService for the bank account */
  FinService?: Maybe<FinService>;
};

/** FiatPaymentIntegrationTokenized: integration where a token given is by a payment provider */
export type FiatPaymentIntegrationTokenized = FiatPaymentIntegration & {
  /** FinService: finservice for the token */
  FinService?: Maybe<FinService>;
  /** Provider: provider for the token */
  Provider: FiatPaymentMethodTokenProvider;
  /** Token: token for the payment method */
  Token: Scalars['String']['output'];
};

export type FiatPaymentIntegrationTokenizedInput = {
  /** FinService: finservice for the token */
  FinService: FinService;
  /** Token: token for the payment method */
  Token: Scalars['String']['input'];
};

/** FiatPaymentMethod: a payment method associated with a user */
export type FiatPaymentMethod = {
  /** AccountHolder: account holder details */
  AccountHolderDetails?: Maybe<AccountHolderDetails>;
  /** Country: the country of the payment method. */
  Country: Scalars['CountryCode']['output'];
  /** Created: the date and time the wallet item was created */
  Created: Scalars['AWSDateTime']['output'];
  /** CustomerID: ID of the customer the Payment Method was created for */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** Details: details of fiat payment method, such as last4 digits of card */
  Details: FiatPaymentMethodDetails;
  /**
   * DynamoID: the ID of the payment method that is composed of multiple parts.
   * For cards, it is [Card]/[Provider]/[Scheme]/[Fingerprint]
   */
  DynamoID: Scalars['FiatPaymentMethodID']['output'];
  /** Integration: the integration of the payment method */
  Integration?: Maybe<FiatPaymentIntegration>;
  /** IssuerDetails: details of the issuer of the payment method */
  IssuerDetails?: Maybe<IssuerDetails>;
  /** UserID: the ID of the user that owns the payment method */
  UserID: Scalars['UserID']['output'];
};

export type FiatPaymentMethodBankDisplay = {
  AccountNumber?: Maybe<Scalars['String']['output']>;
  BankCode?: Maybe<Scalars['String']['output']>;
  Type: TransactionFiatPaymentMethodDetailsType;
};

export type FiatPaymentMethodCardDisplay = {
  Last4: Scalars['String']['output'];
  Scheme?: Maybe<FiatPaymentCardScheme>;
  Type: TransactionFiatPaymentMethodDetailsType;
};

export type FiatPaymentMethodDeleteInput = {
  DynamoID: Scalars['DynamoID']['input'];
};

/** FiatPaymentMethodDetails: details of fiat payment method, such as last4 digits of card */
export type FiatPaymentMethodDetails = {
  /** Payin: payin capabilities */
  Payin: Payin;
  /** Payout: payout capabilities */
  Payout: Payout;
};

export type FiatPaymentMethodIdentifierDisplay = {
  Identifier: Scalars['String']['output'];
  IdentifierType: PaymentIdentifierType;
  Type: TransactionFiatPaymentMethodDetailsType;
};

export enum FiatPaymentMethodTokenProvider {
  Checkout = 'Checkout',
}

export type FiatPaymentMethodTokenizedCardSaveInput = {
  /** FinService: finservice for the token */
  FinService: FinService;
  /** SaveForFutureUse: whether the payment method should be saved for future use */
  SaveForFutureUse: Scalars['Boolean']['input'];
  /** Token: token for the payment method */
  Token: Scalars['String']['input'];
};

export type FiatPaymentMethodUpdateInput = {
  /** BillingAddress: New billing address */
  BillingAddress: StreetAddressInput;
  /** DynamoID: DynamoID of the payment method that is being updated. */
  DynamoID: Scalars['DynamoID']['input'];
};

export type FiatPaymentMethodWalletCardSaveInput = {
  /** Details: details of fiat payment method, such as last4 digits of card */
  Details: FiatPaymentWalletCardInput;
  /** Integration: the integration of the payment method */
  Integration: FiatPaymentIntegrationTokenizedInput;
};

export type FiatPaymentMethodsInput = {
  /** CustomerID: ID of the customer the Payment Method was created for */
  CustomerID?: InputMaybe<Scalars['CustomerID']['input']>;
  paging?: InputMaybe<PagingInput>;
};

/** FiatPaymentMethodsPage: a list of payment methods associated with a user or customer */
export type FiatPaymentMethodsPage = {
  items: Array<FiatPaymentMethod>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** @deprecated Use PaymentProvider enum instead */
export enum FiatPaymentProvider {
  Checkout = 'Checkout',
}

/** FiatPaymentReference: the reference for a fiat payment transaction */
export type FiatPaymentReference = {
  /** Created: timestamp of when the deposit was created */
  Created: Scalars['AWSDateTime']['output'];
  /** CustomerID: the customer associated with this fiat payment reference */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** DynamoID: unique identifier for the fiat payment reference */
  DynamoID: Scalars['DynamoID']['output'];
  /** FiatPaymentMethodID: identifier of the payment method used for the reference */
  FiatPaymentMethodID: Scalars['DynamoID']['output'];
  /** Reference: the reference text */
  Reference: Scalars['String']['output'];
  /** UserID: the user associated with this fiat payment reference */
  UserID: Scalars['UserID']['output'];
};

export type FiatPaymentRefund = {
  /** RefundAmount: amount to refund, may be equal or less than PaymentAmount */
  RefundAmount: FiatAmount;
  /** RefundID: identifies the refund */
  RefundID: Scalars['UUID']['output'];
  /** RequestTime: timestamp when the request for the refund was made */
  RequestTime: Scalars['AWSDateTime']['output'];
  /** Status: status of the refund */
  Status: FiatPaymentStatus;
};

export type FiatPaymentResponse = {
  /** Links: links relevant to the payment (redirects / receipts) */
  Links?: Maybe<Array<FiatPaymentResponseLink>>;
  /** Payment: the payment that was created or an already processing payment based on Nonce. */
  Payment: FiatPayment;
};

export type FiatPaymentResponseLink = {
  LinkType: Scalars['String']['output'];
  Location: Scalars['String']['output'];
};

export enum FiatPaymentStatus {
  /** Failed: funds were not received / sent */
  Failed = 'Failed',
  /** Pending: user needs to complete further actions for the funds to be captured (e.g. 3DS check) / funds are being processed */
  Pending = 'Pending',
  /** Successful: funds have been captured / sent successfully */
  Successful = 'Successful',
}

/** FiatPaymentTokenizationSource: source of the payment tokenization */
export enum FiatPaymentTokenizationSource {
  ApplePay = 'ApplePay',
  /** Checkout: Payment is tokenized by Checkout (via API or Frames) */
  Checkout = 'Checkout',
  GooglePay = 'GooglePay',
}

export enum FiatPaymentType {
  EPS = 'EPS',
  /** FiatPaymentMethodID: a payment method previously stored in our database */
  FiatPaymentMethodID = 'FiatPaymentMethodID',
  Giropay = 'Giropay',
  Sofort = 'Sofort',
}

/** FiatPaymentWalletCard: a card that represents a user's phone wallet card such as google pay / apple pay */
export type FiatPaymentWalletCard = FiatPaymentMethodDetails & {
  /** BillingAddress: the billing address for the payment method */
  BillingAddress?: Maybe<StreetAddress>;
  /** Bin: the bank identification number of the card */
  Bin: Scalars['String']['output'];
  /** ExpiryMonth: the expiry month of the card. Contains 1-2 characters */
  ExpiryMonth: Scalars['Int']['output'];
  /** ExpiryYear: the expiry year of the card. Contains 4 characters */
  ExpiryYear: Scalars['Int']['output'];
  /** Last4: the last 4 digits of the card */
  Last4: Scalars['String']['output'];
  /** Payin: payin capabilities */
  Payin: Payin;
  /** Payout: payout capabilities */
  Payout: Payout;
  /** WalletType: the type of wallet */
  WalletType: FiatPaymentWalletCardType;
};

export type FiatPaymentWalletCardInput = {
  /** BillingAddress: the billing address for the payment method */
  BillingAddress?: InputMaybe<StreetAddressInput>;
  /** Bin: the bank identification number of the card */
  Bin: Scalars['String']['input'];
  /** ExpiryMonth: the expiry month of the card. Contains 1-2 characters */
  ExpiryMonth: Scalars['Int']['input'];
  /** ExpiryYear: the expiry year of the card. Contains 4 characters */
  ExpiryYear: Scalars['Int']['input'];
  /** Last4: the last 4 digits of the card */
  Last4: Scalars['String']['input'];
  /** WalletType: the type of wallet */
  WalletType: FiatPaymentWalletCardType;
};

export enum FiatPaymentWalletCardType {
  ApplePay = 'ApplePay',
  GooglePay = 'GooglePay',
}

export type FiatPayoutInput = {
  /** ChannelID: channel ID to use for the payout. */
  ChannelID?: InputMaybe<Scalars['UUID']['input']>;
  /**
   * MaximumDeduction: maximum deduction that can be taken from user's crypto account.
   * This amount should be derived from the liquidity provider's offer price and include fees and slippage.
   */
  MaximumDeduction: Scalars['Decimal']['input'];
  /** Nonce: unique value sent from client. */
  Nonce: Scalars['Nonce']['input'];
  /** PaymentDetails: payment details for the payment. */
  PaymentDetails: FiatPaymentDetailsInput;
  /** RequestedAmount: requested amount of fiat to sell. This will be the amount user gets sent to their fiat payment method. */
  RequestedAmount: FiatAmountInput;
  /**
   * Signature: signature with user's signing key, of the form v1/DateTime/base64(sign(Nonce/DateTime/CurrencyCode/AccountType/Amount/Destination))
   * Nonce, which uniqueness is enforced, is included to verify that signature is not reused.
   * Signature is valid for 10 minutes.
   */
  Signature?: InputMaybe<Scalars['String']['input']>;
  /** SourceCurrency: what currency to sell (Crypto). */
  SourceCurrency: CurrencyCode;
};

/** FiatRequestedAmountInput: fiat amount request */
export type FiatRequestedAmountInput = {
  /** Amount: amount of fiat currency in minor currency unit (cents / pence / etc.) */
  Amount: Scalars['String']['input'];
  /** FiatCurrency: used fiat currency */
  FiatCurrency: FiatCurrencyCode;
};

/**
 * FinService: A 3rd party that provides some financial service to NOAH. This
 * could be facilitation of payments, liquidity provisioning or any other kind of
 * financial service.
 */
export enum FinService {
  /** Cko: checkout.com - Global payment processor. */
  Cko = 'Cko',
  /** Fireblocks: fireblocks.com - Digital assets management. */
  Fireblocks = 'Fireblocks',
  /** Flutterwave: flutterwave.com - African payment processor. */
  Flutterwave = 'Flutterwave',
  /** LeadBank: lead.bank - Used for bank accounts. */
  LeadBank = 'LeadBank',
  /** Onafriq: onafriq.com - African payment processor. */
  Onafriq = 'Onafriq',
  /** Openpayd: Openpayd.com - Payment processor for bank accounts. */
  Openpayd = 'Openpayd',
  /** Remitee: remitee.com - South American payment processor. */
  Remitee = 'Remitee',
  /** Revolut: revolut.com - Our treasury bank. */
  Revolut = 'Revolut',
  /** Tazapay: tazapay.com - APAC payment processor. */
  Tazapay = 'Tazapay',
  /** Volt: volt.io - Used for virtual bank accounts. */
  Volt = 'Volt',
  /** Wintermute: wintermute.com - Algo traders that provide us crypto liquidity. */
  Wintermute = 'Wintermute',
  /** Woorton: woorton.com - Market makers that provide us crypto liquidity. */
  Woorton = 'Woorton',
}

/** FireblocksTransactionStatus: transaction status from Fireblocks */
export enum FireblocksTransactionStatus {
  /** BLOCKED: the transaction was blocked due to a policy rule */
  BLOCKED = 'BLOCKED',
  /** BROADCASTING: the transaction is pending broadcast to the blockchain network */
  BROADCASTING = 'BROADCASTING',
  /**
   * CANCELLED: the transaction was cancelled or rejected by the user on the Fireblocks platform or
   * by the 3rd party service from which the funds are withdrawn
   */
  CANCELLED = 'CANCELLED',
  /** COMPLETED: transaction is completed */
  COMPLETED = 'COMPLETED',
  /** CONFIRMING: pending confirmation on the blockchain */
  CONFIRMING = 'CONFIRMING',
  /** FAILED: the transaction has failed */
  FAILED = 'FAILED',
  /** PARTIALLY_COMPLETED: (Only for Aggregated transactions) One or more of of the transaction records have completed successfully */
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  /** PENDING_3RD_PARTY: the transaction is pending approval by the 3rd party service (e.g exchange) */
  PENDING_3RD_PARTY = 'PENDING_3RD_PARTY',
  /** PENDING_3RD_PARTY_MANUAL_APPROVAL: the transaction is pending manual approval as required by the 3rd party, usually an email approval */
  PENDING_3RD_PARTY_MANUAL_APPROVAL = 'PENDING_3RD_PARTY_MANUAL_APPROVAL',
  /** PENDING_AML_SCREENING: in case the AML screening feature is enabled, transaction is pending AML screening result */
  PENDING_AML_SCREENING = 'PENDING_AML_SCREENING',
  /** PENDING_AUTHORIZATION: the transaction is pending authorization by other users (as defined in the Transaction Authorization Policy) */
  PENDING_AUTHORIZATION = 'PENDING_AUTHORIZATION',
  /** PENDING_SIGNATURE: the transaction is pending the initiator to sign the transaction */
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',
  /** QUEUED: transaction is queued. Pending for another transaction to be processed */
  QUEUED = 'QUEUED',
  /** REJECTED: the transaction was rejected by the Fireblocks system or by the 3rd party service */
  REJECTED = 'REJECTED',
  /** SUBMITTED: the transaction was submitted to the Fireblocks system and is being processed */
  SUBMITTED = 'SUBMITTED',
}

/** FireblocksTransactionSubStatus: transaction sub-status from Fireblocks */
export enum FireblocksTransactionSubStatus {
  /** CONFIRMED: transaction is confirmed on blockchain */
  CONFIRMED = 'CONFIRMED',
}

/** FireblocksWebhookEventType: types of webhook events from Fireblocks */
export enum FireblocksWebhookEventType {
  /** TRANSACTION_CREATED: transaction created in Fireblocks */
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  /** TRANSACTION_STATUS_UPDATED: transaction status updated in Fireblocks */
  TRANSACTION_STATUS_UPDATED = 'TRANSACTION_STATUS_UPDATED',
}

/**
 * FormBankAccountType: Used in the input forms to denote the type of bank accounts
 * in the local terminology of the destination.
 */
export enum FormBankAccountType {
  /**
   * Checking: is an account that is used for everyday transactions.
   * It is also similar to a current account.
   */
  Checking = 'Checking',
  /**
   * Current: is an account that is used for everyday transactions.
   * It is also similar to a checking account.
   */
  Current = 'Current',
  /** Savings: account used to earn interest on easily accessible cash */
  Savings = 'Savings',
}

export type FormSession = {
  ChannelID?: Maybe<Scalars['UUID']['output']>;
  ComplianceData?: Maybe<PaymentComplianceData>;
  Created: Scalars['AWSDateTime']['output'];
  ID: Scalars['UUID']['output'];
  PaymentMethodID: Scalars['FiatPaymentMethodID']['output'];
  Reference?: Maybe<Scalars['String']['output']>;
  UserID: Scalars['UserID']['output'];
};

/** FullName: The components of a person's name. */
export type FullName = {
  /** FirstName: user's first name */
  FirstName: Scalars['String']['output'];
  /** LastName: user's last name (family name) */
  LastName: Scalars['String']['output'];
  /** MiddleName: user's middle name */
  MiddleName?: Maybe<Scalars['String']['output']>;
};

export type FullNameInput = {
  /** FirstName: user's first name */
  FirstName: Scalars['String']['input'];
  /** LastName: user's last name (family name) */
  LastName: Scalars['String']['input'];
  /** MiddleName: user's middle name */
  MiddleName?: InputMaybe<Scalars['String']['input']>;
};

export type FundingBalance = {
  /** AssetID: the account currency code. This can be crypto or fiat. */
  AssetID: Scalars['AssetID']['output'];
  /** ExpectedBalance: how much we expect to have in this account. */
  ExpectedBalance: Scalars['Decimal']['output'];
  /** FinService: who is hosting/providing the account. */
  FinService: FinService;
  /** Outflow: total amount of pending transactions that will reduce the balance of this account once settled. */
  Outflow: Scalars['Decimal']['output'];
  /** Reference: how to reference this account in the 3rd party system. */
  Reference: Scalars['String']['output'];
};

export type FundingOrder = {
  /** Amount: how much funding was sent. */
  Amount: Scalars['Decimal']['output'];
  /** AssetID: the account currency code. This can be crypto or fiat. */
  AssetID: Scalars['AssetID']['output'];
  /** CreatedAt: when the order was created. */
  CreatedAt: Scalars['AWSDateTime']['output'];
  /** DynamoID: [FinService]/[Reference] */
  DynamoID: Scalars['DynamoID']['output'];
  /** ID: Unique identifier for the order. */
  ID: Scalars['UUID']['output'];
  /** Status: the current status of the order. */
  Status: FundingOrderStatus;
  /** UpdatedAt: when the order was last updated. */
  UpdatedAt: Scalars['AWSDateTime']['output'];
};

export enum FundingOrderStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Settled = 'Settled',
}

export enum IdentificationFileSide {
  Back = 'Back',
  Front = 'Front',
}

export enum IdentifierPaymentSystem {
  /** Mobile Money: African Mobile Money */
  MobileMoney = 'MobileMoney',
  /** Pix: Brazilian Pix */
  Pix = 'Pix',
}

export enum IdentityType {
  AddressProof = 'AddressProof',
  DrivingLicense = 'DrivingLicense',
  NationalIDCard = 'NationalIDCard',
  Passport = 'Passport',
  ResidencePermit = 'ResidencePermit',
  /** TaxID: Tax Identification Number. It might not have a file associated with it. */
  TaxID = 'TaxID',
}

export type IndividualCustomer = Customer & {
  /** ComplianceState: details a user's compliance status */
  ComplianceState?: Maybe<UserComplianceState>;
  Created: Scalars['AWSDateTime']['output'];
  CreationSource: CustomerCreationSource;
  CustomerID: Scalars['CustomerID']['output'];
  DateOfBirth: Scalars['AWSDate']['output'];
  Email?: Maybe<Scalars['AWSEmail']['output']>;
  FullName: FullName;
  Identities?: Maybe<Array<CustomerIdentity>>;
  PhoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  PrimaryResidence: StreetAddress;
  Type: CustomerType;
  UserID: Scalars['UserID']['output'];
};

export type IntercomIdentifyHashResponse = {
  UserHash?: Maybe<Scalars['String']['output']>;
};

export type InvoiceExpiryStateMachineInput = {
  ExpiryTime: Scalars['String']['input'];
  NoahID: Scalars['NoahID']['input'];
};

export type IssuerDetails = {
  /** Name: the name of the issuer of the payment method */
  Name?: Maybe<Scalars['String']['output']>;
};

/** JwtToken: JWT token for accessing services */
export type JwtToken = {
  /** Token: JWT token */
  Token: Scalars['String']['output'];
};

/** Kyb: Fields related to user's KYB verification */
export type Kyb = {
  /** BusinessName: Name of the business */
  BusinessName: Scalars['String']['output'];
  /** Url: URL to the business website */
  Url: Scalars['String']['output'];
  /** VerificationID: Verification ID from SumSub */
  VerificationID?: Maybe<Scalars['String']['output']>;
};

export enum KycActionRequired {
  HighRiskDataSubmit = 'HighRiskDataSubmit',
  MandatoryDataSubmit = 'MandatoryDataSubmit',
  Retry = 'Retry',
  Review = 'Review',
}

/** KycAddress: Address data extracted from user's KYC document(s). */
export type KycAddress = {
  /** City: name of an address's city or town. */
  City?: Maybe<Scalars['String']['output']>;
  /** CountryCode: user's country code */
  CountryCode: Scalars['CountryCode']['output'];
  /** PostCode: the address's postcode */
  PostCode?: Maybe<Scalars['String']['output']>;
  /** State: the address's state / province / county */
  State?: Maybe<Scalars['String']['output']>;
  /** Street: the primary name of an address's street. */
  Street?: Maybe<Scalars['String']['output']>;
  /** Street2: the secondary name of an address's street. */
  Street2?: Maybe<Scalars['String']['output']>;
};

export enum KycApprovalStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Pending = 'Pending',
}

export enum KycDocumentVerificationStatus {
  /** Complete: verification complete */
  Complete = 'Complete',
  /** Failed: failed verification */
  Failed = 'Failed',
  /** Pending: user has submitted the document for verification */
  Pending = 'Pending',
}

export enum KycLevel {
  Basic = 'Basic',
  Edd = 'Edd',
  Full = 'Full',
}

export enum KycRejectType {
  /** Final: rejection is final */
  Final = 'Final',
  /** Retry: user can retry the KYC process */
  Retry = 'Retry',
}

/**
 * KycRejectionData: stores data about the applicants that were rejected after following the SumSub KYC process
 * https://developers.sumsub.com/api-reference/#getting-verification-results
 */
export type KycRejectionData = {
  /** ClientComment: A human-readable comment that should not be shown to an end user, and is meant to be read by a client */
  ClientComment?: Maybe<Scalars['String']['output']>;
  /** ModerationComment: A human-readable comment that can be shown to your end user */
  ModerationComment?: Maybe<Scalars['String']['output']>;
  /** RejectLabels: list of rejection labels */
  RejectLabels: Array<Scalars['String']['output']>;
  /** RejectType: type of rejection */
  RejectType: KycRejectType;
};

/** KycVerification: Fields related to user's KYC verification, fields are null if user has not verified */
export type KycVerification = {
  /** ActionRequired: Action required to progress the KYC process */
  ActionRequired?: Maybe<KycActionRequired>;
  /** ApprovalStatus: Internal status of the user's KYC acceptance */
  ApprovalStatus?: Maybe<KycApprovalStatus>;
  /**
   * DocumentVerificationStatus: Status of the user's KYC progress in the KYC provider
   * @deprecated DocumentVerificationStatus field is deprecated and should not be used.
   */
  DocumentVerificationStatus?: Maybe<KycDocumentVerificationStatus>;
  /** Expiry: Time the verification will expire */
  Expiry?: Maybe<Scalars['AWSDateTime']['output']>;
  /** ID: Verification ID from the KYC provider, null if the user has not verified */
  ID?: Maybe<Scalars['String']['output']>;
  /** KycLevel: Which KYC level is the user currently attempting */
  KycLevel?: Maybe<KycLevel>;
  /** LastUpdated: Time the verification was last updated */
  LastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  /** RejectType: if the KYC was not successful, data about the rejection will be populated. */
  RejectionData?: Maybe<KycRejectionData>;
};

export type Ledger = {
  name: Scalars['String']['input'];
};

/**
 * LedgerTransaction:
 * Describes deposit or withdrawal transactions that are committed to our ledger.
 * With the exception of the Status and Modified* fields, all fields should be immutable once set.
 * We have indexes on: NoahID and PublicID.
 */
export type LedgerTransaction = {
  /** Amount: the incoming or outgoing absolute amount of the transaction (i.e. no -ve numbers) */
  Amount: Scalars['Decimal']['output'];
  /** AmountUSD: the usd amount (i.e. the usd amount from the fireblocks transaction) */
  AmountUSD?: Maybe<Scalars['String']['output']>;
  /** Created: date when the transaction was created */
  Created: Scalars['AWSDateTime']['output'];
  /** Currency: the currency type of the transaction */
  Currency: CurrencyCode;
  /** Destination: the end location of this transaction */
  Destination?: Maybe<DestinationAddress>;
  /** DestinationAccountID: identifies the destination account ID */
  DestinationAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** DestinationOut: for UTXO based assets this is the vOut, for Ethereum based, this is the index of the event of the contract call */
  DestinationOut?: Maybe<Scalars['Int']['output']>;
  /** DestinationRecords: records of operations perfomed on a destination transaction */
  DestinationRecords?: Maybe<Array<TransactionRecord>>;
  /** Destination Type: type of destination */
  DestinationType?: Maybe<TransferDestinationType>;
  /** FireblocksIDs: fireblocks identifiers for the public transaction */
  FireblocksIDs?: Maybe<Array<Scalars['FireblocksID']['output']>>;
  /**
   * MarketAmount: amount of fiat that the crypto was worth at the time transaction
   * or order was committed to ledger
   */
  MarketAmount?: Maybe<FiatAmount>;
  /**
   * MoonpayID: the unique identifier of this transaction
   *
   * @deprecated Moonpay is deprecated and should not be used.
   */
  MoonpayID?: Maybe<Scalars['MoonpayID']['output']>;
  /** Network: Network of the transaction */
  Network: Network;
  /** NoahID: the unique identifier of this transaction */
  NoahID: Scalars['NoahID']['output'];
  /** Operation: Operation of the transaction, such as Transfer */
  Operation: TransactionOperation;
  /** PublicID: the unique identifier of this transaction */
  PublicID: Scalars['PublicID']['output'];
  /**
   * RequestedAmount: amount of fiat that user requested to transact.
   * This amount is informative and can be manipulated by user.
   * Market and order amounts are expected to be near this number but
   * this might be what user expects to see and user might be easiser to read.
   */
  RequestedAmount?: Maybe<FiatAmount>;
  /** Reverses: transaction that this transaction reverses (may reverse partially) */
  Reverses?: Maybe<Scalars['NoahID']['output']>;
  /** Source: the origin location of this transaction */
  Source?: Maybe<SourceAddress>;
  /** SourceAccountID: identifies the source account ID */
  SourceAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** SourceID: identifier of the source */
  SourceID?: Maybe<Scalars['String']['output']>;
  /** SourceRecords: records of operations perfomed on a source transaction */
  SourceRecords?: Maybe<Array<TransactionRecord>>;
  /** SourceType: type of the source */
  SourceType?: Maybe<TransferSourceType>;
  /** Status: the current status of the transaction on the Noah Ledger */
  Status: TransactionStatus;
  /** Steps: is a record of the outcome of our validation & verification steps */
  Steps: TransactionSteps;
  /** WithdrawOrderID: withdraw order for which this transaction was created for */
  WithdrawOrderID?: Maybe<Scalars['WithdrawOrderID']['output']>;
};

/** LedgerUserLimits: user's limits that are stored in the ledger */
export type LedgerUserLimits = {
  /** Limits: user's limits */
  Limits: Array<UserLimit>;
  /** UserID: identifies the owner of the limits */
  UserID: Scalars['UserID']['output'];
};

/** LightningAddressInvoiceProxyInput: request a payment request for a lightning address */
export type LightningAddressInvoiceProxyInput = {
  Amount: Scalars['Decimal']['input'];
  LightningAddress?: InputMaybe<Scalars['String']['input']>;
  LnurlLink?: InputMaybe<Scalars['String']['input']>;
};

export type LightningAddressInvoiceProxyResponse = LightningAddressInvoiceProxySuccess | LnurlError;

/** LightningAddressInvoiceProxySuccess: payment request for a lightning address */
export type LightningAddressInvoiceProxySuccess = {
  /** PaymentRequest: lightning payment request */
  PaymentRequest: Scalars['PaymentRequest']['output'];
  /** PublicID: hex-encoded invoice hash which uniquely identifies the invoice */
  PublicID: Scalars['PublicID']['output'];
};

/** LightningAddressProxyInput: request lightning address details */
export type LightningAddressProxyInput = {
  LightningAddress?: InputMaybe<Scalars['String']['input']>;
  LnurlLink?: InputMaybe<Scalars['String']['input']>;
};

export type LightningAddressProxyResponse = LightningAddressProxySuccess | LnurlError;

/** LightningAddressProxySuccess: lightning address details response */
export type LightningAddressProxySuccess = {
  /** Description: short description to display when paying */
  Description: Scalars['String']['output'];
  /** LongDescription: long description to display when paying */
  LongDescription?: Maybe<Scalars['String']['output']>;
  /** MaxSendable: maximum value allowed for LightningAddressInvoiceProxyInput.Amount */
  MaxSendable: Scalars['Decimal']['output'];
  /** MinSendable: minimum value allowed for LightningAddressInvoiceProxyInput.Amount */
  MinSendable: Scalars['Decimal']['output'];
  /** Picture: base64 encoded 512x512px PNG or JPG thumbnail */
  Picture?: Maybe<Scalars['String']['output']>;
  /** Tag: string to identify the request type */
  Tag: Scalars['String']['output'];
};

/** LightningInvoiceCreateInput: request to create a lightning invoice */
export type LightningInvoiceCreateInput = {
  /** AccountType: which AccountType this withdrawal is from */
  AccountType: AccountType;
  /** Amount: decimal amount to withdraw in account's base currency unit (like BTC / ETH), use '0' for sender to decide the amount */
  Amount: Scalars['Decimal']['input'];
  /** CurrencyCode: the currency code of this withdrawal request */
  CurrencyCode: CurrencyCode;
  /** Description: invoice description included in payment request */
  Description?: InputMaybe<Scalars['String']['input']>;
  /**
   * RequestedAmount: fiat amount that user entered to invoice.
   * Defining this indicates that user specifically entered this amount, not only that this amount displayed as estimate.
   */
  RequestedAmount?: InputMaybe<FiatAmountInput>;
};

/** LightningInvoiceCreateResponse: response for lightning invoice creation */
export type LightningInvoiceCreateResponse = {
  /**
   * PaymentRequest: the lightning payment request string.
   * User will share this string with sender or will present a QR image generated from this string.
   */
  PaymentRequest: Scalars['PaymentRequest']['output'];
  /** PublicID: hex-encoded invoice hash which uniquely identifies the invoice */
  PublicID: Scalars['PublicID']['output'];
};

/** LightningPaymentRequest: lightning payment request */
export type LightningPaymentRequest = {
  /** Description: payment request description */
  Description?: Maybe<Scalars['String']['output']>;
  /** Expiry: time from which the invoice cannot be paid anymore */
  Expiry: Scalars['AWSDateTime']['output'];
  /** PaymentRequest: lightning payment request */
  PaymentRequest: Scalars['PaymentRequest']['output'];
};

/** LightningPaymentRequestInput: get payment request */
export type LightningPaymentRequestInput = {
  /** PublicID: hex-encoded invoice hash which uniquely identifies the invoice */
  PublicID: Scalars['PublicID']['input'];
};

/** LightningPaymentSendInput: request to pay a lightning invoice */
export type LightningPaymentSendInput = {
  /** AccountType: which AccountType this withdrawal is from */
  AccountType: AccountType;
  /** Amount: decimal amount to withdraw in account's base currency unit (like BTC / ETH). Only needed if invoice amount was 0 */
  Amount?: InputMaybe<Scalars['Decimal']['input']>;
  /** CurrencyCode: the currency code of this withdrawal request */
  CurrencyCode: CurrencyCode;
  /** DestinationType: destination type */
  DestinationType?: InputMaybe<TransferDestinationTypeInput>;
  /** Nonce: unique value sent from client. */
  Nonce?: InputMaybe<Scalars['Nonce']['input']>;
  /** PaymentRequest: the lightning payment request string */
  PaymentRequest: Scalars['PaymentRequest']['input'];
  /**
   * RequestedAmount: fiat amount that user entered to send.
   * Defining this indicates that user specifically entered this amount, not only that this amount displayed as estimate.
   */
  RequestedAmount?: InputMaybe<FiatAmountInput>;
  /**
   * Signature: signature with user's signing key, of the form v1/DateTime/base64(sign(Nonce/DateTime/CurrencyCode/AccountType/Amount/Destination))
   * Nonce, which uniqueness is enforced, is included to verify that signature is not reused.
   * Signature is valid for 10 minutes.
   */
  Signature?: InputMaybe<Scalars['String']['input']>;
};

export type LimitPolicy = {
  customerWithdrawDayLimitEur?: InputMaybe<Scalars['Decimal']['input']>;
  networkLimits: Array<InputMaybe<NetworkLimit>>;
  /** unsignedLimitEur: Signature is mandatory beyond this amount */
  unsignedLimitEur?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawDayLimitEur: Scalars['Decimal']['input'];
};

export type LineItem = {
  Description: Scalars['String']['output'];
  Quantity: Scalars['Decimal']['output'];
  TotalAmount: Scalars['Decimal']['output'];
  UnitAmount: Scalars['Decimal']['output'];
};

export type LiquidityOrder = {
  /** Amount: oustanding amount to be filled */
  Amount: Scalars['Decimal']['output'];
  /** Currency of the order */
  Currency: CurrencyCode;
  /** FiatCurrency: Fiat currency used for the order */
  FiatCurrency: FiatCurrencyCode;
  /** ID: Unique identifier of the order */
  ID: Scalars['String']['output'];
  /** PlaceTime: Time at which the order was placed, null if still filling */
  PlaceTime?: Maybe<Scalars['AWSDateTime']['output']>;
  /** PlacedPrice: Price at which the order was placed, null if still filling */
  PlacedPrice?: Maybe<Scalars['Decimal']['output']>;
  /** Settlement: Information about how this liquidity order was settled */
  Settlement?: Maybe<LiquiditySettlement>;
  /** Status: Status of the order */
  Status: LiquidityOrderStatus;
};

export enum LiquidityOrderStatus {
  Filling = 'Filling',
  Placing = 'Placing',
  Settled = 'Settled',
}

export type LiquidityPrice = {
  /** BuyPrice: the best price to buy */
  BuyPrice: Scalars['Decimal']['output'];
  /** FetchedAt: the time the price was taken */
  FetchedAt: Scalars['AWSDateTime']['output'];
  /** SellPrice: the best price to sell */
  SellPrice: Scalars['Decimal']['output'];
};

export enum LiquidityProvider {
  Woorton = 'Woorton',
}

export type LiquiditySettlement = {
  /**
   * GroupID: This refers to how the liquidity order was executed. Every order
   * should be settled as part of a group. Groups may contain only 1 liquidity orders.
   * This can be used as dedup identifier with the provider.
   */
  GroupID: Scalars['String']['output'];
  /** Price: The price at which the order was settled at. */
  Price?: Maybe<Scalars['Decimal']['output']>;
  /** Provider: Who provided liquidity for this order. */
  Provider?: Maybe<LiquidityProvider>;
  /** Quantity: The total amount of the asset traded as part of this settlement. */
  Quantity?: Maybe<Scalars['Decimal']['output']>;
  /** Reference: The provider identifier for the order that settled this. */
  Reference?: Maybe<Scalars['String']['output']>;
  /** SettledAt: When the order was settled at with the provider. */
  SettledAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

/** LnurlError: error from LNURL endpoint */
export type LnurlError = {
  /** Domain: LN Service domain name */
  Domain: Scalars['String']['output'];
  /** Reason: freetext reason from LN Service */
  Reason: Scalars['String']['output'];
  /** Status: LNURL status from LN Service such as ERROR */
  Status: Scalars['String']['output'];
};

export enum LogLevel {
  debug = 'debug',
  error = 'error',
  info = 'info',
  none = 'none',
  warn = 'warn',
}

export enum LogObject {
  AlertEvent = 'AlertEvent',
  LedgerResult = 'LedgerResult',
}

export enum LoggerMode {
  Dev = 'Dev',
  Prod = 'Prod',
}

/** MainLedgerTables: an enum describing the tables that are created in the main Ledger */
export enum MainLedgerTables {
  accounts = 'accounts',
  apikeys = 'apikeys',
  balances = 'balances',
  depositaddresses = 'depositaddresses',
  fireblocks = 'fireblocks',
  moonpay = 'moonpay',
  requestsignatures = 'requestsignatures',
  rewards = 'rewards',
  signingkeys = 'signingkeys',
  transactions = 'transactions',
  userlimits = 'userlimits',
  withdraworders = 'withdraworders',
}

export type ManualFinopsInput = {
  Amount: Scalars['Decimal']['input'];
  CurrencyCode: CurrencyCode;
  Network: Network;
  Nonce: Scalars['Nonce']['input'];
};

export type ManualRampCurrencySettings = {
  FeeMinimumFiatAmount: Scalars['Decimal']['input'];
  FeePercentage: Scalars['Decimal']['input'];
  FiatCurrency: FiatCurrencyCode;
  MinimumFiatAmount: Scalars['Decimal']['input'];
};

export enum MarketDataService {
  CoinGecko = 'CoinGecko',
  Flutterwave = 'Flutterwave',
  Remitee = 'Remitee',
  Tazapay = 'Tazapay',
}

export type MarketPrice = {
  /** FetchedAt: the time the price was taken */
  FetchedAt: Scalars['AWSDateTime']['output'];
  /** Price: price of the requested currency in fiat currency */
  Price: Scalars['Decimal']['output'];
};

export type MarketPriceInput = {
  /** CurrencyCode: the currency to get market price for */
  CurrencyCode: CurrencyCode;
  /** FiatCurrency: used fiat currency */
  FiatCurrency: FiatCurrencyCode;
};

export type MarketingCode = {
  EndedResponse: Scalars['String']['output'];
  Ends: Scalars['AWSDateTime']['output'];
  MarketingCodeID: Scalars['MarketingCodeID']['output'];
  OpenResponse: Scalars['String']['output'];
  Source: Scalars['String']['output'];
  Starts?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type MarketingCodeCreateInput = {
  /** EndedResponse: response text when code is applied after end date */
  EndedResponse?: InputMaybe<Scalars['String']['input']>;
  /** Ends: time the code is no longer valid */
  Ends: Scalars['AWSDateTime']['input'];
  /** Open Response: response text on successful application of code */
  OpenResponse?: InputMaybe<Scalars['String']['input']>;
  /** Name: Name of the source of the marketing code */
  Source: Scalars['String']['input'];
  /** Starts: time the code begins to be valid */
  Starts?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type MarketingCodeInput = {
  /** Code: marketing code */
  Code: Scalars['String']['input'];
};

export type MarketingCodeResponse = {
  /** Description: description of the outcome of using the code */
  Description: Scalars['String']['output'];
};

export type ModelDecimalInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  ge?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  le?: InputMaybe<Scalars['Decimal']['input']>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  ne?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ModelDestinationInput = {
  Address?: InputMaybe<ModelStringInput>;
  Ref?: InputMaybe<ModelStringInput>;
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  accountCreate: AccountCreateResponse;
  claim: AwsEventBridgeResponse;
  createApiKey: ApiKey;
  deleteApiKey: ApiKey;
  depositBuys: DepositBuysResponse;
  fiatPayment: FiatPaymentResponse;
  fiatPaymentAppleSession: FiatPaymentAppleSessionResponse;
  fiatPaymentMethodBankSave: FiatPaymentMethod;
  fiatPaymentMethodDelete: FiatPaymentMethod;
  fiatPaymentMethodTokenizedCardSave: FiatPaymentMethod;
  fiatPaymentMethodUpdate: FiatPaymentMethod;
  fiatPaymentMethodWalletCardSave: FiatPaymentMethod;
  fiatPayout: AwsEventBridgeResponse;
  lightningInvoiceCreate: LightningInvoiceCreateResponse;
  lightningPaymentSend: AwsEventBridgeResponse;
  marketingCode: MarketingCodeResponse;
  pubAccountUpdate: SubAccountResponse;
  sardineDevices: SardineDevicesResponse;
  sendOtp: SendOtpResponse;
  sumSubAccessToken: SumSubAccessTokenResponse;
  updateUserLimits: UserLimitUpdateResponse;
  userAppSettingsUpdate: UserProfile;
  userFiatPaymentCurrencyUpdate: UserProfile;
  userMetadataUpdate: UserProfile;
  userProfileCreate: UserProfile;
  userSettingsConsentCreate: UserSettingsConsent;
  webhookSubscriptionCreate: WebhookSubscription;
  webhookSubscriptionDelete: WebhookSubscription;
  whitelistAddressCreate: WhitelistAddress;
  whitelistAddressDelete: WhitelistAddress;
  withdrawOrderCreate: WithdrawOrder;
};

export type MutationAccountCreateArgs = {
  Input?: InputMaybe<AccountCreateInput>;
};

export type MutationClaimArgs = {
  Input: ClaimInput;
};

export type MutationCreateApiKeyArgs = {
  Input: CreateApiKeyInput;
};

export type MutationDeleteApiKeyArgs = {
  Input: DeleteApiKeyInput;
};

export type MutationDepositBuysArgs = {
  Input: DepositBuysInput;
};

export type MutationFiatPaymentArgs = {
  Input: FiatPaymentInput;
};

export type MutationFiatPaymentAppleSessionArgs = {
  Input: FiatPaymentAppleSessionInput;
};

export type MutationFiatPaymentMethodBankSaveArgs = {
  Input: FiatPaymentBankSaveInput;
};

export type MutationFiatPaymentMethodDeleteArgs = {
  Input: FiatPaymentMethodDeleteInput;
};

export type MutationFiatPaymentMethodTokenizedCardSaveArgs = {
  Input: FiatPaymentMethodTokenizedCardSaveInput;
};

export type MutationFiatPaymentMethodUpdateArgs = {
  Input: FiatPaymentMethodUpdateInput;
};

export type MutationFiatPaymentMethodWalletCardSaveArgs = {
  Input: FiatPaymentMethodWalletCardSaveInput;
};

export type MutationFiatPayoutArgs = {
  Input: FiatPayoutInput;
};

export type MutationLightningInvoiceCreateArgs = {
  Input: LightningInvoiceCreateInput;
};

export type MutationLightningPaymentSendArgs = {
  Input: LightningPaymentSendInput;
};

export type MutationMarketingCodeArgs = {
  Input: MarketingCodeInput;
};

export type MutationPubAccountUpdateArgs = {
  Input: Scalars['AWSJSON']['input'];
};

export type MutationSendOtpArgs = {
  Input: SendOtpInput;
};

export type MutationSumSubAccessTokenArgs = {
  Input: SumSubAccessTokenInput;
};

export type MutationUpdateUserLimitsArgs = {
  Input: UserLimitUpdateInput;
};

export type MutationUserAppSettingsUpdateArgs = {
  Input: UserAppSettingsUpdateInput;
};

export type MutationUserFiatPaymentCurrencyUpdateArgs = {
  Input: UserFiatPaymentCurrencyUpdate;
};

export type MutationUserMetadataUpdateArgs = {
  Input: UserMetadataUpdateInput;
};

export type MutationUserProfileCreateArgs = {
  Input: UserProfileCreateInput;
};

export type MutationUserSettingsConsentCreateArgs = {
  Input: UserSettingsConsentInput;
};

export type MutationWebhookSubscriptionCreateArgs = {
  Input: WebhookSubscriptionCreateInput;
};

export type MutationWebhookSubscriptionDeleteArgs = {
  Input: WebhookSubscriptionDeleteInput;
};

export type MutationWhitelistAddressCreateArgs = {
  Input: WhitelistAddressCreateInput;
};

export type MutationWhitelistAddressDeleteArgs = {
  Input: WhitelistAddressDeleteInput;
};

export type MutationWithdrawOrderCreateArgs = {
  Input?: InputMaybe<WithdrawOrderCreateRequestInput>;
};

/** Network: Payments network */
export enum Network {
  /** Bitcoin: Bitcoin mainnet */
  Bitcoin = 'Bitcoin',
  /** BitcoinTest: Bitcoin testnet (version 3) */
  BitcoinTest = 'BitcoinTest',
  /** Ethereum: Ethereum mainnet */
  Ethereum = 'Ethereum',
  /** Ethereum: Ethereum testnet (Sepolia) */
  EthereumTestSepolia = 'EthereumTestSepolia',
  /** Lightning: Lightning mainnet */
  Lightning = 'Lightning',
  /** LightningTest: Lightning testnet */
  LightningTest = 'LightningTest',
  /** OffNetwork: Transaction outside crypto network */
  OffNetwork = 'OffNetwork',
  /** PolygonPos: Polygon Pos network */
  PolygonPos = 'PolygonPos',
  /** PolygonTestAmoy: Polygon testnet (Amoy) */
  PolygonTestAmoy = 'PolygonTestAmoy',
  /**
   * PolygonTestMumbai: Polygon testnet (Mumbai)
   *
   * @deprecated Mumbai testnet is not functional.
   */
  PolygonTestMumbai = 'PolygonTestMumbai',
  /** Solana: Solana mainnet */
  Solana = 'Solana',
  /** SolanaDevnet: Solana devnet */
  SolanaDevnet = 'SolanaDevnet',
  /** Tron: Tron network */
  Tron = 'Tron',
  /** TronTestShasta: Tron testnet (Shasta) */
  TronTestShasta = 'TronTestShasta',
}

export type NetworkLimit = {
  customerWithdrawDayLimitEur?: InputMaybe<Scalars['Decimal']['input']>;
  network: Network;
  withdrawDayLimitEur?: InputMaybe<Scalars['Decimal']['input']>;
  withdrawSingleLimitEur?: InputMaybe<Scalars['Decimal']['input']>;
};

/** NoahError: error that Noah has encountered */
export type NoahError = {
  /** Action: What was the code trying to do when the error occurred */
  Action: Scalars['String']['output'];
  /** Cause: the raw error object */
  Cause: Scalars['ErrorObject']['output'];
  /** Detail: (from RFC7807) A human-readable explanation specific to this occurrence of the problem */
  Detail: Scalars['String']['output'];
  /** Extensions: (from RFC7807) extra error data object with fields named in ErrorExtensionType */
  Extensions?: Maybe<Scalars['ErrorExtensions']['output']>;
  /** Handler: Handler in which the error occurred */
  Handler: Scalars['String']['output'];
  /** Instance: (from RFC7807) A unique reference that identifies the specific occurrence of the problem */
  Instance: Scalars['String']['output'];
  /** Status: (from RFC7807) The advised HTTP status code applicable to this problem */
  Status: Scalars['Int']['output'];
  /** Title: (from RFC7807) The string description that comes from the Type enum */
  Title: Scalars['String']['output'];
  /** Type: (from RFC7807) Enum about what caused this error */
  Type: NoahErrorType;
};

/** NoahErrorType: (from RFC7807) Enum about what caused this error */
export enum NoahErrorType {
  /** AwaitingFunding: a system needs funding to perform payment */
  AwaitingFunding = 'AwaitingFunding',
  /** AwaitingLiquidity: liquidity order has not been placed */
  AwaitingLiquidity = 'AwaitingLiquidity',
  /** BalanceTransition: balance couldn't be updated because the new balance would be illegal */
  BalanceTransition = 'BalanceTransition',
  /** Conflict: operation conflicts with current state of the resource. */
  Conflict = 'Conflict',
  /** DropMessage: a message that is droppe invalid */
  DropMessage = 'DropMessage',
  /** Duplicate: received duplicate event or message */
  Duplicate = 'Duplicate',
  /** DynamoDbExecute: error reading or writing data to dynamo db */
  DynamoDbExecute = 'DynamoDbExecute',
  /** ExternalConnection: connection to external system failed or timed out */
  ExternalConnection = 'ExternalConnection',
  /** ExternalSystem: unexpected error in an external system */
  ExternalSystem = 'ExternalSystem',
  /** Forbidden: access forbidden, client is authenticated but not allowed */
  Forbidden = 'Forbidden',
  /** InsufficientBalance: insufficient balance in user's account */
  InsufficientBalance = 'InsufficientBalance',
  /** InsufficientLiquidity: insufficient liquidity to perform operation */
  InsufficientLiquidity = 'InsufficientLiquidity',
  /** InternalConnection: connection to internal system failed or timed out */
  InternalConnection = 'InternalConnection',
  /** InternalSystem: unexpected error interacting with an internal system */
  InternalSystem = 'InternalSystem',
  /** InvalidEmail: invalid email */
  InvalidEmail = 'InvalidEmail',
  /** InvalidMessage: message is invalid */
  InvalidMessage = 'InvalidMessage',
  /** InvalidRewardTarget: when the target of a reward is not supposed receive rewards */
  InvalidRewardTarget = 'InvalidRewardTarget',
  /** InvalidUsername: invalid username */
  InvalidUsername = 'InvalidUsername',
  /** LedgerExecute: error reading or writing data to ledger */
  LedgerExecute = 'LedgerExecute',
  /** Misconfiguration: misconfiguration in infrastructure */
  Misconfiguration = 'Misconfiguration',
  /** PaymentRejection: payment entity rejected request */
  PaymentRejection = 'PaymentRejection',
  /** PolicyViolation: violated one or more policies */
  PolicyViolation = 'PolicyViolation',
  /** ReconcileIntermittent: could not reconcile message at this time */
  ReconcileIntermittent = 'ReconcileIntermittent',
  /** ResourceNotFound: resource required for the operation wasn't found */
  ResourceNotFound = 'ResourceNotFound',
  /** TransactionDetailsMissing: transaction details missing */
  TransactionDetailsMissing = 'TransactionDetailsMissing',
  /** Unauthorized: client is not authenticated */
  Unauthorized = 'Unauthorized',
  /** Unexpected: encountered an unexpected error */
  Unexpected = 'Unexpected',
  /** UnsupportedCurrency: currency is not supported */
  UnsupportedCurrency = 'UnsupportedCurrency',
  /** UserIdExists: user has already created a profile */
  UserIdExists = 'UserIdExists',
  /** UserProfileUpdateFailed: failed to update user profile in ddb */
  UserProfileUpdateFailed = 'UserProfileUpdateFailed',
  /** UsernameExists: username has already been registered */
  UsernameExists = 'UsernameExists',
}

export type NovuSubscriberHashResponse = {
  SubscriberHash: Scalars['String']['output'];
};

export type OnchainOut = {
  /** Amount: amount sent */
  Amount: Scalars['Decimal']['output'];
  /**
   * AssetID: for etherum transactions, this should be the smart contract address
   * for the token.
   */
  AssetID?: Maybe<Scalars['String']['output']>;
  /** DestinationAddress: destination address */
  DestinationAddress?: Maybe<DestinationAddress>;
  /**
   * DestinationOut: identify the transfer within the transaction. In the scenario
   * where only one transfer occured then there will be just one instance with a
   * zero index.
   */
  DestinationOut: Scalars['Int']['output'];
};

export type OnchainTransaction = {
  /** Confirmations: number of onchain confirmation */
  Confirmations: Scalars['Int']['output'];
  /** Outs: transaction destinations */
  Outs?: Maybe<Array<OnchainOut>>;
};

export type OpsCurrencyNetworkConfiguration = {
  /** funding: settings used for the funding and re-balancing of this currency. */
  funding: AppConfigFundingSettings;
  /**
   * minimumSweepAmount: The amount above which the intermediate sweeper will attempt sweeping. Only relevant for account-based currencies.
   * If minimumSweepAmount is not set then sweeping is disabled for the currency.
   */
  minimumSweepAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

/** OrderLimitPolicy: user's order limit policy and usage */
export type OrderLimitPolicy = {
  /** CountLimit: describes a limit on number of transactions */
  CountLimit?: Maybe<CountLimit>;
  /** Network: describes the network to which the limit is applied */
  Network?: Maybe<Network>;
  /** OrderType: describes the type of order */
  OrderType: OrderType;
  /** Period: describes the period of time to which the limit is applied */
  Period: PolicyPeriod;
  /** VolumeLimit: describes a limit on total volume */
  VolumeLimit?: Maybe<VolumeLimit>;
};

/** OrderType: Describes the type of order for currency transfers */
export enum OrderType {
  /** DepositOrder: currency is transferred into the user's account */
  DepositOrder = 'DepositOrder',
  /** WithdrawOrder: currency is transferred out of the user's account */
  WithdrawOrder = 'WithdrawOrder',
}

export type OryTransientPayload = {
  referralCode?: Maybe<Scalars['String']['output']>;
  sessionKey: Scalars['String']['output'];
};

export type Otp = {
  /** DynamoTTL: time to live before item is marked for deletion and considered expired */
  DynamoTTL: Scalars['Int']['output'];
  /** OtpCodeHash: Hash of an One-Time-Password code */
  OtpCodeHash: Scalars['String']['output'];
  /** Purpose: Use case that demanded this OTP */
  Purpose: OtpPurpose;
  /** UserID: ID of source user */
  UserID: Scalars['UserID']['output'];
};

/** OtpPurpose: One-time-password purpose */
export enum OtpPurpose {
  /** PinReset: PIN reset */
  PinReset = 'PinReset',
}

export type PagingInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};

/** ParamStoreName: Parameter name enum in SSM Parameter Store */
export enum ParamStoreName {
  /** ApplePayMerchantIDCert: Certificate for requesting Apple Pay payment sessions */
  ApplePayMerchantIDCert = 'ApplePayMerchantIDCert',
  /** ApplePayMerchantIDKey: Key for requesting Apple Pay payment sessions */
  ApplePayMerchantIDKey = 'ApplePayMerchantIDKey',
  /** CheckoutClientID: Checkout Oauth Client ID */
  CheckoutClientID = 'CheckoutClientID',
  /** CheckoutClientSecret: Checkout Oauth Client Secret */
  CheckoutClientSecret = 'CheckoutClientSecret',
  /** CheckoutPublicKey: Checkout Public Key */
  CheckoutPublicKey = 'CheckoutPublicKey',
  /** CheckoutSecretKey: Checkout Secret Key */
  CheckoutSecretKey = 'CheckoutSecretKey',
  /** FireblocksCosignerCallbackSecretKey: Fireblocks API Co-Signer SGX public key */
  FireblocksApiCosignerSgxPubKey = 'FireblocksApiCosignerSgxPubKey',
  /** FireblocksCosignerCallbackSecretKey: Fireblocks Co-Signer user secret key */
  FireblocksCosignerCallbackSecretKey = 'FireblocksCosignerCallbackSecretKey',
  /** FireblocksEditorApiToken: Token to make Fireblocks API requests */
  FireblocksEditorApiToken = 'FireblocksEditorApiToken',
  /** FireblocksEditorSecretKey: Key to sign Fireblocks API requests */
  FireblocksEditorSecretKey = 'FireblocksEditorSecretKey',
  /** FireblocksWebhookVerifyKey: Key to verify Fireblocks webhook requests */
  FireblocksWebhookVerifyKey = 'FireblocksWebhookVerifyKey',
  /** FlutterwaveSecretKey: Flutterwave Secret Key */
  FlutterwaveSecretKey = 'FlutterwaveSecretKey',
  /** IBANApiApiKey: IBANAPI Api Key */
  IBANApiApiKey = 'IBANApiApiKey',
  /** IntercomIDSecret: Intercom Identity secret */
  IntercomIDSecret = 'IntercomIDSecret',
  /** LeadClientID: Lead Bank Client ID */
  LeadClientID = 'LeadClientID',
  /** LeadClientSecret: Lead Bank Client Secret */
  LeadClientSecret = 'LeadClientSecret',
  /** LeadSecretKey: Lead Bank Webhook Secret Key */
  LeadSecretKey = 'LeadSecretKey',
  /** NomuPayPrivateKey: Private key for signing a NomuPay API requests */
  NomuPayPrivateKey = 'NomuPayPrivateKey',
  /** NovuApiKey: Novu API Key */
  NovuApiKey = 'NovuApiKey',
  /** OpenpayWebhookPublicKey: Key to verify Openpayd webhook requests */
  OpenpayWebhookPublicKey = 'OpenpayWebhookPublicKey',
  /** OpenpaydPassword: Openpayd API Password */
  OpenpaydPassword = 'OpenpaydPassword',
  /** OpenpaydUsername: Openpayd API Username */
  OpenpaydUsername = 'OpenpaydUsername',
  /** OryAdminAPIKeyDeveloper: Generated by Ory to authenticate against the developer Ory api */
  OryAdminAPIKeyDeveloper = 'OryAdminAPIKeyDeveloper',
  /** OryAdminApiKey: Generated by Ory to authenticate against the Ory api */
  OryAdminApiKey = 'OryAdminApiKey',
  /** OryOIDCClientSecretApple: Apple OIDC Provider Client Secret */
  OryOIDCClientSecretApple = 'OryOIDCClientSecretApple',
  /** OryOIDCClientSecretGoogle: Google OIDC Provider Client Secret */
  OryOIDCClientSecretGoogle = 'OryOIDCClientSecretGoogle',
  /** OryWebhookApiKey: Generated by NOAH to authenticate Ory Webhooks */
  OryWebhookApiKey = 'OryWebhookApiKey',
  /** QuickNodeToken: The authentication token for QuickNode endpoint */
  QuickNodeToken = 'QuickNodeToken',
  /** QuickNodeTokenPolygon: The authentication token for QuickNode Polygon endpoint */
  QuickNodeTokenPolygon = 'QuickNodeTokenPolygon',
  /** QuickNodeTokenSolana: The authentication token for QuickNode Solana endpoint, is the API Key */
  QuickNodeTokenSolana = 'QuickNodeTokenSolana',
  /** QuickNodeTokenTron: The authentication token for QuickNode Tron endpoint */
  QuickNodeTokenTron = 'QuickNodeTokenTron',
  /** RemiteePassword: Password for Remitee API */
  RemiteePassword = 'RemiteePassword',
  /** RemiteeUsername: Username for Remitee API */
  RemiteeUsername = 'RemiteeUsername',
  /** RevolutApiClientId: Client ID for Revolut API */
  RevolutApiClientId = 'RevolutApiClientId',
  /** RevolutApiPrivateCert: Private certificate for Revolut API */
  RevolutApiPrivateCert = 'RevolutApiPrivateCert',
  /** RevolutApiRefreshToken: Refresh token that allows requesting a new access token */
  RevolutApiRefreshToken = 'RevolutApiRefreshToken',
  /** RevolutWebhookKey: Secret key used to create a HMAC */
  RevolutWebhookKey = 'RevolutWebhookKey',
  /** SardineClientID: Sardine API Client ID */
  SardineClientID = 'SardineClientID',
  /** SardineSecretKey: Sardine API Secret Key */
  SardineSecretKey = 'SardineSecretKey',
  /** ScorechainApiKey: Scorechain API Key */
  ScorechainApiKey = 'ScorechainApiKey',
  /** SegmentApiWriteKey: Segment write key for the client */
  SegmentApiWriteKey = 'SegmentApiWriteKey',
  /** SigningJwtPrivateKey: Private key for signing a JWT used with Signing service */
  SigningJwtPrivateKey = 'SigningJwtPrivateKey',
  /** SubsciptionJwtPrivateKey: Private key for signing a JWT used when setting up GQL subscriptions */
  SubsciptionJwtPrivateKey = 'SubsciptionJwtPrivateKey',
  /** SubsciptionJwtPublicKey: Public key for verifying a signed JWT when setting up GQL subscriptions */
  SubsciptionJwtPublicKey = 'SubsciptionJwtPublicKey',
  /** SumSubApiKey: The authentication token for SumSub API */
  SumSubApiKey = 'SumSubApiKey',
  /** SumSubSecretKey: The secret key for SumSub API */
  SumSubSecretKey = 'SumSubSecretKey',
  /** SumSubWebhookKey: Secret key used to create a HMAC */
  SumSubWebhookKey = 'SumSubWebhookKey',
  /** TazapayApiKey: Tazapay Api Key */
  TazapayApiKey = 'TazapayApiKey',
  /** TazapaySecretKey:  Tazapay Secret Key */
  TazapaySecretKey = 'TazapaySecretKey',
  /** VoltClientID: Volt client id for application */
  VoltClientID = 'VoltClientID',
  /** VoltClientSecret: Volt client secret for application */
  VoltClientSecret = 'VoltClientSecret',
  /** VoltPassword: Volt password for merchant */
  VoltPassword = 'VoltPassword',
  /** VoltSecretKey: Volt Secret Key for Credit Webhooks */
  VoltSecretKey = 'VoltSecretKey',
  /** VoltSecretKey: Volt Secret Key for Payout Webhooks */
  VoltSecretKeyPayout = 'VoltSecretKeyPayout',
  /** VoltSigningKey: Volt private key for signing requests */
  VoltSigningKey = 'VoltSigningKey',
  /** VoltSigningKeyReference: Volt's Reference in UUID for the signing key */
  VoltSigningKeyReference = 'VoltSigningKeyReference',
  /** VoltUsername: Volt username for merchant */
  VoltUsername = 'VoltUsername',
  /** WoortonOAuthToken: Woorton OAuth token */
  WoortonOAuthToken = 'WoortonOAuthToken',
}

/** ParamStoreNameBitcoin: Bitcoin parameter name prefix for SSM Parameter Store */
export enum ParamStoreNameBitcoin {
  /** BitcoinJsonRpcHost01: Bitcoin JSON-RPC server host in format domain:port */
  BitcoinJsonRpcHost01 = 'BitcoinJsonRpcHost01',
  /** BitcoinJsonRpcPass01: Bitcoin JSON-RPC server password */
  BitcoinJsonRpcPass01 = 'BitcoinJsonRpcPass01',
  /** BitcoinJsonRpcUser01: Bitcoin JSON-RPC server username */
  BitcoinJsonRpcUser01 = 'BitcoinJsonRpcUser01',
}

/** ParamStoreNameBitcoinNode: Bitcoin parameter name suffix for SSM Parameter Store */
export enum ParamStoreNameBitcoinNode {
  /** a: Primary bitcoin node */
  a = 'a',
  /** b: Test wallet bitcoin node */
  b = 'b',
}

/** ParamStoreNameLightning: Lightning parameter name prefix for SSM Parameter Store */
export enum ParamStoreNameLightning {
  /** LightningHost01: Lightning node host in format domain:port */
  LightningHost01 = 'LightningHost01',
  /** LightningMacaroon01: Macaroon to authenticate to a lightning node */
  LightningMacaroon01 = 'LightningMacaroon01',
  /** LightningTlsCert01: Lightning node certificate */
  LightningTlsCert01 = 'LightningTlsCert01',
}

/** ParameterStoreLightningNode: Lightning parameter name suffix for SSM Parameter Store */
export enum ParameterStoreLightningNode {
  /** a: Primary lightning node */
  a = 'a',
  /** b: Test wallet lightning node */
  b = 'b',
}

export type Payin = PaymentCapability & {
  EstimatedSeconds?: Maybe<Scalars['Decimal']['output']>;
  Supported: Scalars['Boolean']['output'];
};

export type Payment = {
  Created: Scalars['AWSDateTime']['output'];
  Direction: TransactionDirection;
  /** FiatPaymentMethodID: Where should this payment be payed to */
  FiatPaymentMethodID: Scalars['FiatPaymentMethodID']['output'];
  ID: Scalars['DynamoID']['output'];
  /** OrderAmount: The amount of crypto that has been reserved for a payout. TODO: No definition yet for a payin. */
  OrderAmount: Scalars['Decimal']['output'];
  /** OrderCurrency: The crypto currency code the OrderAmount refers to. */
  OrderCurrency: CurrencyCode;
  /** RequestedAmount: The amount and currency to be payed */
  RequestedAmount: FiatAmount;
  Status: PaymentStatus;
  UserID: Scalars['UserID']['output'];
};

export type PaymentCapability = {
  EstimatedSeconds?: Maybe<Scalars['Decimal']['output']>;
  Supported: Scalars['Boolean']['output'];
};

export type PaymentComplianceData = {
  /** PaymentPurpose: Purpose of the payment */
  Purpose?: Maybe<Scalars['String']['output']>;
};

export enum PaymentIdentifierType {
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
  TaxID = 'TaxID',
  UUID = 'UUID',
}

export enum PaymentMethodCategory {
  Bank = 'Bank',
  Card = 'Card',
  Identifier = 'Identifier',
}

export enum PaymentMethodType {
  BankAch = 'BankAch',
  BankFedwire = 'BankFedwire',
  BankLocal = 'BankLocal',
  BankSepa = 'BankSepa',
  BankSortCode = 'BankSortCode',
  BankSwift = 'BankSwift',
  IdentifierMobileMoney = 'IdentifierMobileMoney',
  IdentifierPix = 'IdentifierPix',
  TokenizedCard = 'TokenizedCard',
}

export enum PaymentStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Settled = 'Settled',
}

export enum PaymentStatusEnum {
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  LOCKED = 'LOCKED',
  LOCK_DECLINED = 'LOCK_DECLINED',
  QUOTE_FAILED = 'QUOTE_FAILED',
  REVERSED = 'REVERSED',
  SETTLED = 'SETTLED',
}

export enum PaymentSubStateEnum {
  AVAILABLE = 'AVAILABLE',
  EXPIRED = 'EXPIRED',
  FORWARDED = 'FORWARDED',
  PENDING = 'PENDING',
}

export type Payout = PaymentCapability & {
  EstimatedSeconds?: Maybe<Scalars['Decimal']['output']>;
  /** RawData: raw data of the payout information sent by the provider */
  RawData?: Maybe<Scalars['AWSJSON']['output']>;
  Supported: Scalars['Boolean']['output'];
};

export type PayoutStatus = {
  Created: Scalars['AWSDateTime']['output'];
  EventType: CkoWebhookType;
  ExecutionRate?: Maybe<MarketPrice>;
  FailureCause?: Maybe<NoahError>;
  ID?: Maybe<Scalars['String']['output']>;
  QuoteID?: Maybe<Scalars['String']['output']>;
  ReferenceID: Scalars['String']['output'];
  Status: PaymentStatusEnum;
  SubState?: Maybe<PaymentSubStateEnum>;
  Updated?: Maybe<Scalars['AWSDateTime']['output']>;
};

/** PolicyPeriod: describes the period of time for which the limit is valid */
export enum PolicyPeriod {
  /** DAY: policy applies to last 24 hours */
  DAY = 'DAY',
  /** SINGLE: policy applies to every transaction */
  SINGLE = 'SINGLE',
}

/** PolicyUsage: represents the usage of a transaction policy */
export type PolicyUsage = {
  /** Amount: used EUR amount during the period indicated in the policy key */
  Amount: Scalars['Decimal']['output'];
  /** DynamoTTL: time to live before item is marked for deletion */
  DynamoTTL: Scalars['Int']['output'];
  /** PolicyKey: [OrderType]/[PeriodID]/[Network] */
  PolicyKey: Scalars['DynamoID']['output'];
  /** UserKey: owner of the policy [UserID]/[CustomerID] or [UserID] */
  UserKey: Scalars['DynamoID']['output'];
};

export type Promotion = {
  /**
   * Amount: amounts of the promotion values per currency.
   * If the user currency is not found, the first Amount in the list will be used as a default value.
   */
  Amounts: Array<FiatAmount>;
  /** Created: created time of the promotion */
  Created: Scalars['AWSDateTime']['output'];
  /** Ends: end time of the promotion */
  Ends: Scalars['AWSDateTime']['output'];
  /** OneTimeUse: whether promotion can only be used once */
  OneTimeUse: Scalars['Boolean']['output'];
  /** PromoID: unique promotion identifier */
  PromoID: Scalars['PromoID']['output'];
  /** PromotionType: what is the promotion applied against */
  PromotionType: PromotionType;
  /** Starts: start time of the promotion */
  Starts: Scalars['AWSDateTime']['output'];
};

/** PromotionClaim: transaction / order ID that the promotion was applied to + amount of promotion applied */
export type PromotionClaim = {
  Amount: FiatAmount;
  ClaimedAt: Scalars['AWSDateTime']['output'];
  ID: Scalars['String']['output'];
};

export enum PromotionType {
  RampFee = 'RampFee',
}

export type PromotionsResponse = {
  UnclaimedPromotions?: Maybe<Array<Promotion>>;
};

export type PubSubEvent = {
  EventName: PubSubEventName;
  NewObject: Scalars['AWSJSON']['output'];
  Occurred: Scalars['AWSDateTime']['output'];
  OldObject?: Maybe<Scalars['AWSJSON']['output']>;
  Source: PubSubEventSource;
  Type: PubSubEventType;
  Version: Scalars['Int']['output'];
};

export enum PubSubEventName {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update',
}

export enum PubSubEventSource {
  Api = 'Api',
  Auth = 'Auth',
  DynamoDb = 'DynamoDb',
  Ledger = 'Ledger',
}

export enum PubSubEventType {
  AuthRecovery = 'AuthRecovery',
  AuthSignIn = 'AuthSignIn',
  AuthSignUp = 'AuthSignUp',
  AuthVerification = 'AuthVerification',
  DynamoDbCheckoutSession = 'DynamoDbCheckoutSession',
  DynamoDbCustomer = 'DynamoDbCustomer',
  DynamoDbLiquidityOrder2 = 'DynamoDbLiquidityOrder2',
  DynamoDbQuestCompletion = 'DynamoDbQuestCompletion',
  DynamoDbTransaction = 'DynamoDbTransaction',
  DynamoDbUserConsent = 'DynamoDbUserConsent',
  DynamoDbUserProfile = 'DynamoDbUserProfile',
  DynamoDbWalletAddress = 'DynamoDbWalletAddress',
  DynamoDbWhitelist = 'DynamoDbWhitelist',
  FiatDeposit = 'FiatDeposit',
  FiatPayment = 'FiatPayment',
  FinServicePayout = 'FinServicePayout',
  LedgerAccount = 'LedgerAccount',
  LedgerAccountBalanceCurrent = 'LedgerAccountBalanceCurrent',
  LedgerRewardTransaction = 'LedgerRewardTransaction',
  LedgerTransaction = 'LedgerTransaction',
  LedgerWithdrawOrder = 'LedgerWithdrawOrder',
  UserClaim = 'UserClaim',
}

export enum PubSubSubscriber {
  QuestEvent = 'QuestEvent',
  ReferralEvent = 'ReferralEvent',
  Segment = 'Segment',
}

export type Query = {
  accounts: AccountsPage;
  apiKeys: ApiKeysPage;
  cardIssuerNotifications: CardIssuerNotificationPage;
  channelPrices: ChannelPrices;
  channels: ChannelsPage;
  customer?: Maybe<IndividualCustomer>;
  customers: CustomersPage;
  depositAddresses?: Maybe<Array<DepositAddress>>;
  earnedBadges: EarnedBadgesPage;
  fiatPaymentMethods: FiatPaymentMethodsPage;
  intercomIdentifyHash: IntercomIdentifyHashResponse;
  lightningAddressInvoiceProxy: LightningAddressInvoiceProxyResponse;
  lightningAddressProxy: LightningAddressProxyResponse;
  lightningPaymentRequest: LightningPaymentRequest;
  liquidityPrice: LiquidityPrice;
  marketPrice: MarketPrice;
  novuSubscriberHash: NovuSubscriberHashResponse;
  promotions: PromotionsResponse;
  questsAndBadges: QuestsAndBadgesResponse;
  ruleExecution: RuleExecution;
  ruleExecutions: RuleExecutionsPage;
  signingJwt: JwtToken;
  subscriptionJwt: JwtToken;
  transaction?: Maybe<TransactionResponse>;
  transactions: TransactionsPage;
  userEstimateBalance: EstimateBalanceResponse;
  userLimit: UserLimitResponse;
  userProfile: UserProfile;
  userSettingsConsent: Array<Maybe<UserSettingsConsent>>;
  validateReferralCode: Scalars['Boolean']['output'];
  webhookSubscriptions?: Maybe<WebhookSubscriptionsPage>;
  withdrawFee: WithdrawFee;
};

export type QueryAccountsArgs = {
  Input?: InputMaybe<AccountsInput>;
};

export type QueryApiKeysArgs = {
  Input?: InputMaybe<ApiKeysInput>;
};

export type QueryCardIssuerNotificationsArgs = {
  Input: CardIssuerNotificationInput;
};

export type QueryChannelPricesArgs = {
  Input: ChannelPricesInput;
};

export type QueryChannelsArgs = {
  Input?: InputMaybe<ChannelsInput>;
};

export type QueryCustomerArgs = {
  Input: CustomerInput;
};

export type QueryCustomersArgs = {
  Input?: InputMaybe<CustomersInput>;
};

export type QueryDepositAddressesArgs = {
  Input: DepositAddressesInput;
};

export type QueryEarnedBadgesArgs = {
  Input?: InputMaybe<EarnedBadgesInput>;
};

export type QueryFiatPaymentMethodsArgs = {
  Input?: InputMaybe<FiatPaymentMethodsInput>;
};

export type QueryLightningAddressInvoiceProxyArgs = {
  Input: LightningAddressInvoiceProxyInput;
};

export type QueryLightningAddressProxyArgs = {
  Input: LightningAddressProxyInput;
};

export type QueryLightningPaymentRequestArgs = {
  Input: LightningPaymentRequestInput;
};

export type QueryLiquidityPriceArgs = {
  Input: MarketPriceInput;
};

export type QueryMarketPriceArgs = {
  Input: MarketPriceInput;
};

export type QueryRuleExecutionArgs = {
  Input?: InputMaybe<RuleExecutionInput>;
};

export type QueryRuleExecutionsArgs = {
  Input: RuleExecutionsInput;
};

export type QuerySigningJwtArgs = {
  Input?: InputMaybe<SigningJwtInput>;
};

export type QueryTransactionArgs = {
  Input: TransactionInput;
};

export type QueryTransactionsArgs = {
  Input?: InputMaybe<TransactionsInput>;
};

export type QueryUserEstimateBalanceArgs = {
  Input: EstimateBalanceInput;
};

export type QueryValidateReferralCodeArgs = {
  Input: ValidateReferralCodeInput;
};

export type QueryWebhookSubscriptionsArgs = {
  Input?: InputMaybe<WebhookSubscriptionsInput>;
};

export type QueryWithdrawFeeArgs = {
  Input: WithdrawFeeInput;
};

/** Quest: quest which can be completed by some users to earn a badge */
export type Quest = {
  /** BadgeTypeID: identifies the type of badge earned from this quest */
  BadgeTypeID?: Maybe<Scalars['BadgeTypeID']['output']>;
  /** Ends: date when thie quest ends */
  Ends?: Maybe<Scalars['AWSDateTime']['output']>;
  /**
   * EndsAfterUserCreatedSec: quest dynamically ends after this many seconds has elapsed after user is created.
   * Does not punish already created users, minimum ending time is Opens + EndsAfterUserCreatedSec.
   */
  EndsAfterUserCreatedSec?: Maybe<Scalars['Int']['output']>;
  /**
   * Features: one of the features is required for the quest to be displayed to user.
   * Default: no features are required.
   */
  Features?: Maybe<Array<Feature>>;
  /**
   * Opens: date from which this quest is displayed to users.
   * Can be after Starts date to allow events prior to Opens date to progress this quest.
   */
  Opens: Scalars['AWSDateTime']['output'];
  /** QuestID: identifies this quest */
  QuestID: Scalars['QuestID']['output'];
  /** RequiredQuestIDs: quests that need to be completed before this one is started */
  RequiredQuestIDs: Array<Scalars['QuestID']['output']>;
  /** Starts: date from which this quest starts */
  Starts: Scalars['AWSDateTime']['output'];
  /** Tasks: tasks */
  Tasks: Array<QuestTask>;
  /** Version: version to increment when updating quest */
  Version: Scalars['Int']['output'];
};

/** QuestCompletion: completion status of quest */
export type QuestCompletion = {
  /** BadgeTypeID: identifies the badge type earned from the quest */
  BadgeTypeID?: Maybe<Scalars['BadgeTypeID']['output']>;
  /** Completed: date when this quest was completed */
  Completed?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Opened: has the quest completion opened to display to users */
  Opened: Scalars['Boolean']['output'];
  /** QuestID: identifies the completed quest */
  QuestID: Scalars['QuestID']['output'];
  /** UserID: identifies the owner of this badge */
  UserID: Scalars['UserID']['output'];
  /** Version: version which is increased when event is handled for quest */
  Version: Scalars['Int']['output'];
};

/** Quest: quest which can be completed by some users to earn a badge */
export type QuestCreateInput = {
  /**
   * BadgeTypeID: identifies the type of badge earned from this quest.
   * Upper/lowercase letters and numbers, 3-25 characters.
   */
  BadgeTypeID?: InputMaybe<Scalars['BadgeTypeID']['input']>;
  /** Ends: date when thie quest ends */
  Ends?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /**
   * EndsAfterUserCreatedSec: quest dynamically ends after this many seconds has elapsed after user is created.
   * Does not punish already created users, minimum ending time is Opens + EndsAfterUserCreatedSec.
   */
  EndsAfterUserCreatedSec?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Features: one of the features is required for the quest to be displayed to user.
   * Default: no features are required.
   */
  Features?: InputMaybe<Array<Feature>>;
  /**
   * Opens: date from which this quest is displayed to users.
   * Can be after Starts date to allow events prior to Opens date to progress this quest.
   */
  Opens: Scalars['AWSDateTime']['input'];
  /**
   * QuestID: identifies the quest.
   * Upper/lowercase letters and numbers, 3-25 characters.
   */
  QuestID: Scalars['QuestID']['input'];
  /** RequiredQuestIDs: quests that need to be completed before this one is started */
  RequiredQuestIDs: Array<Scalars['QuestID']['input']>;
  /** Starts: date from which this quest starts */
  Starts: Scalars['AWSDateTime']['input'];
  /** Tasks: tasks */
  Tasks: Array<QuestTaskCreateInput>;
  /** Version: version to increment when updating quest */
  Version: Scalars['Int']['input'];
};

/** QuestProgress: user's status for a quest */
export type QuestStatus = {
  /**
   * Progress: how many tasks user has completed or, for a sinle-task quest, task progress count of the task.
   * Equal to RequiredProgress if user has completed the quest.
   */
  Progress: Scalars['Int']['output'];
  /** Quest: quest */
  Quest: Quest;
  /** RequiredProgress: how many tasks are in the quest or, for a sinle-task quest, required count of the task */
  RequiredProgress: Scalars['Int']['output'];
  /** Tasks: quest's tasks with user's current progress of the task, null if quest has been completed */
  Tasks?: Maybe<Array<QuestTaskWithStatus>>;
};

/** QuestTask: task which can reward NOAH and count towards a quest */
export type QuestTask = {
  /** Code: code that must be provided */
  Code?: Maybe<Scalars['String']['output']>;
  /** DestinationType: type of transfer destination */
  DestinationType?: Maybe<TransferDestinationType>;
  /** FiatCurrencyCode: fiat currency that the required amount is counted in */
  FiatCurrencyCode?: Maybe<FiatCurrencyCode>;
  /** IsAmountCumulative: is event amount counted towards required amount cumulatively */
  IsAmountCumulative?: Maybe<Scalars['Boolean']['output']>;
  /** QuestTaskID: identifier of this task */
  QuestTaskID: Scalars['QuestTaskID']['output'];
  /**
   * RequiredAmount: amount required to make the action count
   * * Deposit requirement: amount of money that needs to be deposited
   */
  RequiredAmount?: Maybe<Scalars['Decimal']['output']>;
  /** RequiredCount: how many times the requirement needs to be fulfilled */
  RequiredCount: Scalars['Int']['output'];
  /**
   * RequiredCurrencyCode: currency associated with the task
   * * Deposit requirement: currency that needs to be deposited
   */
  RequiredCurrencyCode?: Maybe<CurrencyCode>;
  /** Requirement: requirement to complete this task */
  Requirement: QuestTaskRequirement;
  /** RewardHold: reward is not dispersed */
  RewardHold?: Maybe<Scalars['Boolean']['output']>;
  /** RewardNoah: amount of NOAH rewarded for task */
  RewardNoah: Scalars['Decimal']['output'];
  /** SourceTypes: type of transfer source */
  SourceTypes?: Maybe<Array<TransferSourceType>>;
  /** Sources: source of the event */
  Sources?: Maybe<Array<Scalars['String']['output']>>;
};

/** QuestTaskCreateInput: task which can reward NOAH and count towards a quest */
export type QuestTaskCreateInput = {
  /** Code: code that must be provided */
  Code?: InputMaybe<Scalars['String']['input']>;
  /** DestinationType: type of transfer destination */
  DestinationType?: InputMaybe<TransferDestinationType>;
  /** FiatCurrencyCode: fiat currency that the required amount is counted in */
  FiatCurrencyCode?: InputMaybe<FiatCurrencyCode>;
  /** IsAmountCumulative: is event amount counted towards required amount cumulatively */
  IsAmountCumulative?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * RequiredAmount: amount required to make the action count
   * * Deposit requirement: amount of money that needs to be deposited
   */
  RequiredAmount?: InputMaybe<Scalars['Decimal']['input']>;
  /** RequiredCount: how many times the requirement needs to be fulfilled */
  RequiredCount: Scalars['Int']['input'];
  /**
   * RequiredCurrencyCode: currency associated with the task
   * * Deposit requirement: currency that needs to be deposited
   */
  RequiredCurrencyCode?: InputMaybe<CurrencyCode>;
  /** Requirement: requirement to complete this task */
  Requirement: QuestTaskRequirement;
  /** RewardHold: reward is not dispersed */
  RewardHold?: InputMaybe<Scalars['Boolean']['input']>;
  /** RewardNoah: amount of NOAH rewarded for task */
  RewardNoah: Scalars['Decimal']['input'];
  /** SourceTypes: type of transfer source */
  SourceTypes?: InputMaybe<Array<TransferSourceType>>;
  /** Sources: source of the event */
  Sources?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** QuestTaskRequirement: requirement to complete a task */
export enum QuestTaskRequirement {
  /** Claim: user has used a claim code, action based */
  Claim = 'Claim',
  /** CreateLightningInvoice: user creates a lightning invoice, action based */
  CreateLightningInvoice = 'CreateLightningInvoice',
  /** Deposit: deposit to Noah, action based */
  Deposit = 'Deposit',
  /** LightningInvoiceSettled: user's lightning invoice is settled, action based */
  LightningInvoiceSettled = 'LightningInvoiceSettled',
  /** MarketingSignup: user has signed up to marketing emails, state based */
  MarketingSignup = 'MarketingSignup',
  /** Referrals: amount of referrals user makes, action based */
  Referrals = 'Referrals',
  /** RegisterLightningAddress: user has registered a lightning address, state based */
  RegisterLightningAddress = 'RegisterLightningAddress',
  /** TotalReferrals: amount of referrals user has made, state based */
  TotalReferrals = 'TotalReferrals',
  /** UserSignup: user has signed up, state based */
  UserSignup = 'UserSignup',
  /** VerifyEmail: user has verified email, state based */
  VerifyEmail = 'VerifyEmail',
  /** Withdraw: withdraw from Noah, action based */
  Withdraw = 'Withdraw',
}

/** QuestTaskStatus: user status of a quest task */
export type QuestTaskStatus = {
  /** Count: count of actions user completed */
  Count: Scalars['Int']['output'];
  /** CumulatedAmount: amount that user has accumulated */
  CumulatedAmount?: Maybe<Scalars['Decimal']['output']>;
  /** QuestTaskID: identifies quest task */
  QuestTaskID: Scalars['QuestTaskID']['output'];
  /** Started: date when task progress started */
  Started: Scalars['AWSDateTime']['output'];
  /** UserID: identifies the user performing the quest task */
  UserID: Scalars['UserID']['output'];
};

/** QuestTaskAndStatus: quest task and user status of the task */
export type QuestTaskWithStatus = {
  /** Task: quest task */
  Task: QuestTask;
  /** TaskStatus: user status of the task, null if task has not been progressed */
  TaskStatus?: Maybe<QuestTaskStatus>;
};

/** QuestsAndBadgesResponse: user's quest status */
export type QuestsAndBadgesResponse = {
  /** QuestStatuses: progress of quests available for user */
  QuestStatuses: Array<QuestStatus>;
  /** UserBadges: locked and earned status for the user */
  UserBadges: Array<UserBadge>;
};

/** ReconciliationBalance: Balance at a reconciliation step */
export type ReconciliationBalance = {
  /** Available: Available balance after this step */
  Available: Scalars['Decimal']['output'];
  /** OpeningAvailable: Available balance prior to this step */
  OpeningAvailable: Scalars['Decimal']['output'];
  /** OpeningTotal: Total balance prior to this step */
  OpeningTotal: Scalars['Decimal']['output'];
  /** Total: Total balance after this step */
  Total: Scalars['Decimal']['output'];
};

/** ReconciliationResult: Describes the result of a reconciliation step */
export type ReconciliationResult = {
  Created: Scalars['AWSDateTime']['output'];
  /** Description: additional description */
  Description?: Maybe<Scalars['String']['output']>;
  /** DestinationBalance: Destination account balance */
  DestinationBalance?: Maybe<ReconciliationBalance>;
  Error?: Maybe<NoahError>;
  /** Skipped: step was invoked but it was disabled */
  Skipped?: Maybe<Scalars['Boolean']['output']>;
  /** SourceBalance: Source account balance */
  SourceBalance?: Maybe<ReconciliationBalance>;
  Type: ReconciliationTask;
};

export enum ReconciliationTask {
  BroadcastTransaction = 'BroadcastTransaction',
  CheckOnchainRisk = 'CheckOnchainRisk',
  CheckPolicy = 'CheckPolicy',
  CheckUserRisk = 'CheckUserRisk',
  Correction = 'Correction',
  DepositOrder = 'DepositOrder',
  DepositTransaction = 'DepositTransaction',
  ExpireTransaction = 'ExpireTransaction',
  FailureTransaction = 'FailureTransaction',
  FiatPaymentTransaction = 'FiatPaymentTransaction',
  FinaliseTransaction = 'FinaliseTransaction',
  InitiateOrder = 'InitiateOrder',
  InitiateTransaction = 'InitiateTransaction',
  InternalTransaction = 'InternalTransaction',
  InvoiceTransaction = 'InvoiceTransaction',
  ManageLiquidity = 'ManageLiquidity',
  MarketAmount = 'MarketAmount',
  PaymentTransaction = 'PaymentTransaction',
  Promotion = 'Promotion',
  ReversalTransaction = 'ReversalTransaction',
  RollbackTransaction = 'RollbackTransaction',
  TransactionCosigner = 'TransactionCosigner',
  ValidateCommit = 'ValidateCommit',
  ValidateContract = 'ValidateContract',
  ValidateDestination = 'ValidateDestination',
  ValidateNonCustody = 'ValidateNonCustody',
  ValidateOnchain = 'ValidateOnchain',
  ValidateStructure = 'ValidateStructure',
  WithdrawTransaction = 'WithdrawTransaction',
}

export type RemiteeDebtor = {
  fullName: FullNameInput;
  idNumber: Scalars['String']['input'];
  idType: IdentityType;
  postalAddress: StreetAddressInput;
};

export type RequestSignature = {
  /** ApiKeyHash: the hash of the API key used to sign the request */
  ApiKeyHash?: Maybe<Scalars['String']['output']>;
  /** Body: base64 encoded string of the body of the request where bodyHash field in JWT Payload is generated from */
  Body?: Maybe<Scalars['String']['output']>;
  /** Created: the time the signature was stored */
  Created: Scalars['AWSDateTime']['output'];
  /** ID: the ID of the signature */
  ID: Scalars['UUID']['output'];
  /** Jwt: the JWT token sent with a request */
  Jwt: Scalars['String']['output'];
  /** UserID: the user ID */
  UserID: Scalars['UserID']['output'];
};

export type RevolutAccount = {
  AccountID: Scalars['String']['output'];
  CounterpartyID: Scalars['String']['output'];
  FinService: FinService;
  Reference: Scalars['String']['output'];
};

/**
 * RevolutAccountCreateInput: inputs for creating a counterparty in Revolut and an Account in our system.
 * For required fields depending on the country, see:
 * https://developer.revolut.com/docs/guides/manage-accounts/tutorials/create-a-counterparty#required-details-depending-on-the-country
 */
export type RevolutAccountCreateInput = {
  AccountDetails: CounterpartyInput;
  FinService: FinService;
  Reference: Scalars['String']['input'];
};

/** RewardActivity: Noah rewards activity */
export enum RewardActivity {
  /** EarlyUser: rewards accrued by signing up with Noah early on and referring early */
  EarlyUser = 'EarlyUser',
  /** QuestTask: rewards accrued by completing a quest task */
  QuestTask = 'QuestTask',
  /** Referral: reward accrual by referring a person to signup with Noah */
  Referral = 'Referral',
  /** Referred: reward accrual by signing up with Noah using a referral */
  Referred = 'Referred',
  /**
   * ReferredSignup: reward accrual by signing up with Noah using a referral
   *
   * @deprecated Reward comes from Onboarding quest and Referred activity
   */
  ReferredSignup = 'ReferredSignup',
  /**
   * Signup: reward accrual by signing up with Noah
   *
   * @deprecated Reward comes from Onboarding quest
   */
  Signup = 'Signup',
}

/**
 * RewardTransaction:
 * Describes internal reward transactions that are committed to our ledger.
 */
export type RewardTransaction = {
  /** Activity: activity that caused this transaction */
  Activity: RewardActivity;
  /** ActivityCount: count of times the activity was performed */
  ActivityCount: Scalars['Int']['output'];
  /** Amount: the incoming or outgoing absolute amount of the transaction (i.e. no -ve numbers) */
  Amount: Scalars['Decimal']['output'];
  /** Currency: the currency type of the transaction */
  Currency: CurrencyCode;
  /** DestinationAccountID: identifies the destination account ID */
  DestinationAccountID?: Maybe<Scalars['AccountID']['output']>;
  NoahID: Scalars['NoahID']['output'];
  /** QuestTaskID: task id for reward with quest task activity */
  QuestTaskID?: Maybe<Scalars['QuestTaskID']['output']>;
  /** ReversedBy: transaction that reverses this transaction */
  ReversedBy?: Maybe<Scalars['NoahID']['output']>;
  /** Reverses: transaction that this transaction reverses */
  Reverses?: Maybe<Scalars['NoahID']['output']>;
  /** SourceAccountID: identifies the source account ID */
  SourceAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** Status: the current status of the transaction on the Noah Ledger */
  Status: TransactionStatus;
  /** Steps: is a record of the outcome of our validation & verification steps */
  Steps: RewardTransactionSteps;
};

/** RewardTransactionStepSource: an enum describing the supported service types */
export enum RewardTransactionStepSource {
  Quest = 'Quest',
  Signup = 'Signup',
}

/** RewardTransactionSteps: is a record of the outcome of our validation & verification steps */
export type RewardTransactionSteps = {
  Quest: Array<ReconciliationResult>;
  Signup: Array<ReconciliationResult>;
};

/** RewardsIndexedFields: index fields on ledger rewards table */
export enum RewardsIndexedFields {
  DestinationAccountID = 'DestinationAccountID',
  ID = 'ID',
  NoahID = 'NoahID',
}

export enum RpcImplementation {
  BtcRpc = 'BtcRpc',
  Geth = 'Geth',
  SolanaRpc = 'SolanaRpc',
}

export type Rule = {
  Actions: Array<Action>;
  Created: Scalars['AWSDateTime']['output'];
  Expiry?: Maybe<Scalars['AWSDateTime']['output']>;
  /** Group: Used to drive deterministic ID for rule execution */
  Group: Scalars['String']['output'];
  ID: Scalars['ID']['output'];
  Permanent: Scalars['Boolean']['output'];
  Priority: Scalars['Int']['output'];
  /** RequestSignatureID: id of the request signature used to sign the rule */
  RequestSignatureID?: Maybe<Scalars['UUID']['output']>;
  Status: RuleStatus;
  Trigger: Trigger;
  TriggerIndex: Scalars['String']['output'];
  UserID: Scalars['UserID']['output'];
  /** Workflow: indicates for which workflow the rule is created */
  Workflow?: Maybe<Workflow>;
};

export type RuleExecution = {
  /** Actions: a list of actions executed with output data */
  Actions?: Maybe<Array<RuleExecutionAction>>;
  /** ID: Identifier - a deterministic ID from Group and SourceID */
  ID: Scalars['ID']['output'];
  RuleID: Scalars['ID']['output'];
  /** SmExecutionArn: The current state machine execution ARN */
  SmExecutionArn?: Maybe<Scalars['String']['output']>;
  StartedAt: Scalars['AWSDateTime']['output'];
  Status: RuleExecutionStatus;
  /** Trigger: a trigger for the rule execution with output data */
  Trigger: RuleExecutionTrigger;
  UpdatedAt: Scalars['AWSDateTime']['output'];
  UserID: Scalars['UserID']['output'];
  /** Version: Version of the rule execution */
  Version: Scalars['Int']['output'];
  /** Workflow: indicates for which workflow the rule is executed */
  Workflow?: Maybe<Workflow>;
};

export type RuleExecutionAction = {
  ActionID: Scalars['ID']['output'];
  /** Data: an output data from execution of an action */
  Data?: Maybe<Scalars['AWSJSON']['output']>;
  FailureCause?: Maybe<NoahError>;
  Status: RuleExecutionStatus;
  Type: ActionType;
  UpdatedAt: Scalars['AWSDateTime']['output'];
};

export type RuleExecutionInput = {
  /** RuleExecutionID: the unique identifier of this rule execution */
  RuleExecutionID: Scalars['String']['input'];
};

export enum RuleExecutionStatus {
  Failed = 'Failed',
  Running = 'Running',
  Successful = 'Successful',
}

export type RuleExecutionTrigger = {
  /** Data: an output data drived from an event. It is used to pass data for subsequent actions and used to communicate to consumer of the API what happened */
  Data?: Maybe<Scalars['AWSJSON']['output']>;
  Details?: Maybe<TriggerPaymentMethod>;
  Type: TriggerType;
};

export type RuleExecutionsInput = {
  /** Workflow: passing a value here means the api will use the WorkflowIndex */
  Workflow: Workflow;
  paging?: InputMaybe<PagingInput>;
};

export type RuleExecutionsPage = {
  items: Array<RuleExecution>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export enum RuleStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Executed = 'Executed',
  Paused = 'Paused',
}

/**
 * A separate helper table used to sort and fetch rules based on trigger index and priority
 * Uses to filter expired rules
 */
export type RuleTriggerIndex = {
  /** Created: a rule Created date */
  Created: Scalars['AWSDateTime']['output'];
  /** DynamoTTL: TTL in seconds for the record. Drived from Expiry field in Rules table */
  DynamoTTL?: Maybe<Scalars['Int']['output']>;
  /** Group: a rule group: Taken from Rule */
  Group: Scalars['String']['output'];
  /** ID: Identifier. This is the TriggerIndex in Rules */
  ID: Scalars['ID']['output'];
  /** PrioritySort: Sort key for the table. Drived from Priority and Created fields from Rules tables. Formatted as Priority/Created */
  PrioritySort: Scalars['String']['output'];
  /** RuleID:This is the ID in Rules */
  RuleID: Scalars['ID']['output'];
  /** Trigger: A rule trigger. Taken from Rules */
  Trigger: Trigger;
  /** UserID: This is the UserID in Rules */
  UserID: Scalars['UserID']['output'];
  /** Workflow: a workflow in rules. indicates for which workflow the rule is created. */
  Workflow?: Maybe<Workflow>;
};

export type S3 = {
  customerBucketName: Scalars['String']['input'];
};

export type SardineDevicesResponse = {
  Success: Scalars['Boolean']['output'];
};

export enum SardineFlows {
  CryptoDeposit = 'CryptoDeposit',
  CryptoWithdraw = 'CryptoWithdraw',
  FiatDeposit = 'FiatDeposit',
  FiatWithdraw = 'FiatWithdraw',
  Login = 'Login',
  Registration = 'Registration',
  Root = 'Root',
}

export type SegmentSubscription = {
  groups: Array<SegmentSubscriptionGroup>;
  /** key: user's email address */
  key: Scalars['String']['input'];
  status: SegmentSubscriptionGroupStatus;
  type: SegmentSubscriptionGroupType;
};

export type SegmentSubscriptionGroup = {
  name: SegmentSubscriptionGroupName;
  status: SegmentSubscriptionGroupStatus;
};

export enum SegmentSubscriptionGroupName {
  Marketing = 'Marketing',
  Operational = 'Operational',
}

export enum SegmentSubscriptionGroupStatus {
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
}

export enum SegmentSubscriptionGroupType {
  EMAIL = 'EMAIL',
}

export type SellAction = Action & {
  AuthorizedAmount: StepDecimal;
  CryptoCurrency: Scalars['String']['output'];
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  ExternalID?: Maybe<Scalars['String']['output']>;
  FiatAmount: StepDecimal;
  FormSessionID: Scalars['UUID']['output'];
  ID: Scalars['ID']['output'];
  Type: ActionType;
};

export type SendOtpInput = {
  OtpPurpose: OtpPurpose;
};

export type SendOtpResponse = {
  Success: Scalars['Boolean']['output'];
};

export type SenderFiatPaymentMethod = {
  DisplayDetails?: Maybe<TransactionFiatPaymentMethodDetails>;
  FinancialInstitution?: Maybe<SenderFinancialInstitution>;
  FullName?: Maybe<Scalars['String']['output']>;
};

export type SenderFinancialInstitution = {
  Code: Scalars['String']['output'];
  Country: Scalars['CountryCode']['output'];
  Name: Scalars['String']['output'];
};

export type Session = {
  AuthorizedAmount?: Maybe<Scalars['Decimal']['output']>;
  CheckoutSessionID: Scalars['ID']['output'];
  Created: Scalars['AWSDateTime']['output'];
  CustomerID: Scalars['CustomerID']['output'];
  DestinationAmount?: Maybe<Scalars['Decimal']['output']>;
  DestinationCurrency: Scalars['AssetID']['output'];
  Expiry: Scalars['AWSDateTime']['output'];
  ExternalID?: Maybe<Scalars['ID']['output']>;
  LineItems: Array<LineItem>;
  PaymentMethodCategory?: Maybe<PaymentMethodCategory>;
  ReturnURL: Scalars['String']['output'];
  SourceAmount?: Maybe<Scalars['Decimal']['output']>;
  SourceCurrency: Scalars['AssetID']['output'];
  Status: CheckoutStatus;
  UserID: Scalars['UserID']['output'];
};

export type SettlePaymentInput = {
  ID: Scalars['DynamoID']['input'];
  MarketAmount: FiatAmountInput;
};

export type SettlePaymentResponse = {
  Status: PaymentStatus;
};

/** SigningDocumentType: type indicating what a key managed by Signing service is for */
export enum SigningDocumentType {
  /** NonCustodyKey: key that user signs withdraw orders with */
  NonCustodyKey = 'NonCustodyKey',
}

export type SigningJwtInput = {
  ClaimOtp?: InputMaybe<ClaimOtpInput>;
};

/** SigningKey: public key of a key that user has control of and that user manages with Signing service */
export type SigningKey = {
  /** CreatedAt: when the key was created by user in the Signing service */
  CreatedAt: Scalars['AWSDateTime']['output'];
  /** DocumentType: type indicating what the key is for */
  DocumentType: SigningDocumentType;
  /** ID: ID of a user signing key, of the form <UserID>/<SigningDocumentType> */
  ID: Scalars['SigningKeyID']['output'];
  /** KeyAlgorithm: key algorithm used when verifying user signatures */
  KeyAlgorithm: SigningKeyAlgorithm;
  /** PublicKey: key used to verify user signatures */
  PublicKey: Scalars['String']['output'];
  /**
   * Seq: sequence number of the key.
   * For NonCustodyKey the key with the highest seq is the active key.
   */
  Seq: Scalars['Int']['output'];
  /** Tag that uniquely identifies the the signing key, 32 bytes */
  Tag: Scalars['String']['output'];
  /** UserID: user that controls the private key */
  UserID: Scalars['UserID']['output'];
};

/** SigningKeyAlgorithm: asymmetric key algorithm supported by Signing service */
export enum SigningKeyAlgorithm {
  /** Secp256k1: algorithm commoly used for Bitcoin */
  Secp256k1 = 'Secp256k1',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** SourceAddress: the origin location of a transaction */
export type SourceAddress = {
  /** Address: the wallet address of the transaction */
  Address: Scalars['Address']['output'];
};

export type StepDecimal = {
  Operations?: Maybe<Array<StepDecimalOperation>>;
  Value: Scalars['String']['output'];
};

export type StepDecimalOperation = {
  Operator: StepDecimalOperator;
  Value: Scalars['String']['output'];
};

export enum StepDecimalOperator {
  Add = 'Add',
  Mul = 'Mul',
  Sub = 'Sub',
}

/** StepFnEvent: Describes the shape of inputs and outputs between step functions. */
export type StepFnEvent = {
  /** ErrorCatch: captures the error on step function failure */
  ErrorCatch?: Maybe<ErrorCatch>;
  /** EventID: id unique for the step function execution */
  EventID: Scalars['EventID']['output'];
  /** ExpiryTime: time of expiry */
  ExpiryTime?: Maybe<Scalars['AWSDateTime']['output']>;
  /** FailureStep: records the step which failed so it can be recorded and / or used in error handlers. */
  FailureStep?: Maybe<FailureStep>;
  /** IsCompletedTransaction: is the transaction completed */
  IsCompletedTransaction: Scalars['Boolean']['output'];
  /** IsDepositTransaction: does the transaction deposit currency to user wallet */
  IsDepositTransaction: Scalars['Boolean']['output'];
  /** IsDuplicate: is the event a duplicate */
  IsDuplicate: Scalars['Boolean']['output'];
  /** IsFailure: is the event a failure */
  IsFailure: Scalars['Boolean']['output'];
  /** IsInternalTransaction: is the transaction to be settled internally */
  IsInternalTransaction: Scalars['Boolean']['output'];
  /**
   * IsManualTransaction: does the transaction need manual intervention. If true
   * it means that there is some human intervention required to complete the
   * transaction.
   */
  IsManualTransaction: Scalars['Boolean']['output'];
  /** IsMarketAmountSet: is market amount already set on transaction */
  IsMarketAmountSet: Scalars['Boolean']['output'];
  /** IsPendingTransaction: is the transaction still pending */
  IsPendingTransaction: Scalars['Boolean']['output'];
  /** IsWithdrawTransaction: does the transaction withdraw currency from user wallet */
  IsWithdrawTransaction: Scalars['Boolean']['output'];
  /** NoahID: id of a transaction event */
  NoahID?: Maybe<Scalars['NoahID']['output']>;
  /** PublicID: public blockchain transaction id or hash */
  PublicID?: Maybe<Scalars['PublicID']['output']>;
  /** RawEvent: unmodified event that started the step function */
  RawEvent: Scalars['AWSJSON']['output'];
  /** Status: the current status of the transaction. Used by the state machine to know when to stop or fail. */
  Status: TransactionStatus;
  /** TravelRuleResult: result of the travel rule check */
  TravelRuleResult?: Maybe<Scalars['String']['output']>;
  /** UserSessionID: unique identifier of user authentication session */
  UserSessionID?: Maybe<Scalars['String']['output']>;
  /** WithdrawOrderID: id of a withdraw order event */
  WithdrawOrderID?: Maybe<Scalars['WithdrawOrderID']['output']>;
};

export type StreetAddress = {
  /** City: name of an address's city or town. */
  City: Scalars['String']['output'];
  /** CountryCode: user's country code */
  CountryCode: Scalars['CountryCode']['output'];
  /** PostCode: the address's postcode */
  PostCode: Scalars['String']['output'];
  /** State: the address's state / province / county */
  State: Scalars['String']['output'];
  /** Street: the primary name of an address's street. */
  Street: Scalars['String']['output'];
  /** Street2: the secondary name of an address's street. */
  Street2?: Maybe<Scalars['String']['output']>;
};

export type StreetAddressInput = {
  /** City: name of an address's city or town. */
  City: Scalars['String']['input'];
  /** CountryCode: user's country code */
  CountryCode: Scalars['CountryCode']['input'];
  /** PostCode: the address's postcode */
  PostCode: Scalars['String']['input'];
  /** State: the address's state / province / county */
  State: Scalars['String']['input'];
  /** Street: the primary name of an address's street. */
  Street: Scalars['String']['input'];
  /** Street2: the secondary name of an address's street. */
  Street2?: InputMaybe<Scalars['String']['input']>;
};

/** SubAccountResponse: wrapper for publishing account updates to the app. */
export type SubAccountResponse = {
  Data: AccountResponse;
  UserID: Scalars['UserID']['output'];
};

export type Subscription = {
  onAccountUpdate?: Maybe<SubAccountResponse>;
};

export type SumSubAccessTokenInput = {
  /** LevelName: A name of the level configured in the dashboard */
  LevelName: Scalars['String']['input'];
};

/** SumSubAccessTokenResponse: returns an access token for applicant, it’s only valid for 1 applicant and can't access other applicants */
export type SumSubAccessTokenResponse = {
  /** Token: Access token for the SDK */
  Token: Scalars['String']['output'];
  /** UserID: UserID for which the token was created */
  UserID: Scalars['UserID']['output'];
};

export type SuspendState = {
  ID: Scalars['String']['output'];
  TaskToken: Scalars['String']['output'];
};

export enum SystemStatus {
  /** Active: system is functioning normally */
  Active = 'Active',
  /** Locked: system has a problem and all features will be disabled (e.g. system is under attack) */
  Locked = 'Locked',
  /** Maintenance: system is in maintenance mode and all features will be disabled */
  Maintenance = 'Maintenance',
}

export type TransactionBreakdownItem = {
  Amount: Scalars['Decimal']['output'];
  Type: TransactionBreakdownType;
};

export enum TransactionBreakdownType {
  /** BusinessFee: amount to withheld on behalf of the business over its customers transactions. */
  BusinessFee = 'BusinessFee',
  /** ChannelFee: amount withheld by the system from the fiat payment. */
  ChannelFee = 'ChannelFee',
  /** Remaining: any amount not explicitly split into a breakdown item will have this type. */
  Remaining = 'Remaining',
}

export enum TransactionDirection {
  In = 'In',
  Out = 'Out',
}

export type TransactionFiatPayment = {
  /**
   * Amount: the final amount that has been debited (for buy transactions) or
   * credited (for sell transactions) from the payment method.
   */
  Amount: Scalars['Decimal']['output'];
  /**
   * FeeAmount: The fee applied to this payment. For buy transactions, this value
   * is included in `Amount`. For sell transactions, this value is excluded from
   * the `Amount`.
   */
  FeeAmount: Scalars['Decimal']['output'];
  /** FiatCurrency: The currency related to the amounts. */
  FiatCurrency: FiatCurrencyCode;
  /** DepositID: identifies the deposit whe the payment originated from a deposit. */
  FiatDepositID?: Maybe<Scalars['String']['output']>;
  /** Rate: Crypto/Fiat exchange rate. */
  Rate?: Maybe<Scalars['Decimal']['output']>;
};

export type TransactionFiatPaymentMethod = {
  Country: Scalars['CountryCode']['output'];
  DisplayDetails: TransactionFiatPaymentMethodDetails;
  FiatPaymentMethodType: PaymentMethodType;
  ID: Scalars['FiatPaymentMethodID']['output'];
};

export type TransactionFiatPaymentMethodDetails =
  | FiatPaymentMethodBankDisplay
  | FiatPaymentMethodCardDisplay
  | FiatPaymentMethodIdentifierDisplay;

export enum TransactionFiatPaymentMethodDetailsType {
  FiatPaymentMethodBankDisplay = 'FiatPaymentMethodBankDisplay',
  FiatPaymentMethodCardDisplay = 'FiatPaymentMethodCardDisplay',
  FiatPaymentMethodIdentifierDisplay = 'FiatPaymentMethodIdentifierDisplay',
}

export type TransactionInput = {
  /** TransactionID: the unique identifier of this transaction can be either NoahID or WithdrawOrderID */
  TransactionID: Scalars['String']['input'];
};

export type TransactionMetadata = {
  /**
   * Breakdown: describes how this amount should be split. It should add up to the transaction amount.
   * When present you can allways expect to find one BreakdownItem with the type `Remaining`.
   */
  Breakdown?: Maybe<Array<TransactionBreakdownItem>>;
  /** ExternalID: the unique identifier of this transaction on the external system */
  ExternalID?: Maybe<Scalars['String']['output']>;
  /** Orchestration: the orchestration rule that was used to process this transaction */
  Orchestration?: Maybe<TransactionOrchestration>;
  /** TransactionID: id of the transaction this metadata belongs to */
  TransactionID: Scalars['String']['output'];
  /** UserID: ID of source or destination user */
  UserID: Scalars['UserID']['output'];
};

/** TransactionOperation: Operation of transaction */
export enum TransactionOperation {
  /** Mint: Mint new tokens */
  Mint = 'Mint',
  /** Transfer: Transfer funds */
  Transfer = 'Transfer',
}

export type TransactionOrchestration = {
  /** RuleExecutionID: an id of a rule beign executed for a transaction */
  RuleExecutionID: Scalars['ID']['output'];
  /** RuleID: a matched rule id for a transaction */
  RuleID: Scalars['ID']['output'];
};

/** TransactionRecord: a record of an operation performed on a transaction */
export type TransactionRecord = {
  /** Amount: resulting amount of the operation */
  Amount: Scalars['Decimal']['output'];
  /** Asset: asset of the operation */
  Asset?: Maybe<Scalars['AssetID']['output']>;
  /**
   * FiatCurrency: resulting fiat currency of the operation.
   *
   * @deprecated Use AssetID insead.
   */
  FiatCurrency?: Maybe<FiatCurrencyCode>;
  /** Rate: rate used to perform the operation */
  Rate?: Maybe<Scalars['Decimal']['output']>;
  /** RecordType: type of operation */
  RecordType: TransactionRecordType;
};

export enum TransactionRecordType {
  /** Fee: fee taken to system funding */
  Fee = 'Fee',
  /** NetworkFee: fee taken to cover network transaction costs */
  NetworkFee = 'NetworkFee',
}

/** TransactionResponse: transaction from DDB */
export type TransactionResponse = {
  /** Amount: The net crypto amount transacted, affecting the balance. This amount **excludes** the `NetworkFee`. For buy transactions, this is the amount after payment fees. For sell transactions, this is the amount before payment fees. */
  Amount: Scalars['Decimal']['output'];
  /** AmountUSD: the usd amount (i.e. the usd amount from the fireblocks transaction) */
  AmountUSD?: Maybe<Scalars['String']['output']>;
  /** Breakdown: describes how this amount should be split. */
  Breakdown?: Maybe<Array<TransactionBreakdownItem>>;
  /** Created: date when the transaction was created */
  Created: Scalars['AWSDateTime']['output'];
  /** Currency: the currency type of the transaction */
  Currency: CurrencyCode;
  /** CustomerID: ID of the customer for this transaction */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** Description: text attached to this transaction */
  Description?: Maybe<Scalars['String']['output']>;
  /** Destination: the end location of this transaction */
  Destination?: Maybe<DestinationAddress>;
  /** DestinationAccountID: identifies the destination account ID */
  DestinationAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** DestinationRecords: records of operations perfomed on a destination transaction */
  DestinationRecords?: Maybe<Array<TransactionRecord>>;
  /** Destination Type: type of destination */
  DestinationType?: Maybe<TransferDestinationType>;
  /** DestinationUsernameDisplay: the receiver UsernameDisplay if the destination address belongs to a NOAH user */
  DestinationUsernameDisplay?: Maybe<Scalars['String']['output']>;
  /** Direction: whether the funds were added/credited or subtracted/debited from the user balance */
  Direction?: Maybe<TransactionDirection>;
  /** DynamoID: DynamoID of the transaction */
  DynamoID: Scalars['DynamoID']['output'];
  /** Expiry: time from which the transaction cannot be paid anymore */
  Expiry?: Maybe<Scalars['AWSDateTime']['output']>;
  /** ExternalID: the unique identifier of this transaction on the external system */
  ExternalID?: Maybe<Scalars['String']['output']>;
  /** FiatPayment: When the transaction is a sell or buy this attributed is set. */
  FiatPayment?: Maybe<TransactionFiatPayment>;
  /** TransactionFiatPaymentMethod: information about the fiat payment method used to facilitate this transaction */
  FiatPaymentMethod?: Maybe<TransactionFiatPaymentMethod>;
  /**
   * MarketAmount: amount of fiat that the crypto was worth at the time transaction was
   * committed to ledger
   */
  MarketAmount?: Maybe<FiatAmount>;
  /** Network: Network of the transaction */
  Network: Network;
  /** NetworkFee: Amount paid to cover the network costs. */
  NetworkFee?: Maybe<Scalars['Decimal']['output']>;
  /** NoahID: the unique identifier of this transaction */
  NoahID?: Maybe<Scalars['NoahID']['output']>;
  /** Operation: Operation of the transaction, such as Transfer */
  Operation?: Maybe<TransactionOperation>;
  /** Orchestration: orchestration information for this transaction */
  Orchestration?: Maybe<TransactionOrchestration>;
  /** Origin: the origin of activity transaction */
  Origin?: Maybe<EventOrigin>;
  /** PublicID: the unique identifier of this transaction */
  PublicID?: Maybe<Scalars['PublicID']['output']>;
  /**
   * RequestedAmount: amount of fiat that user requested to transact.
   * This amount is informative and can be manipulated by user.
   * Market and order amounts are expected to be near this number but
   * this might be what user expects to see and user might be easiser to read.
   */
  RequestedAmount?: Maybe<FiatAmount>;
  /** Reverses: transaction that this transaction reverses (may reverse partially) */
  Reverses?: Maybe<Scalars['NoahID']['output']>;
  /** Source: the origin location of this transaction */
  Source?: Maybe<SourceAddress>;
  /** SourceAccountID: identifies the source account ID */
  SourceAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** SourceRecords: records of operations perfomed on a source transaction */
  SourceRecords?: Maybe<Array<TransactionRecord>>;
  /** SourceType: type of the source */
  SourceType?: Maybe<TransferSourceType>;
  /** SourceUsernameDisplay: the sender UsernameDisplay if the source address belongs to a NOAH user */
  SourceUsernameDisplay?: Maybe<Scalars['String']['output']>;
  /** Status: the current status of the transaction on the Noah Ledger */
  Status: TransactionStatus;
  /** WithdrawOrderID: id of an original withdraw order, if related */
  WithdrawOrderID?: Maybe<Scalars['WithdrawOrderID']['output']>;
};

export enum TransactionStatus {
  /** Failed: The transaction has failed processing */
  Failed = 'Failed',
  /** Pending: The transaction is being processed */
  Pending = 'Pending',
  /** Settled: The transaction has been processed and found to be correct */
  Settled = 'Settled',
}

/** TransactionStepSource: an enum describing the supported service types */
export enum TransactionStepSource {
  Checkout = 'Checkout',
  Core = 'Core',
  Deposit = 'Deposit',
  Fireblocks = 'Fireblocks',
  Invoice = 'Invoice',
  Lnd = 'Lnd',
  Moonpay = 'Moonpay',
  Payment = 'Payment',
  Ramp = 'Ramp',
  Signing = 'Signing',
  Withdraw = 'Withdraw',
}

/** TransactionSteps: is a record of the outcome of our validation & verification steps */
export type TransactionSteps = {
  /** Checkout: transaction steps from a cko event */
  Checkout: Array<ReconciliationResult>;
  /** Core: additional core steps */
  Core: Array<ReconciliationResult>;
  /** Deposit: transaction steps from initiating a deposit */
  Deposit: Array<ReconciliationResult>;
  /** Fireblocks: transaction steps from a Fireblocks event */
  Fireblocks: Array<ReconciliationResult>;
  /** Invoice: transaction steps from invoicing */
  Invoice: Array<ReconciliationResult>;
  /** Lnd: transaction steps from a lightning node event */
  Lnd: Array<ReconciliationResult>;
  /**
   * Moonpay: transaction steps from a Moonpay event
   *
   * @deprecated Moonpay is deprecated and should not be used.
   */
  Moonpay: Array<ReconciliationResult>;
  /** Payment: transaction steps from sending a payment */
  Payment: Array<ReconciliationResult>;
  /** Ramp: transactions steps from the ramp engine */
  Ramp: Array<ReconciliationResult>;
  /** Signing: signing service steps */
  Signing: Array<ReconciliationResult>;
  /** Withdraw: transaction steps from initiating a withdrawal */
  Withdraw: Array<ReconciliationResult>;
};

export type TransactionsFilter = {
  AccountType?: InputMaybe<ModelStringInput>;
  Amount?: InputMaybe<ModelDecimalInput>;
  Currency?: InputMaybe<ModelStringInput>;
  Destination?: InputMaybe<ModelDestinationInput>;
  SourceAccountID?: InputMaybe<ModelStringInput>;
  SourceType?: InputMaybe<ModelStringInput>;
  Status?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<TransactionsFilter>>;
  not?: InputMaybe<TransactionsFilter>;
  or?: InputMaybe<Array<TransactionsFilter>>;
};

/** TransactionsIndexedFields: index fields on ledger transactions table */
export enum TransactionsIndexedFields {
  NoahID = 'NoahID',
  PublicID = 'PublicID',
}

export type TransactionsInput = {
  /** Currency: passing a value here means the api will use the CurrencyIndex. Prefer this over filtering by Currency */
  Currency?: InputMaybe<CurrencyCode>;
  filter?: InputMaybe<TransactionsFilter>;
  paging?: InputMaybe<PagingInput>;
};

export type TransactionsPage = {
  items: Array<TransactionResponse>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** TransferDestination: transfer transaction destination */
export type TransferDestination = {
  /**
   * DestinationAddress: destination address (address + optional ref).
   * Only needed if DestinationType is Address
   */
  DestinationAddress?: InputMaybe<DestinationAddressInput>;
  /** DestinationType: determines type of transfer */
  DestinationType: TransferDestinationType;
  /** ID: if DestinationType is Whitelist then use the Whitelisted AddressID, if Moonpay use the Moonpay transactionID. */
  ID?: InputMaybe<Scalars['String']['input']>;
};

/** TransferDestinationType: type of transfer destination */
export enum TransferDestinationType {
  /** Address: withdrawals to an address */
  Address = 'Address',
  /** Bitrefill: withrawals to pay Bitrefill orders */
  Bitrefill = 'Bitrefill',
  /** Channel: sell transactions that should go through a channel */
  Channel = 'Channel',
  /** Checkout: withrawals to pay Checkout orders */
  Checkout = 'Checkout',
  /** ManualRamp: withrawal to fund a ManualRamp payout transaction */
  ManualRamp = 'ManualRamp',
  /**
   * Moonpay: withrawals to pay Moonpay orders
   *
   * @deprecated Moonpay should not be used and is deprecated
   */
  Moonpay = 'Moonpay',
  /** Whitelist: withdrawals to a user's whitelisted address */
  Whitelist = 'Whitelist',
}

/** TransferDestinationTypeInput: transfer destination input */
export enum TransferDestinationTypeInput {
  /** Bitrefill: withrawals to pay Bitrefill orders */
  Bitrefill = 'Bitrefill',
}

/** TransferSourceType: type of transfer source */
export enum TransferSourceType {
  /** Channel: buy transactions that should go through a channel */
  Channel = 'Channel',
  /** Checkout: transfers from Cko payment requests. CORE-620 - rename to Cko */
  Checkout = 'Checkout',
  /** ManualRamp: transfers from manual ramp payin */
  ManualRamp = 'ManualRamp',
  /**
   * Moonpay: transfers for Moonpay sell orders
   *
   * @deprecated Moonpay should not be used and is deprecated
   */
  Moonpay = 'Moonpay',
}

export type TravelRuleDeposit = {
  DynamoTTL: Scalars['Int']['output'];
  PublicID: Scalars['String']['output'];
  SumSubID: Scalars['String']['output'];
};

export type Trigger = {
  Type: TriggerType;
};

export type TriggerDecimalCondition = {
  ComparisonOperator: ComparisonOperator;
  Value: Scalars['Decimal']['output'];
};

export type TriggerPaymentMethod = {
  DisplayDetails?: Maybe<TransactionFiatPaymentMethodDetails>;
  FiatPaymentMethodID?: Maybe<Scalars['FiatPaymentMethodID']['output']>;
  PaymentMethodType?: Maybe<PaymentMethodType>;
};

export enum TriggerType {
  DepositDestination = 'DepositDestination',
  DepositSource = 'DepositSource',
  FiatDeposit = 'FiatDeposit',
}

export type UserAppSettingsUpdateInput = {
  DisplayUnit?: InputMaybe<CurrencyUnit>;
  FiatCurrencyCode?: InputMaybe<FiatCurrencyCode>;
  PrimaryCurrency?: InputMaybe<CurrencyDisplayType>;
};

/** UserBadge: badge that user has earned */
export type UserBadge = {
  /** BadgeTypeID: identifies the type of this badge */
  BadgeTypeID: Scalars['BadgeTypeID']['output'];
  /** Difficulty: difficulty of the quest granting this badge */
  Difficulty: Scalars['Int']['output'];
  /** Earned: date when this badge was earned */
  Earned?: Maybe<Scalars['AWSDateTime']['output']>;
  /** QuestID: the ID of the quest granting this badge */
  QuestID?: Maybe<Scalars['QuestID']['output']>;
};

/** UserClaim: claim that user is making */
export type UserClaim = {
  /** ClaimType: claim type being made */
  Code: Scalars['String']['output'];
  /** UserID: user making the claim */
  UserID: Scalars['UserID']['output'];
};

export type UserComplianceState = {
  Modified: Scalars['AWSDateTime']['output'];
  /** ModifiedBy: the identifier of the user who changed this status (comes from Retool) */
  ModifiedBy: Scalars['String']['output'];
  /** Reason: a Linear ticket number. Must be in the format "COM-5****' where **** are optional numbers */
  Reason: Scalars['String']['output'];
  Status: UserComplianceStatus;
};

export type UserComplianceStateInput = {
  /** DeleteOverrides: pass true to also delete the FeatureOverrides for this user */
  DeleteOverrides: Scalars['Boolean']['input'];
  /** ModifiedBy: the identifier of the user who changed this status (comes from Retool) */
  ModifiedBy: Scalars['String']['input'];
  /** Reason: a Linear ticket number. Must be in the format "COM-5****' where **** are optional numbers */
  Reason: Scalars['String']['input'];
  Status: UserComplianceStatus;
  UserID: Scalars['UserID']['input'];
};

/** UserComplianceStatus: see, https://www.notion.so/thenoahapp/NIP-11-User-Status-1577fc6d2308485da5efd5e7b24541cd */
export enum UserComplianceStatus {
  Active = 'Active',
  Investigating = 'Investigating',
  Reported = 'Reported',
  Suspended = 'Suspended',
}

export type UserFiatPaymentCurrencyUpdate = {
  FiatCurrencyCode: FiatCurrencyCode;
  FiatPaymentCurrencyCode: FiatCurrencyCode;
};

/** UserGroup: group that user can belong to */
export enum UserGroup {
  /** Beta: user has access to beta features */
  Beta = 'Beta',
  /** Business: user has access to business features */
  Business = 'Business',
  /** Noah: for noah employees - should have access to all features, even globally disabled ones */
  Noah = 'Noah',
}

export type UserIdentificationFile = {
  /** FileSide: The side of the file returned */
  FileSide?: Maybe<IdentificationFileSide>;
  /** IssuingCountryCode: The country that issued the id. */
  IssuingCountryCode?: Maybe<Scalars['CountryCode']['output']>;
  /** Kind: The kind of file returned */
  Kind: IdentityType;
  /** URL: A url link to download the file. It is advisable to set an expiration time of 30 seconds on the link, to add an additional layer of security. */
  URL: Scalars['AWSURL']['output'];
};

export type UserLimit = {
  /** When the FiatAmount is null, the user has no limit for the given period */
  FiatAmount?: Maybe<FiatAmount>;
  /** LimitType: the type of the limit */
  LimitType: UserLimitType;
  /** Period: the period of the limit */
  Period: PolicyPeriod;
  /** Format of the signature: v1/DateTime/base64(sign(DateTime/LimitType/Period/FiatCurrency/FiatAmount)) */
  Signature: Scalars['String']['output'];
};

export type UserLimitFiatAmountInput = {
  /** Amount: amount of fiat currency */
  Amount: Scalars['Decimal']['input'];
  /** Currency: used fiat currency */
  FiatCurrency: FiatCurrencyCode;
};

export type UserLimitInput = {
  /** When the FiatAmount is null, the user has no limit for the given period */
  FiatAmount?: InputMaybe<UserLimitFiatAmountInput>;
  /** LimitType: the type of the limit */
  LimitType: UserLimitType;
  /** Period: the period of the limit */
  Period: PolicyPeriod;
  /** Format of the signature: v1/DateTime/base64(sign(DateTime/LimitType/Period/FiatCurrency/FiatAmount)) */
  Signature: Scalars['String']['input'];
};

/** UserLimitResponse: returns user's limit and usage */
export type UserLimitResponse = {
  /** Limits: user's limits and usage */
  Limits: Array<OrderLimitPolicy>;
};

/** UserLimitTransaction: represents a transaction that helps calculate user's limit */
export type UserLimitTransaction = {
  /** Amount: the eur amount for this transaction */
  Amount: Scalars['Decimal']['output'];
  /** Created: date when the transaction was created */
  Created: Scalars['AWSDateTime']['output'];
  /** CustomerID: optional customer identifier */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** DynamoID: [OrderType]/[Network]/[ID] */
  DynamoID: Scalars['DynamoID']['output'];
  /** Expiry time of the transaction in DynamoDB */
  DynamoTTL: Scalars['Int']['output'];
  /** Network: network used for the transaction */
  Network: Network;
  /** OrderID: unique identifier of the source transaction */
  OrderID: Scalars['ID']['output'];
  /** OrderType: type of the transaction (deposit or withdraw) */
  OrderType: OrderType;
  /** UserKey: identifies the owner of this transaction. [UserID]/[CustomerID] or [UserID] */
  UserKey: Scalars['DynamoID']['output'];
};

export enum UserLimitType {
  Signing = 'Signing',
}

export type UserLimitUpdateInput = {
  SigningDayLimit: UserLimitInput;
  SigningSingleLimit: UserLimitInput;
};

export type UserLimitUpdateResponse = {
  limits: Array<UserLimit>;
};

/** UserLimitsIndexedFields: index fields on ledger userlimits table */
export enum UserLimitsIndexedFields {
  UserID = 'UserID',
}

export type UserMetadata = {
  /** GooglePlayStoreAppReviewed: timestamp when the google play store app review dialog was displayed to the user */
  GooglePlayStoreAppReviewed?: Maybe<Scalars['AWSDateTime']['output']>;
  /** GroupBetaClaimed: timestamp of a first login after becoming beta */
  GroupBetaClaimed?: Maybe<Scalars['AWSDateTime']['output']>;
  /** GroupBetaGranted: timestamp when a user was added to beta list */
  GroupBetaGranted?: Maybe<Scalars['AWSDateTime']['output']>;
  /** QuestsCompleted: quests which the user has completed */
  QuestsCompleted?: Maybe<Array<Scalars['QuestID']['output']>>;
  /** QuestsInProgress: quests which the user is currently working on */
  QuestsInProgress?: Maybe<Array<Scalars['QuestID']['output']>>;
  /** RewardsNoahPointsAvailable: number of noah points available */
  RewardsNoahPointsAvailable?: Maybe<Scalars['Int']['output']>;
  /** RewardsNoahPointsLifetime: number of lifetime noah points */
  RewardsNoahPointsLifeTime?: Maybe<Scalars['Int']['output']>;
  /** RewardsNoahPointsVersion: version of the noah points in the ledger */
  RewardsNoahPointsVersion?: Maybe<Scalars['Int']['output']>;
  /** TxBtcDepositFirst: timestamp of a first BTC deposit */
  TxBtcDepositFirst?: Maybe<Scalars['AWSDateTime']['output']>;
  /** TxBtcDepositLatest: timestamp of a latest BTC deposit */
  TxBtcDepositLatest?: Maybe<Scalars['AWSDateTime']['output']>;
  /** TxBtcWithdrawFirst: timestamp of a first BTC withdraw */
  TxBtcWithdrawFirst?: Maybe<Scalars['AWSDateTime']['output']>;
  /** TxBtcWithdrawLatest: timestamp of a latest BTC withdraw */
  TxBtcWithdrawLatest?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type UserMetadataUpdateInput = {
  GooglePlayStoreAppReviewed?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserProfile = {
  /** AvatarUrl: user's avatar */
  AvatarUrl?: Maybe<Scalars['String']['output']>;
  /** BotData: Network traffic data for suspected bots */
  BotData?: Maybe<BotData>;
  /** ComplianceState: details a user's compliance status */
  ComplianceState?: Maybe<UserComplianceState>;
  /** Created: when the user profile was created */
  Created?: Maybe<Scalars['AWSDateTime']['output']>;
  /** DateOfBirth: user's date of birth */
  DateOfBirth?: Maybe<Scalars['AWSDate']['output']>;
  /** DisplayUnit: the currency unit the user prefers to display their balance in defaults to the currency code if this is null */
  DisplayUnit: CurrencyUnit;
  /** Email: user email */
  Email: Scalars['AWSEmail']['output'];
  /** FeatureFlags: defines which features user can access */
  FeatureFlags?: Maybe<Array<FeatureFlag>>;
  /** FeatureOverrides: explicitly set status of features */
  FeatureOverrides?: Maybe<Array<FeatureFlag>>;
  /** FiatCurrencyCode: the fiat currency the user prefers to display their balance */
  FiatCurrencyCode: FiatCurrencyCode;
  /** FiatPaymentCurrencyCode: the fiat currency the user prefers to do payments on on-ramping and off-ramping flows */
  FiatPaymentCurrencyCode?: Maybe<FiatCurrencyCode>;
  /** Files: user's files */
  Files?: Maybe<Array<Maybe<UserIdentificationFile>>>;
  /** Groups: groups that the user belongs to */
  Groups?: Maybe<Array<UserGroup>>;
  /** HomeAddress: user's home address. */
  HomeAddress?: Maybe<KycAddress>;
  /** Kyb: Data related the user's KYB verification */
  Kyb?: Maybe<Kyb>;
  /** KycRetryCount: Number of times user has retried KYC */
  KycRetryCount?: Maybe<Scalars['Int']['output']>;
  /** KycVerification: Data related to user's KYC verification */
  KycVerification: KycVerification;
  /** LatestCountry: country where user is currently located */
  LatestCountry?: Maybe<Scalars['CountryCode']['output']>;
  /** LatestRegion: region where user is currently located */
  LatestRegion?: Maybe<Scalars['RegionCode']['output']>;
  /** Limits: the limits that the user defined for themselves */
  Limits?: Maybe<Array<UserLimit>>;
  /** Metadata: metadata about the user */
  Metadata: UserMetadata;
  /** Nationality: user's nationality, represented by a `CountryCode`. */
  Nationality?: Maybe<Scalars['CountryCode']['output']>;
  /**
   * PersonalName: user's full personal name.
   * https://en.wikipedia.org/wiki/Personal_name
   */
  PersonalName?: Maybe<FullName>;
  /**
   * PhoneNumber: a user's phone number -- potentially including a country code.
   *
   * See: https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html
   */
  PhoneNumber?: Maybe<Scalars['AWSPhone']['output']>;
  /** PrimaryCurrency: the currency the user prefers to be displayed as primary */
  PrimaryCurrency: CurrencyDisplayType;
  /** Referrals: number of users this user has referred */
  Referrals: Scalars['Int']['output'];
  /** ReferrerReferral: referral code of the user that referred this user */
  ReferrerReferral?: Maybe<Scalars['String']['output']>;
  /** UserID: identifies the owner of this account */
  UserID: Scalars['UserID']['output'];
  /** Username: username registered for the profile in lowercase */
  Username: Scalars['String']['output'];
  /** UsernameDisplay: username registered for the profile */
  UsernameDisplay: Scalars['String']['output'];
};

export type UserProfileCreateInput = {
  AvatarUrl: Scalars['String']['input'];
  /** Country: a honeypot field that should be null for human users */
  Country?: InputMaybe<Scalars['String']['input']>;
  ReferrerReferral?: InputMaybe<Scalars['String']['input']>;
  UsernameDisplay: Scalars['String']['input'];
};

export type UserSettingsConsent = {
  CommitHash: Scalars['String']['output'];
  Cookies?: Maybe<CookieConsent>;
  Created: Scalars['AWSDateTime']['output'];
  EmailContact: Scalars['Boolean']['output'];
  Source?: Maybe<UserSettingsConsentSource>;
  Terms: Scalars['Boolean']['output'];
  UserID: Scalars['UserID']['output'];
};

export type UserSettingsConsentInput = {
  CommitHash: Scalars['String']['input'];
  Cookies: CookieConsentInput;
  EmailContact: Scalars['Boolean']['input'];
  Terms: Scalars['Boolean']['input'];
};

export enum UserSettingsConsentSource {
  Segment = 'Segment',
  User = 'User',
}

export type ValidateReferralCodeInput = {
  ReferralCode: Scalars['String']['input'];
};

export type VaspAddress = {
  DynamoTTL: Scalars['Int']['output'];
  SourceAddress: Scalars['String']['output'];
};

/** VolumeLimit: A limit on the total volume over a time period */
export type VolumeLimit = {
  /** Limit: the limit amount */
  Limit?: Maybe<FiatAmount>;
  /** Minimum: a minimum threshold that must be exceeded */
  Minimum?: Maybe<FiatAmount>;
  /** Signing: a threshold that, when exceeded, requires a signature */
  Signing?: Maybe<FiatAmount>;
  /** Usage: used fiat currency */
  Usage?: Maybe<FiatAmount>;
};

export type WalletVerification = {
  Link: Scalars['String']['output'];
  TransactionID: Scalars['String']['output'];
  UserID: Scalars['UserID']['output'];
};

/**
 * WebhookDocument: a record of any important raw events
 * It extracts data that we need for indexing and records the full raw event as a string / json.
 */
export type WebhookDocument = {
  /** EventID: ID of the webhook */
  EventID: Scalars['ID']['output'];
  /** EventTime: The time of the raw event */
  EventTime: Scalars['AWSDateTime']['output'];
  /** Hash: Uniquely identifies the event message */
  Hash: Scalars['String']['output'];
  /** PublicID */
  PublicID: Scalars['String']['output'];
  /** RawEvent: The raw event */
  RawEvent: Scalars['AWSJSON']['output'];
};

export enum WebhookEventType {
  CheckoutSession = 'CheckoutSession',
  FiatDeposit = 'FiatDeposit',
  Transaction = 'Transaction',
}

export type WebhookSubscription = {
  /** Created: The time the subscription was created */
  Created: Scalars['AWSDateTime']['output'];
  /** EventTypes: The types of events that the webhook will be sent for */
  EventTypes: Array<WebhookEventType>;
  /** ID: The ID that the subscription is for */
  ID: Scalars['KSUID']['output'];
  /** Url: The URL that the webhook will be sent to */
  Url: Scalars['String']['output'];
  /** UserID: The user that created the subscription */
  UserID: Scalars['UserID']['output'];
};

export type WebhookSubscriptionCreateInput = {
  EventTypes: Array<WebhookEventType>;
  Url: Scalars['AWSURL']['input'];
};

export type WebhookSubscriptionDeleteInput = {
  ID: Scalars['KSUID']['input'];
};

export type WebhookSubscriptionsInput = {
  paging?: InputMaybe<PagingInput>;
};

export type WebhookSubscriptionsPage = {
  items: Array<WebhookSubscription>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

/** WhitelistAddress: whitelisted address */
export type WhitelistAddress = {
  /** DestinationAddress: address user can send funds to */
  DestinationAddress: DestinationAddress;
  /** ID: identifies a whitelist address */
  ID: Scalars['WhitelistID']['output'];
  /** Name: user defined name, max 20 characters */
  Name: Scalars['String']['output'];
};

/** WhitelistAddressCreateInput: request to add an address to whitelist */
export type WhitelistAddressCreateInput = {
  /** AccountType: type of account e.g. current / savings / loan */
  AccountType: AccountType;
  /** CurrencyCode: the currency code of this account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** DestinationAddress: address user can send funds to */
  DestinationAddress: DestinationAddressInput;
  /** Name: user defined name, max 20 characters */
  Name: Scalars['String']['input'];
};

/** WhitelistAddressDeleteInput: request to remove an address from whitelist */
export type WhitelistAddressDeleteInput = {
  /** AccountType: type of account e.g. current / savings / loan */
  AccountType: AccountType;
  /** CurrencyCode: the currency code of this account's base currency e.g. BTC / ETH */
  CurrencyCode: CurrencyCode;
  /** ID: identifies a whitelist address to remove */
  ID: Scalars['WhitelistID']['input'];
};

export type WhitelistUser = {
  Created?: Maybe<Scalars['AWSDateTime']['output']>;
  Email: Scalars['String']['output'];
  Groups: Array<UserGroup>;
  Source?: Maybe<Scalars['String']['output']>;
};

export type WithdrawAction = Action & {
  /**
   * CryptoAmount: the amount that will be debited from the user account if the GrossAmount is true OR
   * the amount plus the network fees that will be debited from the user account if the GrossAmount is
   * false
   */
  CryptoAmount: StepDecimal;
  CryptoCurrency: Scalars['String']['output'];
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  DestinationAddress: DestinationAddress;
  /**
   * GrossAmount: expresses whether the network fees will be debited from the account in addition to
   * the CryptoAmount (sender pays for the fees) OR will they by deducted from received the CryptoAmount
   * (receiver pays for the fees, only CryptoAmount will be deducted from user balance).
   */
  GrossAmount: Scalars['Boolean']['output'];
  ID: Scalars['ID']['output'];
  Network: Network;
  Type: ActionType;
};

/** WithdrawFee: fees paid for withdraw */
export type WithdrawFee = {
  /** FeeQuote: withdraw fee quote, returned when Transaction is provided */
  FeeQuote?: Maybe<Scalars['JWT']['output']>;
  /** NetworkFee: fee for covering network costs in input currency */
  NetworkFee: Scalars['Decimal']['output'];
};

/** WithdrawFeeInput: withdraw fee calculation input */
export type WithdrawFeeInput = {
  /** Currency: currency withdrawn */
  Currency: CurrencyCode;
  /** Network: network to withdraw on */
  Network: Network;
  /** Transaction: calculate fee for specific transaction */
  Transaction?: InputMaybe<WithdrawFeeTransactionInput>;
};

/** WithdrawFeeTransactionInput: transaction details for withdraw fee calculation */
export type WithdrawFeeTransactionInput = {
  /** Amount: absolute amount the receiver gets */
  Amount: Scalars['Decimal']['input'];
  /** DestinationAddress: address to withdraw to */
  DestinationAddress: DestinationAddressInput;
  /** Gross: amount includes the withdraw fee */
  GrossAmount?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WithdrawManualRollback = {
  /** RefundedPaymentID identifies a Payment that was refunded. */
  RefundedPaymentID?: Maybe<Scalars['String']['output']>;
  /** WithdrawOrderID identifies the WithdrawOrder that was rolled back. */
  WithdrawOrderID: Scalars['WithdrawOrderID']['output'];
};

export type WithdrawManualRollbackInput = {
  /** Force allows rolling back the WithdrawOrder after 12 hours even when our cosigner has already signed the onchain transaction. */
  Force: Scalars['Boolean']['input'];
  /** RetoolID: identifier in Retool */
  RetoolID: Scalars['String']['input'];
  /** WithdrawOrderID identifies the WithdrawOrder to be rolled back. */
  WithdrawOrderID: Scalars['WithdrawOrderID']['input'];
};

export type WithdrawOrder = {
  /**
   * AdminUserIDs: identifies admin users authorising this order. Used with ComplianceReason.
   * In chronological order: first proposer, then approvals.
   */
  AdminUserIDs?: Maybe<Array<Scalars['UUID']['output']>>;
  /**
   * Amount: gross amount deducted in account's base currency unit (like BTC / ETH / USDC).
   * Includes the network fee.
   */
  Amount: Scalars['Decimal']['output'];
  /** ApproveStatus: approval status of the order */
  ApproveStatus?: Maybe<WithdrawOrderApproveStatus>;
  /**
   * ApprovedFireblocksID: fireblocks transaction that was approved for
   * co-signing based on this order
   */
  ApprovedFireblocksID?: Maybe<Scalars['FireblocksID']['output']>;
  /** Attempts: attempted transactions to fulfil a withdraw order. */
  Attempts?: Maybe<Array<WithdrawOrderAttempt>>;
  /** BeneficiaryName: full name of the beneficiary */
  BeneficiaryName?: Maybe<Scalars['String']['output']>;
  /** BeneficiaryPlatform: name of the beneficiary exchange / vasp. Can be omitted if it is an unhosted wallet. */
  BeneficiaryPlatform?: Maybe<Scalars['String']['output']>;
  /**
   * ComplianceReason: reason (of UserComplianceState.Reason) for cases where compliance is legally
   * required to refund user funds. Signature is not required for compliance reverses.
   */
  ComplianceReason?: Maybe<Scalars['String']['output']>;
  /** Created: date when the withdraw was created */
  Created: Scalars['AWSDateTime']['output'];
  /** Currency: the currency type of the transaction */
  Currency: CurrencyCode;
  /** CustomerID: ID of the customer for this withdraw */
  CustomerID?: Maybe<Scalars['CustomerID']['output']>;
  /** DestinationAccountID: identifies the destination account ID in case of internally routed withdrawal */
  DestinationAccountID?: Maybe<Scalars['AccountID']['output']>;
  /** DestinationAddress: destination of the withdraw */
  DestinationAddress?: Maybe<DestinationAddress>;
  /** DestinationID: the destination ID of the type specified by DestinationType */
  DestinationID?: Maybe<Scalars['String']['output']>;
  /** DestinationType: type of destination ID */
  DestinationType?: Maybe<TransferDestinationType>;
  /** ExternalTransactionID: identifies transaction in destination. Can be manipulated by user. */
  ExternalTransactionID?: Maybe<Scalars['String']['output']>;
  /** ID: id of the order */
  ID: Scalars['WithdrawOrderID']['output'];
  /** IsRollBackAllowed: is the balance rollback of this order allowed */
  IsRollbackAllowed: Scalars['Boolean']['output'];
  /**
   * MarketAmount: amount of fiat that the crypto was worth at the time the order
   * was committed to ledger
   */
  MarketAmount?: Maybe<FiatAmount>;
  /** Network: network of the order */
  Network: Network;
  /** NetworkFee: fee taken from Amount to cover network costs. */
  NetworkFee?: Maybe<Scalars['Decimal']['output']>;
  /** NoahID: transaction created for this order */
  NoahID?: Maybe<Scalars['NoahID']['output']>;
  /** Nonce: unique value sent from client. */
  Nonce?: Maybe<Scalars['Nonce']['output']>;
  /**
   * RequestSignatureID: withdraw orders which are not directly signed by user require user signature of the request where the user
   * authorised the withdraw order to be created.
   */
  RequestSignatureID?: Maybe<Scalars['UUID']['output']>;
  /**
   * RequestedAmount: amount of fiat that user requested to transact.
   * This amount is informative and can be manipulated by user.
   * Market and order amounts are expected to be near this number but
   * this might be what user expects to see and might be easier to read.
   */
  RequestedAmount?: Maybe<FiatAmount>;
  /** Reverses: transaction that this withdraw reverses (may reverse partially) */
  Reverses?: Maybe<Scalars['NoahID']['output']>;
  /**
   * Signature: signature with user's signing key, of the form v1/DateTime/base64(sign(Nonce/DateTime/CurrencyCode/AccountType/Amount/Destination))
   * Nonce, which uniqueness is enforced, is included to verify that signature is not reused.
   * Signature is valid for 10 minutes.
   *
   * Some fields are optional and if they're defined they'll be included in the signature with a tag in the following order:
   * 1. /NetworkFee:value
   */
  Signature?: Maybe<Scalars['String']['output']>;
  /** SourceAccountID: identifies the source account ID */
  SourceAccountID: Scalars['AccountID']['output'];
  /** Status: the current status of the order on ledger */
  Status: WithdrawOrderStatus;
  /** Steps: is a record of the outcome of our validation & verification steps */
  Steps: TransactionSteps;
};

export enum WithdrawOrderApproveStatus {
  /** Approved: The order has been approved by the co-signer or as a result of a successful webhook */
  Approved = 'Approved',
  /** Rejected: The order has been rejected by the co-signer or as a result of a failed webhook */
  Rejected = 'Rejected',
}

export type WithdrawOrderAttempt = {
  /** ApproveStatus: approval status of the attempted transaction */
  ApproveStatus?: Maybe<WithdrawOrderApproveStatus>;
  /**
   * ApprovedFireblocksID: fireblocks transaction that was approved for
   * co-signing based on this attempt
   */
  ApprovedFireblocksID?: Maybe<Scalars['FireblocksID']['output']>;
  /** AttemptNumber: sequential number identifying the attempt. Initial attempt is 0. */
  AttemptNumber: Scalars['Int']['output'];
  /** Created: date when the attempt was created */
  Created: Scalars['AWSDateTime']['output'];
  /** NoahID: ledger transaction created for this attempt */
  NoahID?: Maybe<Scalars['NoahID']['output']>;
};

/** WithdrawOrderCreateRequestInput: withdrawal request */
export type WithdrawOrderCreateRequestInput = {
  /** AccountType: which AccountType this withdrawal is from */
  AccountType: AccountType;
  /**
   * Amount: gross amount deducted in account's base currency unit (like BTC / ETH).
   * Includes the network fee.
   */
  Amount: Scalars['Decimal']['input'];
  /** BeneficiaryName: full name of the beneficiary. Can be omitted if CustomerID is provided. */
  BeneficiaryName?: InputMaybe<Scalars['String']['input']>;
  /** CurrencyCode: the currency code of this withdrawal request */
  CurrencyCode: CurrencyCode;
  /** CustomerID: ID of the customer for this withdraw */
  CustomerID?: InputMaybe<Scalars['CustomerID']['input']>;
  /** Destination: transfer transaction destination */
  Destination: TransferDestination;
  /** FeeQuote: accepted quote for withdraw fees */
  FeeQuote: Scalars['JWT']['input'];
  /** Network: network to withdraw to */
  Network: Network;
  /** Nonce: unique value sent from client. */
  Nonce: Scalars['Nonce']['input'];
  /**
   * RequestedAmount: fiat amount that user entered to withdraw.
   * Defining this indicates that user specifically entered this amount, not only that this amount displayed as estimate.
   */
  RequestedAmount?: InputMaybe<FiatAmountInput>;
  /**
   * Signature: signature with user's signing key, of the form v1/DateTime/base64(sign(Nonce/DateTime/CurrencyCode/AccountType/Amount/Destination))
   * Nonce, which uniqueness is enforced, is included to verify that signature is not reused.
   * Signature is valid for 10 minutes.
   *
   * Some fields are optional and if they're defined they'll be included in the signature with a tag in the following order:
   * 1. /NetworkFee:value
   */
  Signature?: InputMaybe<Scalars['String']['input']>;
};

export enum WithdrawOrderStatus {
  /** Failed: The order has failed processing */
  Failed = 'Failed',
  /** Pending: The order is being processed */
  Pending = 'Pending',
  /** Settled: The order has been processed and found to be correct */
  Settled = 'Settled',
}

/** WithdrawOrdersIndexedFields: index fields on ledger withdraworders table */
export enum WithdrawOrdersIndexedFields {
  ExternalTransactionID = 'ExternalTransactionID',
  ID = 'ID',
  NoahID = 'NoahID',
  Nonce = 'Nonce',
}

export enum Workflow {
  BankDepositToOnchainAddress = 'BankDepositToOnchainAddress',
}

export type RpcConnection = {
  /** implementation: the implementation for the rpc node to use. */
  implementation: RpcImplementation;
  /** path: the path of rpc endpoint */
  path?: InputMaybe<Scalars['String']['input']>;
  /** secret: the secret used with the rpc connection */
  secret?: InputMaybe<RpcSecret>;
  /** url: the url of the node */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RpcSecret = {
  /** token: parameter name for the secred stored in SSM. In case of QuickNode this is part of the URL. */
  token?: InputMaybe<Scalars['String']['input']>;
};
